<template>
	<div>
		<div class="card weather4">
			<div class="body">
				<div class="row">
					<div class="col-6">
						<i class="wi wi-day-sunny"></i>
						<p>City</p>
					</div>
					<div class="col-6 text-right">
						<h3 class="">27° <span>C</span></h3>
						<span>Fri 20/5</span>
					</div>
				</div>
			</div>
		</div>
		<div class="card weather5">
			<div class="widget">
				<div class="weatherIcon"><i class="wi wi wi-day-cloudy"></i></div>
				<div class="weatherInfo">
					<div class="temperature"><span>25°</span></div>
					<div class="description">
						<div class="weatherCondition">CLOUDY</div>
						<div class="place">New York, USA</div>
					</div>
				</div>
				<div class="date">1st Jun</div>
			</div>
		</div>
		<div class="card weather6 l-amber">
			<div class="body">
				<div class="p-15">
					<div class="temp">
						27°<span>C</span>
						<i class="wi wi-day-cloudy-windy"></i>
					</div>
				</div>
				<p>Saturday 12 May</p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Weather2',
};
</script>
<style scoped></style>
