import axios from 'axios';
import store from '../store/store';

const envUrl = process.env.VUE_APP_URL;
const BASEURL = `${envUrl}farmer/`;
const APPKEY = 'bxXnw3Wxp5V4GWVei5EgvXdMnKB9llOHIBV4';

export default {
	getFarmers() {
		let config = {
			method: 'get',
			url: `${BASEURL}getFarmers`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},

	getFarmerDetailsById(farmerId) {
		let config = {
			method: 'get',
			url: `${BASEURL}getSingleFarmerDetails/${farmerId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},

	updateFarmer(farmer) {
		let config = {
			method: 'put',
			url: `${BASEURL}updateFarmer`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: farmer,
		};
		return axios(config);
	},

	deleteFarmer(farmerId) {
		let config = {
			method: 'delete',
			url: `${BASEURL}deleteFarmer/${farmerId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: '',
		};
		return axios(config);
	},

	getNotifications(userId) {
		let config = {
			method: 'post',
			url: `${BASEURL}getFarmerNotifications`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: { farmerId: userId },
		};
		return axios(config);
	},
	getCropFarmerAnalysis(farmerId) {
		let config = {
			method: 'get',
			url: `${BASEURL}${farmerId}/farmerCropsAnalysis`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
};
