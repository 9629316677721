<template>
	<div id="main-content" v-if="selectedFarmer">
		<bread-crumb-table :itemsType="{ type: 'Farmers' }" :farmerName="selectedFarmer.firstName"></bread-crumb-table>
		<div class="col-lg-12 crop-table-container">
			<div class="col-lg-4 col-md-6">
				<div class="farmer-details-profile border-padding">
					<div class="flex-row-no-space farmer-details-profile-tabs">
						<div :class="{ 'active-tab': activeIndex === 0 }" class="farmer-details-profile-tab btn">
							<a @click="farmerProfileTab()">Profile</a>
						</div>
						<div v-if="isAdmin" :class="{ 'active-tab': activeIndex === 1 }" class="farmer-details-profile-update btn">
							<a @click="farmerUpdateTab()">Edit</a>
						</div>
					</div>
					<farmer-profile-details v-if="activeIndex === 0" :selectedFarmer="selectedFarmer"></farmer-profile-details>
					<farmer-update-profile
						v-if="activeIndex === 1"
						:selectedFarmer="selectedFarmer"
						@cancelupdate="farmerProfileTab()"
						@backtoprofile="farmerProfileTab"
						@farmerupdatedetails="farmerUpdateDetails"
					></farmer-update-profile>
				</div>

				<farmer-sessions-table :userId="id"></farmer-sessions-table>
			</div>
			<div class="col-lg-5 col-md-12">
				<crop-details :selectedFarmer="selectedFarmer"></crop-details>
				<farmer-payments :farmerId="id"></farmer-payments>
				<farmer-analysis-table :selectedFarmer="selectedFarmer"></farmer-analysis-table>
			</div>
			<div class="col-lg-3 col-md-12">
				<farmer-notifications :userId="id" :userName="getUserName"></farmer-notifications>
				<farmer-activity-log :activities="selectedFarmer.activityLog"></farmer-activity-log>
			</div>
		</div>
	</div>
</template>

<script>
/*eslint-disable vue/no-unused-components */

import FarmerSessionsTable from './FarmerSessionsTable.vue';
import BreadCrumbTable from '@/components/BreadCrumbTable';
import farmerService from '../../services/farmerService';
import FarmerProfileDetails from './FarmerProfileDetails';
import FarmerPayments from './FarmerPayments';
import CropDetails from '../crops/CropDetails';
import FarmerAnalysisTable from './FarmerAnalysisTable';
import FarmerNotifications from './FarmerNotifications';
import FarmerActivityLog from './FarmerActivityLog';
import FarmerUpdateProfile from './FarmerUpdateProfile';

export default {
	name: 'FarmerProfile',
	props: {
		id: String,
	},
	components: {
		FarmerSessionsTable,
		FarmerActivityLog,
		FarmerNotifications,
		FarmerPayments,
		CropDetails,
		BreadCrumbTable,
		FarmerProfileDetails,
		FarmerUpdateProfile,
		FarmerAnalysisTable,
	},
	data() {
		return {
			selectedFarmer: null,
			activeIndex: 0,
		};
	},
	methods: {
		farmerProfileTab() {
			this.activeIndex = 0;
		},
		farmerUpdateTab() {
			this.activeIndex = 1;
		},
		farmerUpdateDetails(farmer) {
			this.selectedFarmer = farmer;
		},
	},
	computed: {
		getUserName() {
			return this.selectedFarmer.firstName + ' ' + this.selectedFarmer.lastName;
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	created() {
		farmerService.getFarmerDetailsById(this.id).then((response) => {
			this.selectedFarmer = response.data.data;
			this.selectedFarmer.businessPlans = response.data.farmerBusinessPlan;
			this.selectedFarmer.activityLog = response.data.farmerActivityLogs;
		});
	},
};
</script>

<style scoped>
.crop-table-container {
	display: flex;
}
.farmer-details-profile-tabs {
	margin-bottom: 20px;
}
.farmer-details-profile {
	margin-bottom: 20px;
	min-height: 400px;
}
.active-tab {
	font-weight: bold;
	border-bottom: 3px solid black;
	border-radius: unset;
}
</style>
