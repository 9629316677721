<template>
	<div class="farmer-update-profile-container" v-if="selectedBU">
		<div class="farmer-update-profile-form">
			<form @submit.prevent="onSubmit">
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Company</label>
					<input
						type="text"
						v-model="BUToUpdate.company"
						class="farmer-update-profile-input"
						@blur="v$.BUToUpdate.company.$touch()"
					/>
					<div v-if="v$.BUToUpdate.company.$error">
						<p v-if="v$.BUToUpdate.company.required" class="errorMessage">Company name is required</p>
					</div>
				</div>

				<div class="farmer-update-profile-input-container">
					<div class="d-flex flex-column medium-input">
						<label class="farmer-update-profile-label">Country</label>
						<select class="farmer-update-profile-input" v-model="BUToUpdate.country">
							<option selected>{{ BUToUpdate.country }}</option>
							<option v-for="item in countries" :value="item.country" :key="item.id">
								{{ item.country }}
							</option>
						</select>
					</div>
				</div>

				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Contact Name</label>
					<input
						type="text"
						v-model="BUToUpdate.contactName"
						class="farmer-update-profile-input"
						@blur="v$.BUToUpdate.contactName.$touch()"
					/>
					<div v-if="v$.BUToUpdate.contactName.$error">
						<p v-if="v$.BUToUpdate.contactName.required" class="errorMessage">Contact name is required</p>
					</div>
				</div>

				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Contact Email</label>
					<input
						type="text"
						v-model="BUToUpdate.email"
						class="farmer-update-profile-input"
						@blur="v$.BUToUpdate.email.$touch()"
					/>
					<div v-if="v$.BUToUpdate.email.$error">
						<p v-if="v$.BUToUpdate.email.required" class="errorMessage">Email is required</p>
					</div>
				</div>

				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Contact Phone</label>
					<input
						type="text"
						v-model="BUToUpdate.phone"
						class="farmer-update-profile-input"
						@blur="v$.BUToUpdate.phone.$touch()"
					/>
					<div v-if="v$.BUToUpdate.phone.$error">
						<p v-if="v$.BUToUpdate.phone.required" class="errorMessage">Phone name is required</p>
					</div>
				</div>

				<div class="farmer-update-profile-input-container">
					<div class="d-flex flex-column medium-input">
						<label class="farmer-update-profile-label">Type</label>
						<select class="farmer-update-profile-input" v-model="BUToUpdate.type">
							<option v-for="type in types" :value="type" :key="type">
								{{ type }}
							</option>
						</select>
					</div>
				</div>

				<!-- <label class="farmer-update-profile-label">Business Plans</label>
        <div class="farmer-update-profile-input-container">
          <button
            class="farmer-update-business-plan"
            v-for="businessPlan in BUToUpdate.businessPlans"
            :key="businessPlan.id"
            disabled
          >
            {{ businessPlan.businessPlanName }}
          </button>
        </div> -->

				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Status</label>
					<select v-model="BUToUpdate.status" class="select-status" :class="getActivityBadgeStatus(BUToUpdate.status)">
						<!-- <option>{{ BUToUpdate.status }}</option> -->
						<!-- <option v-if="BUToUpdate.status === 'ACTIVE'">NOT_ACTIVE</option>
            <option v-else>ACTIVE</option> -->
						<option value="ACTIVE">ACTIVE</option>
						<option value="NOT_ACTIVE">NOT ACTIVE</option>
					</select>
				</div>

				<!-- <div
          class="d-flex flex-column farmer-update-profile-input-container"
          v-if="selectedBU.activeUntilDate"
        >
          <label class="farmer-update-profile-label">Valid Until</label>
          <input
            type="text"
            :placeholder="getFormattedDate(BUToUpdate.activeUntilDate)"
            class="farmer-update-profile-input"
            disabled
          />
        </div> -->

				<div class="flex-row-no-space farmer-update-profile-btns">
					<button type="submit" class="btn blue-btn form-btn" name="update" id="1">Update</button>

					<button type="button" @click="cancelUpdate" class="btn form-btn-cancel" name="cancel" id="2">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email} from '@vuelidate/validators';
import activityStatus from '../../utilities/activityStatus';
import dates from '../../utilities/dates';
import utilities from '../../utilities/getCountries';
export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'BusinessUserUpdateProfile',
	props: {
		selectedBU: Object,
	},
	data() {
		return {
			BUToUpdate: { ...this.selectedBU },
			countries: [],
			types: ['Dealer', 'Cooperative'],
		};
	},
	validations: {
		BUToUpdate: {
			contactName: {
				required,
			},
			company: {
				required,
			},
			phone: {
				required,
			},
			email: {
				required, email
			},
		},
	},
	methods: {
		onSubmit() {
			// this.BUToUpdate.status = this.BUToUpdate.status.replace('_', ' ');
			if (!this.v$.$invalid) {
				this.BUToUpdate.businessUserId = this.BUToUpdate._id;
				this.$store.dispatch('businessUser/updateBusinessUser', this.BUToUpdate);
				this.$emit('buserupdatedetails', this.BUToUpdate);

				setTimeout(() => {
					this.$emit('backtoprofile');
				}, 500);
			}
		},
		cancelUpdate() {
			this.$emit('cancelupdate');
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		getFormattedDate(date) {
			return dates.convertToShortDate(date);
		},
	},
	beforeCreate() {
		console.log('BUToUpdate', this.BUToUpdate);
		utilities.getCountries().then((response) => {
			this.countries = response.data.data;
		});
	},
};
</script>
