import UserService from '../../services/userService';
export const namespaced = true;

export const state = {
	user: null,
	userToken: null,
	isRememberMe: true,
};

export const mutations = {
	SET_USER_TOKEN(state, token) {
		state.userToken = token;
	},
	SET_USER_DATA(state, userData) {
		state.user = userData;
	},
	CLEAR_USER_DATA(state) {
		state.user = null;
		state.userToken = null;
		location.reload();
	},
	//   SET_REMEMBER_ME(state, isRememberMe) {
	//     state.isRememberMe = isRememberMe;
	//   },
};

export const actions = {
	loginUser({ commit }, { emailAddress, password }) {
		return new Promise((resolve) => {
			UserService.userLogin(emailAddress, password)
				.then((response) => {
					commit('SET_USER_TOKEN', response.data.token);
					commit('SET_USER_DATA', response.data.data);
					console.log(response.data.data);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response.data);
					// this.$router.push({ path: "/authentication/page-404" });
				});
		});
	},
	logoutUser({ commit }) {
		// Cookies.remove('vuex')
		commit('CLEAR_USER_DATA');
	},
	// eslint-disable-next-line no-unused-vars
	forgetPasswordUser({ state }, emailAddress) {
		return new Promise((resolve) => {
			UserService.userForgetPassword(emailAddress)
				.then((response) => {
					console.log(response);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response.data);
					// this.$router.push({ path: "/authentication/page-404" });
				});
		});
	},
	isRememberUser({ commit }, isRememberMe) {
		commit('SET_REMEMBER_ME', isRememberMe);
	},
};

export const getters = {
	getUser: (state) => {
		return state.user;
	},
	getUserToken: (state) => {
		return state.userToken;
	},
	getIsRememberUser: (state) => {
		return state.isRememberMe;
	},
	getUserRole: (state) => {
		return state.user?.role;
	},
	getUserFullName: (state) => {
		return state.user?.firstName + ' ' + state.user?.lastName;
	},
};
