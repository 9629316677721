<template>
	<div></div>
</template>

<script>
export default {
	name: 'AutoLogoutComponent',
	data() {
		return {
			events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
			logoutTimer: null,
		};
	},
	mounted() {
		this.events.forEach(function (event) {
			window.addEventListener(event, this.resetTimer);
		}, this);
		this.setTimers();
	},
	destroyed() {
		this.events.forEach(function (event) {
			window.removeEventListener(event, this.resetTimer);
		}, this);
		this.resetTimer();
	},
	methods: {
		setTimers() {
			this.logoutTimer = setTimeout(this.logoutUser, 86400 * 1000);
		},
		logoutUser() {
			this.$store.dispatch('userSession/logoutUser');
		},
		resetTimer() {
			clearTimeout(this.logoutTimer);
			this.setTimers();
		},
	},
};
</script>

<style scoped></style>
