<template>
	<div class="row modal-container large-modal">
		<add-user-form
			v-if="addUserForm"
			:userToUpdate="userToUpdate"
			@updateuser="updateUser"
			:userName="userName"
			:userType="userType"
		>
		</add-user-form>

		<add-business-user-form v-if="addBusinessUserForm"> </add-business-user-form>
	</div>
</template>

<script>
import AddUserForm from '../forms/AddUserForm.vue';
import AddBusinessUserForm from '../forms/AddBusinessUserForm.vue';
export default {
	components: { AddUserForm, AddBusinessUserForm },
	name: 'ModalAddUser',
	props: {
		userToUpdate: Object,
	},
	data() {
		return {
			addUserForm: false,
			addBusinessUserForm: false,
			userName: '',
			userType: '',
		};
	},
	methods: {
		closeModal() {
			this.$emit('closemodal');
		},
		updateUser(userName, userType) {
			this.userName = userName;
			this.userType = userType;
			this.$emit('updateuser', userName, userType);
		},
	},
	created: function () {
		if (this.$route.matched.some(({ name }) => name === 'business-users-management')) {
			this.addBusinessUserForm = true;
		} else {
			this.addUserForm = true;
		}
	},
};
</script>

<style>
.large-modal {
	top: 100px;
}
.concil-password {
	position: absolute;
	right: 0px;
	bottom: 6px;
}
.password-input i {
	right: 0;
}
.password-input {
	margin-right: unset;
	width: 205px;
}
.password-input input {
	width: 100%;
}
.input-error-msg {
	margin-bottom: unset;
	color: red;
}
</style>
