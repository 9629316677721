<template>
	<div id="main-content" class="blog-page">
		<div class="container-fluid">
			<div class="block-header">
				<bread-crumb></bread-crumb>
			</div>
			<div class="row clearfix">
				<div class="col-lg-12 col-md-12">
					<div class="card planned_task">
						<div class="header">
							<h2>Stater Page</h2>
							<ul class="header-dropdown">
								<li class="dropdown">
									<a
										href="javascript:void(0);"
										class="dropdown-toggle"
										data-toggle="dropdown"
										role="button"
										aria-haspopup="true"
										aria-expanded="false"
									></a>
									<ul class="dropdown-menu dropdown-menu-right">
										<li><a href="javascript:void(0);">Action</a></li>
										<li><a href="javascript:void(0);">Another Action</a></li>
										<li><a href="javascript:void(0);">Something else</a></li>
									</ul>
								</li>
							</ul>
						</div>
						<div class="body">
							<h4>Stater Page</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb';

export default {
	name: 'BlankComponent',
	components: {
		BreadCrumb,
	},
	data() {
		return {};
	},
};
</script>
<style scoped></style>
