import axios from 'axios';

export default {
	getCountries() {
		let config = {
			method: 'get',
			url: 'https://countriesnow.space/api/v0.1/countries/codes',
		};
		return axios(config);
	},
};
