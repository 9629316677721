<template>
	<div class="farmer-profile-details">
		<div class="farmer-profile-details-row">
			<p>First Name</p>
			<h6>{{ selectedFarmer.firstName }}</h6>
		</div>
		<div class="farmer-profile-details-row">
			<p>Last Name</p>
			<h6>{{ selectedFarmer.lastName }}</h6>
		</div>
		<div class="farmer-profile-details-row">
			<p>Country</p>
			<h6>{{ selectedFarmer.country }}</h6>
		</div>
		<div class="farmer-profile-details-row">
			<p>Dealer</p>
			<h6 v-if="!dealer">Individual User</h6>
			<h6 v-if="dealer">{{ `${dealer.data.contactName}, ${dealer.data.areaCode}${dealer.data.phone}` }}</h6>
		</div>
		<div class="farmer-profile-details-row" v-if="selectedFarmer.userType !== 'private_agro'">
			<p>Total Size</p>
			<h6>{{ totalFieldSize }} Hacta</h6>
		</div>
		<div class="farmer-profile-details-row">
			<p>Business Plans</p>
			<div class="farmer-profile-details-row no-border">
				<button
					class="farmer-update-business-plan"
					v-for="businessPlan in selectedFarmer.businessPlans"
					:key="businessPlan.id"
					disabled
				>
					{{ removeUnderScore(businessPlan.businessPlanName) }}
				</button>
				<div>
					<p class="set-margin">Valid Until</p>
				</div>

				<button
					class="farmer-update-business-plan"
					v-for="date in selectedFarmer.businessPlans"
					:key="date.id"
					disabled
				>
					{{ getFormattedDate(date.validUntilDate) }}
				</button>
			</div>
		</div>

		<div class="farmer-profile-details-row">
			<p>Status</p>
			<div class="crop-status" :class="getActivityBadgeStatus(selectedFarmer.status)">
				{{ selectedFarmer.status }}
			</div>
		</div>

		<div class="farmer-profile-details-row">
			<p>Email Address</p>
			<h6 class="farmer-profile-email">{{ selectedFarmer.email }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Receive Marketing updates</p>
			<h6>{{ selectedFarmer.marketingAcceptance == true ? 'Yes' : 'No' }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Log Level</p>
			<h6>{{ selectedFarmer.logLevel }}</h6>
		</div>
	</div>
</template>

<script>
import dates from '../../utilities/dates';
import activityStatus from '../../utilities/activityStatus';
import RemoveUnderscore from '../../utilities/removeUnderscore';

export default {
	name: 'FarmerProfileDetails',
	props: {
		selectedFarmer: Object,
	},
	data() {
		return {
			totalFieldSize: 0,
			dealer: null
		};
	},
	methods: {
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		getFormattedDate(date) {
			return dates.convertToShortDate(date);
		},
		removeUnderScore(str) {
			return RemoveUnderscore.removeUnderscrore(str);
		},
	},
	async created() {

		const totalFieldSize = this.selectedFarmer.businessPlans.map((plan) => (plan.totalFieldSize * 100) / 120);
		for (let size of totalFieldSize) {
			this.totalFieldSize += size;
		}

		if(this.selectedFarmer.businessUserId) {
			this.dealer = await this.$store.dispatch('businessUser/getUser', this.selectedFarmer.businessUserId);
		}
	},
};

</script>

<style scoped>
.crop-status {
	margin-bottom: 10px;
}
.set-margin {
	margin-top: 5px;
	margin-bottom: 0px;
}
</style>
