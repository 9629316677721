<template>
	<div v-if="activities">
		<div class="farmer-notifications-container">
			<h6>Activity Log ({{ activities.length }})</h6>
			<div class="farmer-payments-table">
				<farmer-account-plans :props="activities" @showaction="showActivity"></farmer-account-plans>
			</div>
		</div>
	</div>
</template>
<script>
import dates from '../../utilities/dates';
import FarmerAccountPlans from './FarmerAccountPlans';

export default {
	name: 'FarmerActivityLog',
	props: {
		activities: Array,
	},
	components: { FarmerAccountPlans },
	methods: {
		getFullDate(timestamp) {
			return dates.getShortDateFromTimeStamp(timestamp);
		},
		showActivity(activity) {
			console.log(activity);
		},
	},
};
</script>

<style scoped></style>
