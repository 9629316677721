<template>
	<div id="main-content">
		<div class="container-fluid">
			<div class="block-header">
				<bread-crumb></bread-crumb>
			</div>
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="header">
							<h2>Basic Table <small>Basic example without any additional modification classes</small></h2>
						</div>
						<div class="body table-responsive">
							<table class="table">
								<thead>
									<tr>
										<th>#</th>
										<th>FIRST NAME</th>
										<th>LAST NAME</th>
										<th>USERNAME</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">1</th>
										<td>Mark</td>
										<td>Otto</td>
										<td>@mdo</td>
									</tr>
									<tr>
										<th scope="row">2</th>
										<td>Jacob</td>
										<td>Thornton</td>
										<td>@fat</td>
									</tr>
									<tr>
										<th scope="row">3</th>
										<td>Larry</td>
										<td>the Bird</td>
										<td>@twitter</td>
									</tr>
									<tr>
										<th scope="row">4</th>
										<td>Larry</td>
										<td>Jellybean</td>
										<td>@lajelly</td>
									</tr>
									<tr>
										<th scope="row">5</th>
										<td>Larry</td>
										<td>Kikat</td>
										<td>@lakitkat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="card">
						<div class="header">
							<h2>
								Striped Rows
								<small
									>Use <code>.table-striped</code> to add zebra-striping to any table row within the
									<code>&lt;tbody&gt;</code></small
								>
							</h2>
						</div>
						<div class="body table-responsive">
							<table class="table table-striped">
								<thead>
									<tr>
										<th>#</th>
										<th>FIRST NAME</th>
										<th>LAST NAME</th>
										<th>USERNAME</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">1</th>
										<td>Mark</td>
										<td>Otto</td>
										<td>@mdo</td>
									</tr>
									<tr>
										<th scope="row">2</th>
										<td>Jacob</td>
										<td>Thornton</td>
										<td>@fat</td>
									</tr>
									<tr>
										<th scope="row">3</th>
										<td>Larry</td>
										<td>the Bird</td>
										<td>@twitter</td>
									</tr>
									<tr>
										<th scope="row">4</th>
										<td>Larry</td>
										<td>Jellybean</td>
										<td>@lajelly</td>
									</tr>
									<tr>
										<th scope="row">5</th>
										<td>Larry</td>
										<td>Kikat</td>
										<td>@lakitkat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="card">
						<div class="header">
							<h2>
								Bordered Table
								<small>Add <code>.table-bordered</code> for borders on all sides of the table and cells.</small>
							</h2>
						</div>
						<div class="body table-responsive">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>#</th>
										<th>FIRST NAME</th>
										<th>LAST NAME</th>
										<th>USERNAME</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">1</th>
										<td>Mark</td>
										<td>Otto</td>
										<td>@mdo</td>
									</tr>
									<tr>
										<th scope="row">2</th>
										<td>Jacob</td>
										<td>Thornton</td>
										<td>@fat</td>
									</tr>
									<tr>
										<th scope="row">3</th>
										<td>Larry</td>
										<td>the Bird</td>
										<td>@twitter</td>
									</tr>
									<tr>
										<th scope="row">4</th>
										<td>Larry</td>
										<td>Jellybean</td>
										<td>@lajelly</td>
									</tr>
									<tr>
										<th scope="row">5</th>
										<td>Larry</td>
										<td>Kikat</td>
										<td>@lakitkat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="card">
						<div class="header">
							<h2>
								Hover Rows
								<small
									>Add <code>.table-hover</code> to enable a hover state on table rows within a
									<code>&lt;tbody&gt;</code>.</small
								>
							</h2>
						</div>
						<div class="body table-responsive">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>#</th>
										<th>FIRST NAME</th>
										<th>LAST NAME</th>
										<th>USERNAME</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">1</th>
										<td>Mark</td>
										<td>Otto</td>
										<td>@mdo</td>
									</tr>
									<tr>
										<th scope="row">2</th>
										<td>Jacob</td>
										<td>Thornton</td>
										<td>@fat</td>
									</tr>
									<tr>
										<th scope="row">3</th>
										<td>Larry</td>
										<td>the Bird</td>
										<td>@twitter</td>
									</tr>
									<tr>
										<th scope="row">4</th>
										<td>Larry</td>
										<td>Jellybean</td>
										<td>@lajelly</td>
									</tr>
									<tr>
										<th scope="row">5</th>
										<td>Larry</td>
										<td>Kikat</td>
										<td>@lakitkat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb';
export default {
	name: 'NormalComponent',
	components: {
		BreadCrumb,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>
<style scoped></style>
