<template>
	<div id="main-content" v-if="farmers">
		<div class="body table-responsive">
			<div class="farmer-details-profile border-padding">
				<h6 class="users-list">Users List ({{ farmers.length }})</h6>
				<paging :filteredFarmers="displayFarmers" @updatevisiblefarmers="updateVisibleFarmers"></paging>
				<table class="table table-hover">
					<thead class="crop-table-head">
						<tr>
							<th class="crop-table-head">Phone Number</th>
							<th class="crop-table-head">First Name</th>
							<th class="crop-table-head">Last Name</th>
							<th class="crop-table-head">Plan</th>
							<th class="crop-table-head">Size</th>
							<th class="crop-table-head">Used</th>
							<th class="crop-table-head">Status</th>
						</tr>
					</thead>

					<tbody class="crop-table-body">
						<tr v-for="farmer in visibleFarmers" :key="farmer._id">
							<td>{{ farmer.phoneNumber }}</td>
							<td>{{ farmer.firstName }}</td>
							<td>{{ farmer.lastName }}</td>
							<td>{{ removeUnderScore(farmer.plan) }}</td>
							<td>{{ farmer.size }}</td>
							<td>{{ floorUsedValue(farmer.used) }}</td>
							<td class="td">
								<div class="crop-status" :class="getActivityBadgeStatus(farmer.status)">
									{{ farmer.status }}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import userService from '../../services/userService';
import activityStatus from '../../utilities/activityStatus';
import RemoveUnderscore from '../../utilities/removeUnderscore';
import paging from '@/components/admin/app/paging';
export default {
	name: 'BusinessUserFarmers',
	props: {
		id: String,
	},
	components: {
		paging,
	},
	data() {
		return {
			farmers: null,
			visibleFarmers: null,
		};
	},
	methods: {
		//   loadBussinessUserFarmers() {
		//   this.$store.dispatch("businessUser/getBusinessUserFarmers",this.id);
		// },
		floorUsedValue(used) {
			return Math.floor(used);
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		removeUnderScore(str) {
			return RemoveUnderscore.removeUnderscrore(str);
		},
		updateVisibleFarmers(visibleFarmers) {
			this.visibleFarmers = visibleFarmers;
		},
	},
	computed: {
		bussinessUserFarmers() {
			return this.$store.getters['farmer/farmersForDisplay'];
		},
		displayFarmers() {
			return this.farmers;
		},
	},
	async created() {
		// this.loadBussinessUserFarmers();
		try {
			const response = await userService.getBusinessUserFarmers(this.id);
			this.farmers = response.data.data.farmersArray;
			console.log(this.farmers);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
};
</script>

<style scoped>
.farmer-search-message {
	text-align: center;
	margin-top: 50px;
}
.search-farmer-input {
	height: 34px;
}
.flex-end {
	align-items: flex-end;
}
.farmer-table-action {
	text-align: right;
}
.farmer-cell-actions {
	text-align: right;
}
.pencil-margin {
	margin-left: 5px;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.paging-exporting-container {
	margin-top: 20px;
}
.card td {
	font-weight: 300;
}
.farmer-date {
	font-size: 12px;
}
.users-list {
	margin-bottom: 20px;
}
</style>
