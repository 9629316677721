<template>
	<div class="card">
		<div class="header">
			<h2>Lucid Feeds</h2>
		</div>
		<div class="body">
			<ul class="list-unstyled feeds_widget">
				<li>
					<div class="feeds-left"><i class="fa fa-thumbs-o-up"></i></div>
					<div class="feeds-body">
						<h4 class="title">7 New Feedback <small class="float-right text-muted">Today</small></h4>
						<small>It will give a smart finishing to your site</small>
					</div>
				</li>
				<li>
					<div class="feeds-left"><i class="fa fa-user"></i></div>
					<div class="feeds-body">
						<h4 class="title">New User <small class="float-right text-muted">10:45</small></h4>
						<small>I feel great! Thanks team</small>
					</div>
				</li>
				<li>
					<div class="feeds-left"><i class="fa fa-question-circle"></i></div>
					<div class="feeds-body">
						<h4 class="title text-warning">Server Warning <small class="float-right text-muted">10:50</small></h4>
						<small>Your connection is not private</small>
					</div>
				</li>
				<li>
					<div class="feeds-left"><i class="fa fa-check"></i></div>
					<div class="feeds-body">
						<h4 class="title text-danger">Issue Fixed <small class="float-right text-muted">11:05</small></h4>
						<small>WE have fix all Design bug with Responsive</small>
					</div>
				</li>
				<li>
					<div class="feeds-left"><i class="fa fa-shopping-basket"></i></div>
					<div class="feeds-body">
						<h4 class="title">7 New Orders <small class="float-right text-muted">11:35</small></h4>
						<small>You received a new oder from Tina.</small>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'LucidFeeds',
};
</script>
<style scoped></style>
