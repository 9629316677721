<template>
	<div class="farmer-notifications-component">
		<div
			@click.self="closeNotificationModal"
			:class="{ 'modal-mask': isNotificationModalOpen || isSuccessModalOpen }"
		></div>
		<div v-if="isAdmin">
			<button class="btn blue-btn create-notification" @click="openNotificationModal()">Create New Notification</button>
		</div>
		<modal-send-notification
			v-if="isNotificationModalOpen"
			@closemodal="closeNotificationModal()"
			@opensuccessmodal="openSuccessModal()"
			:userName="userName"
			:userId="userId"
		></modal-send-notification>
		<modal-success-dynamic
			v-if="isSuccessModalOpen"
			:userName="userName"
			:upperTextStartLine="upperTextStartLine"
			:upperTextEndLine="upperTextEndLine"
			:bottomText="bottomText"
			:isMessageShown="isMessageShown"
			:message="message"
			:title="title"
			@closesuccessmodal="closeSuccessModal()"
		></modal-success-dynamic>
		<div class="farmer-notifications-container">
			<div class="flex-row">
				<h6>Notifications ({{ notificationsForDisplay.length }})</h6>
			</div>
			<div class="farmer-payments-table">
				<farmer-account-plans
					:key="componentKey"
					:props="notifications"
					@showaction="showNotification"
				></farmer-account-plans>
			</div>
		</div>
	</div>
</template>
<script>
import dates from '../../utilities/dates';
import FarmerAccountPlans from './FarmerAccountPlans';
import ModalSendNotification from './../admin/modals/ModalSendNotification.vue';
import ModalSuccessDynamic from '../admin/modals/ModalSuccessDynamic.vue';
export default {
	name: 'FarmerNotifications',
	props: {
		userName: String,
		userId: String,
	},
	components: {
		FarmerAccountPlans,
		ModalSendNotification,
		ModalSuccessDynamic,
	},
	data() {
		return {
			upperTextStartLine: 'Notification to',
			upperTextEndLine: 'sent successfully',
			bottomText: "You can check full details in user's notification list",
			message: '',
			title: '',
			isNotificationModalOpen: false,
			isSuccessModalOpen: false,
			isMessageShown: false,
			notifications: [],
			componentKey: 0,
		};
	},
	methods: {
		async loadNotifications() {
			await this.$store.dispatch('notification/getNotifications', this.userId).then((res) => {
				this.notifications = res;
				this.componentKey++;
			});
		},
		getFullDate(timestamp) {
			return dates.getShortDateFromTimeStamp(timestamp);
		},
		showNotification(title, message) {
			this.isMessageShown = true;
			this.title = title;
			this.message = message;
			this.isSuccessModalOpen = true;
		},
		openNotificationModal() {
			this.isNotificationModalOpen = true;
		},
		closeNotificationModal() {
			this.isNotificationModalOpen = false;
		},
		openSuccessModal() {
			this.isMessageShown = false;
			this.isSuccessModalOpen = true;
			this.notifications = this.notificationsForDisplay;
			this.componentKey++;
		},
		closeSuccessModal() {
			this.componentKey++;
			this.isSuccessModalOpen = false;
		},
	},
	computed: {
		notificationsForDisplay() {
			return this.$store.getters['notification/notificationsForDisplay'];
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},

	created() {
		this.loadNotifications();
	},
};
</script>

<style scoped></style>
