<template>
	<div class="paging-container" :class="{ 'hidden-element': !visibleFarmers.length }">
		<div class="paging-left-buttons">
			<div class="badge-default flex-no-space">
				<p class="show-amount">{{ pageFirstResult }}-{{ pageLastResult }}/{{ filteredFarmers.length }}</p>
				<div>
					<button class="btn btn-outline-secondary btn-sm btn-paging" @click="updatePage(currentPage - 1)">
						<i data-v-34d97735 class="fa fa-angle-left"></i>
					</button>
					<button class="btn btn-outline-secondary btn-sm btn-paging" @click="updatePage(currentPage + 1)">
						<i data-v-34d97735 class="fa fa-angle-right"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'paging',
	props: {
		filteredFarmers: Array,
	},
	data() {
		return {
			currentPage: 0,
			pageSize: 20,
			visibleFarmers: [],
		};
	},
	computed: {
		pageFirstResult() {
			const firstResultOnPage = this.currentPage * this.pageSize + 1;
			if (firstResultOnPage < 1) return '1';
			else return firstResultOnPage;
		},
		pageLastResult() {
			const lastResultOnPage = this.currentPage * this.pageSize + this.pageSize;
			if (lastResultOnPage > this.filteredFarmers.length) {
				return this.filteredFarmers.length;
			} else {
				return lastResultOnPage;
			}
		},
	},
	methods: {
		updatePage(pageNumber) {
			let totalPages = Math.ceil(this.filteredFarmers.length / this.pageSize);
			this.currentPage = pageNumber;
			if (this.currentPage == totalPages || pageNumber < 0) {
				this.currentPage = 0;
			}
			this.updateVisibleFarmers();
		},
		updateVisibleFarmers() {
			this.visibleFarmers = this.filteredFarmers.slice(
				this.currentPage * this.pageSize,
				(this.currentPage + 1) * this.pageSize
			);
			if (!this.visibleFarmers.length && this.currentPage > 0) {
				this.updatePage(this.currentPage - 1);
			}
			this.$emit('updatevisiblefarmers', this.visibleFarmers);
		},
	},
	beforeMount: function () {
		this.updateVisibleFarmers();
	},
};
</script>

<style scoped>
.show-amount {
	padding-right: 10px;
	padding-top: 5px;
	font-size: 18px;
}
.hidden-element {
	visibility: hidden;
}
</style>
