// import UserService from 'src/services/userService.js'
// import Cookies from "js-cookie";

import UserService from '../../services/userService';
export const namespaced = true;
export const state = {
	users: [],
	filterBy: {
		name: '',
		active: '',
	},
};

export const mutations = {
	SET_FILTER(state, filterBy) {
		state.filterBy = filterBy;
	},
	SET_USERS(state, users) {
		state.users = users;
	},
	ADD_USER(state, user) {
		state.users.push(user);
	},
	UPDATE_USER(state, user) {
		const idx = state.users.findIndex((u) => u._id === user.boUserId);
		state.users.splice(idx, 1, user);
	},
	DELETE_USER(state, userId) {
		const idx = state.users.findIndex((user) => user._id === userId);
		state.users.splice(idx, 1);
	},
};

export const actions = {
	async getUsers({ commit }, payload) {
		let router = payload.router;
		try {
			const response = await UserService.getUsers();
			commit('SET_USERS', response.data.data);
		} catch (error) {
			console.log(`There was an error: ${error}`);
			router.push({ path: '/authentication/page-404' });
		}
	},
	addUser({ commit }, user) {
		return new Promise((resolve) => {
			UserService.addUser(user)
				.then((response) => {
					commit('ADD_USER', response.data.data);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response.data);
				});
		});
	},
	async updateUser({ commit }, user) {
		return new Promise((resolve) => {
			UserService.updateUser(user)
				.then((response) => {
					console.log(response);
					commit('UPDATE_USER', user);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
				});
		});
	},
	async deleteUser({ commit }, userId) {
		try {
			const response = await UserService.deleteUser(userId);
			console.log(response);
			commit('DELETE_USER', userId);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
};

export const getters = {
	usersForDisplay(state) {
		const checkTxt = state.filterBy.name.toLowerCase();
		const checkStatus = state.filterBy.active;
		return state.users.filter((user) => {
			return user.firstName.toLowerCase().includes(checkTxt) && user.status === checkStatus;
		});
	},
};
