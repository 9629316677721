<template>
	<div class="auth-box">
		<img class="top" src="@/assets/agriot-logo.png" alt="Agriot" />
		<div class="card">
			<div class="header">
				<h5 class="lead-auth">Login to your account</h5>
			</div>
			<div class="body">
				<form class="form-auth-small" v-on:submit.prevent="submit">
					<div class="form-group">
						<label for="signin-email" class="control-label sr-only">Email</label>
						<input
							type="email"
							class="form-control"
							id="signin-email"
							v-model="userEmail"
							@blur="v$.userEmail.$touch()"
							placeholder="Email"
						/>
						<div v-if="v$?.userEmail.$error">
							<p v-if="!v$.userEmail.email" class="errorMessage">Please enter a valid email</p>
							<p v-if="!v$.userEmail.required" class="errorMessage">Email is required</p>
						</div>
					</div>
					<div class="form-group">
						<label for="signin-password" class="control-label sr-only">Password</label>
						<input
							type="password"
							class="form-control"
							id="signin-password"
							v-model="userPassword"
							@blur="v$.userPassword.$touch()"
							placeholder="Password"
						/>
						<div v-if="v$?.userPassword.$error">
							<p v-if="!v$.userPassword.required" class="errorMessage">Password is required</p>
						</div>
					</div>
					<p class="error-msg">{{ loginErrorMsg }}</p>
					<!--       -->
					<button
						type="submit"
						class="btn btn-primary btn-lg btn-block"
						:disabled="v$?.$invalid"
						:class="v$?.$invalid ? 'btn-disabled' : ''"
					>
						LOGIN
					</button>
					<div class="bottom">
						<span class="helper-text m-b-10"
							><i class="fa fa-lock"></i>
							<router-link to="/authentication/page-forgot-password">Forgot password?</router-link></span
						>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'LoginAuthBox',
	props: {
		loginErrorMsg: String,
	},
	data() {
		return {
			isRememberMe: false,
			userEmail: '',
			userPassword: '',
		};
	},
	validations: {
		userEmail: {
			required,
			email,
		},
		userPassword: {
			required,
		},
	},
	methods: {
		submit() {
			this.$emit('submit-login-form', {
				emailAddress: this.userEmail,
				password: this.userPassword,
				isRememberMe: this.isRememberMe,
			});
		},
	},
};
</script>

<style scoped>
.error-msg {
	color: red;
}
.errorMessage {
	color: red;
	margin-bottom: 0;
}
.btn-disabled {
	cursor: not-allowed;
}
</style>
