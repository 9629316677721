<template>
	<div>
		<div class="overflow-auto b-table-container" v-if="items">
			<div class="form-input">
				<input id="filter-input" v-model="filter" type="search" placeholder="Search"  @input="filterItems" class="form-control " />
			</div>
			<table
				style="white-space: unset"
				class="table-striped"
				id="my-table"
				:fields="fields"
				:items="items"
				:per-page="perPage"
				:current-page="currentPage"
				small
				:filter="filter"
				:filter-included-fields="['notificationType', 'activityDescription']"
			>
  <div>
    <table>
      <tbody>
        <tr v-for="(item, index) in paginatedData" :key="index" class="table-row">
          <td style="white-space: normal" v-if="item?.activityDescription">
            {{ item?.activityDescription }}
          </td>
          <td style="white-space: normal" v-if="item?.notificationType">
            {{ item?.notificationType }}
          </td>
          <td v-if="item?.date">{{ getFullDate(item?.date) }}</td>
          <td v-if="item?.sentDate">{{ getFullDate(item?.sentDate) }}</td>
          <td v-if="item?.notificationType">
            <div class="table-icon-eye">
              <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1" @click="showAction(item?.title, item?.message)">
                <i class="icon-eye"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</table>

<div class="paginate">
	<paginate 	v-if="pageCount > 1"
		:pageCount="pageCount"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'page-item'"
      :prev-class="'page-item'"
      :next-class="'page-item'"
      :link-class="'page-link'"
	:margin-pages="0"
	:per-page="perPage"
	:currentPage="currentPage"
	@set-currentpage="setCurrentPage"
	v-model="currentPage"
		aria-controls="my-table"
    ></paginate>
</div>

		</div>

	</div>
	
</template>

<script>
import { reactive, ref, watch, computed } from 'vue';
import dates from '../../utilities/dates';
import Paginate from 'vuejs-paginate-next';

export default {
  name: 'FarmerAccountPlans',
  props: {
    props: Array,
    notifications: Array,
  },
  components: {
    Paginate
  },
  setup(props, { emit }) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const filter = ref(null);
    const items = ref(reactive(props.props)); 
    const fields = [
      { key: 'types', label: 'Type' },
      { key: 'dates', label: 'Sent date' },
      { key: 'icon-eye', label: '' }
    ];
    const rows = ref(items.value.length);

	const pageCount = computed(() =>
      Math.ceil(filteredItems.value.length / perPage.value)
    );

    const paginatedData = computed(() =>
	filteredItems.value.slice((currentPage.value - 1) * perPage.value, currentPage.value * perPage.value)
    );
	const setCurrentPage=(number)=> {
      this.currentPage = number;
    }

	const filteredItems = computed(() => {
    if (!filter.value) {
      return items.value;

    } else {
	return items.value.filter(item =>
      item?.activityDescription?.toLowerCase().includes(filter.value.toLowerCase()) ||
      item?.notificationType?.toLowerCase().includes(filter.value.toLowerCase())
      );
    }
  });

    const getFullDate = (timestamp) => {
      return dates.getShortDateFromTimeStamp(timestamp);
    };

    const showAction = (title, message) => {
      emit('showaction', title, message);
    };

    watch(() => props.props, (newVal) => {
      items.value = reactive(newVal);
    });

    items.value = JSON.parse(JSON.stringify(props.props));

    return {
      perPage,
      currentPage,
      filter,
      items,
      fields,
      rows,
      getFullDate,
      showAction,
	pageCount,
	paginatedData,
	setCurrentPage, 
	filteredItems,
    };
  }
};
</script>

<style scoped>
.b-table-container {
	min-height: 500px;
}

.b-table-container th {
	padding-left: 15px;
}

.form-input {
	margin-bottom: 10px;
	margin-left: 15px;
}

.table td {
	border-top: unset;
	font-size: 12px;
}

.form-control {
	width: 80%;
	padding: 3px;
}

.btn-outline-secondary {
	padding: 5px 0;
}

.pagination {
	padding-left: 15px;
}
.table-striped td{
width:100%; 
padding: 10px;
}
.paginate{
	margin-top: 15px;
	display:flex;
	justify-content: center;
}

</style>
