<template>
	<div class="row breadcrumb">
		<div
			@click.self="closeUserAddModal"
			:class="{
				'modal-mask': isAddUserModal || isUserSaved || isGroupNotificationModal,
			}"
		></div>
		<div class="col-lg-5 col-md-8 col-sm-12">
			<h4>
				<a href="javascript:void(0);" class="btn btn-xs btn-link btn-toggle-fullwidth"
					><i
						v-bind:class="{
							'fa fa-arrow-right': show,
							'fa fa-arrow-left': !show,
						}"
						class="fa fa-arrow-left"
						v-on:click="layoutFullwidth()"
					></i
				></a>

				{{ meta }}
			</h4>

			<ul class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link to="/dashboard/index"><i class="icon-home"></i></router-link>
				</li>
				<li class="breadcrumb-item" v-for="item in crumbs" :key="item.id">
					{{ item.text }}
				</li>
				<pre>&nbsp;</pre>

				{{
					farmerName
				}}
			</ul>
		</div>
		<div class="col-lg-7 col-md-4 col-sm-12 text-right text-bottom" v-if="itemsType.type === 'Users'">
			<button v-if="isAdmin" class="btn blue-btn" @click="showUserModal()">Add new user</button>
		</div>
		<modal-add-user v-if="isAddUserModal" @closemodal="closeUserAddModal" @saveuser="saveUser"></modal-add-user>

		<modal-add-crop v-if="isAddCropModal" @saveCrop="saveCrop" @closemodalmask="closeAddCropModal"></modal-add-crop>

		<modal-success
			v-if="isUserSaved"
			:userName="userName"
			:userType="userType"
			@closesuccessmodal="closeSuccessModal"
		></modal-success>
	</div>
</template>
<script>
import ModalSuccess from './admin/modals/ModalSuccess';
import ModalAddCrop from './admin/modals/ModalAddCrop';
import ModalAddUser from './admin/modals/ModalAddUser';

export default {
	components: { ModalSuccess, ModalAddCrop, ModalAddUser },
	props: {
		itemsType: Object,
		farmerName: String,
		isGroupNotificationModal: Boolean,
	},
	computed: {
		crumbs: function () {
			let pathArray = this.$route.path.split('/');
			pathArray.shift();
			let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
				breadcrumbArray.push({
					path: path,
					to: breadcrumbArray[idx - 1] ? '/' + breadcrumbArray[idx - 1].path + '/' + path : '/' + path,
					text: this.$route.matched[idx].meta.breadCrumb || path,
				});
				return breadcrumbArray;
			}, []);
			return breadcrumbs;
		},
		showAroundModal() {
			return { 'show-around-modal': !this.isAddCropModal };
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	methods: {
		saveUser(userName, userType) {
			this.isUserSaved = true;
			this.userName = userName;
			this.userType = userType;
		},
		layoutFullwidth() {
			this.show = !this.show;
			let sitebody = document.body;
			this.show ? sitebody.classList.add('layout-fullwidth') : sitebody.classList.remove('layout-fullwidth');
		},
		visitorsOptionsSeries() {
			return (this.data = [3, 5, 1, 6, 5, 4, 8, 3]);
		},
		visitOptionsSeries() {
			return (this.data = [4, 6, 3, 2, 5]);
		},
		showUserModal() {
			this.isAddUserModal = true;
		},
		closeSuccessModal() {
			this.isUserSaved = false;
		},
		closeUserAddModal() {
			this.isAddUserModal = false;
			this.closeGroupNotificationModal();
		},
		closeGroupNotificationModal() {
			this.$emit('closegroupnotificationmodal');
		},
	},
	data() {
		return {
			isUserSaved: null,
			isAddCropModal: null,
			isAddUserModal: null,
			userName: '',
			userType: '',
			show: false,
			meta: '',
			visitors: {
				grid: {
					left: 0,
					top: 0,
					right: 0,
					bottom: 0,
				},
				tooltip: {
					trigger: 'item',
					formatter: function (params) {
						var param = params;
						return param.marker + ' ' + param.value;
					},
				},
				xAxis: {
					type: 'category',
					show: false,
					boundaryGap: false,
				},
				yAxis: [
					{
						type: 'value',
						show: false,
					},
				],
				series: [
					{
						type: 'line',
						symbolSize: 1,
						showSymbol: true,
						data: this.visitorsOptionsSeries(),
						lineStyle: {
							width: 1,
						},
					},
				],
				color: ['#00c5dc'],
			},
			visit: {
				grid: {
					left: 0,
					top: 0,
					right: 0,
					bottom: 0,
				},
				tooltip: {
					trigger: 'item',
					formatter: function (params) {
						var param = params;
						return param.marker + ' ' + param.value;
					},
				},
				xAxis: {
					type: 'category',
					show: false,
					boundaryGap: false,
				},
				yAxis: [
					{
						type: 'value',
						show: false,
					},
				],
				series: [
					{
						type: 'line',
						symbolSize: 1,
						showSymbol: true,
						data: this.visitOptionsSeries(),
						lineStyle: {
							width: 1,
						},
					},
				],
				color: ['#f4516c'],
			},
		};
	},
	created() {
		this.meta = this.$route.meta.title;
	},
};
</script>
<style scoped>
.echart-line-chart {
	height: 20px;
	width: 112px;
}
.breadcrumb {
	background-color: white;
	margin-top: 30px;
	/*padding-top: 0px;*/
}
.show-around-modal {
	position: unset !important;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}
</style>
