<template>
	<div class="modal-success-container" v-if="isUserSaved">
		<div class="modal-container position-relative">
			<button @click="closeSuccessModal" class="btn position-absolute close-success-modal">X</button>
			<h5>
				<b> {{ userName }} </b> successfully added as {{ userType }}.
			</h5>
			<p>You can see full details in {{ userType }} list</p>
			<button @click="closeSuccessModal" class="btn blue-btn">Continue</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		userName: String,
		userType: String,
	},
	data() {
		return {
			isUserSaved: true,
		};
	},
	name: 'ModalSuccess',
	methods: {
		closeSuccessModal() {
			this.$emit('closesuccessmodal');
		},
	},
	created() {},
};
</script>

<style scoped>
.close-success-modal {
	top: 0px;
	right: 0;
	border: none;
	background-color: white;
	font-size: large;
}

.modal-container {
	border: 1px solid black;
	background-color: white;
	padding: 30px;
	left: 30%;
	width: 500px;
	text-align: center;
	border-radius: 8px;
}
</style>
