<template>
	<div class="vertical-align-wrap">
		<div class="vertical-align-middle auth-main">
			<div class="auth-box">
				<div class="top">
					<img src="../../../assets/logo-white.svg" alt="Lucid" />
				</div>
				<div class="card">
					<div class="header">
						<p class="lead">Recover my password</p>
					</div>

					<div class="body" v-if="!changePasswordSuccessMsg">
						<p>Please enter your email address below to receive instructions for resetting password.</p>
						<form class="form-auth-small" v-on:submit.prevent="submit">
							<div class="form-group">
								<input
									type="mail"
									v-model="email"
									class="form-control"
									id="signup-password"
									placeholder="Your Email Address"
									@blur="v$.email.$touch()"
								/>
							</div>
							<div v-if="v$.email.$error">
								<p v-if="!v$.email.email" class="input-error-msg">Please enter a valid email</p>
							</div>

							<!-- <div v-if="changePasswordErrorMsg">
                <p class="input-error-msg">Somthing wrong.. Please try later</p>
              </div> -->

							<button type="submit" class="btn btn-primary btn-lg btn-block">RESET PASSWORD</button>
							<div class="bottom">
								<span class="helper-text"
									>Know your password? <router-link to="/authentication/page-login">Login</router-link></span
								>
							</div>
						</form>
					</div>

					<div v-if="changePasswordSuccessMsg">
						<p>A new password has been sent to your Email, You will redirect to login page immediately</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email } from '@vuelidate/validators';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'ForgotPasswordComponent',
	data() {
		return {
			email: '',
			changePasswordSuccessMsg: false,
			// changePasswordErrorMsg: false,
		};
	},
	validations: {
		email: {
			email,
		},
	},
	methods: {
		submit(e) {
			e.preventDefault();

			this.$store.dispatch('userSession/forgetPasswordUser', { email: this.email }).then((response) => {
				if (response.success) {
					// this.changePasswordSuccessMsg = true;
					setTimeout(() => {
						this.$router.push({ path: '/authentication/page-login' });
					}, 4000);
				} else {
					// this.changePasswordErrorMsg = true;
					console.log(response.error);
				}
			});
		},
	},
};
</script>
