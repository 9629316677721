import XLSX from 'xlsx';

export default {
	exportToExcel(data) {
		let ws = XLSX.utils.json_to_sheet(data);
		let wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'users');
		XLSX.writeFile(wb, 'FarmersTable.xlsx');
	},

	exportToExcelWithCustomHeaders(data, headers) {
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, headers);
		XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
		XLSX.utils.book_append_sheet(wb, ws, 'users');
		XLSX.writeFile(wb, 'FarmersTable.xlsx');
	},
	checkByNameOrPhone(txt, name, phone) {
		return name.toLowerCase().includes(txt) || phone.includes(txt);
	},
};
