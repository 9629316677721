export default {
	getFullDate(timestamp) {
		const d = new Date(timestamp);
		let monthName = d.getMonth() + 1;
		let dayName = d.getDate();
		const yearName = d.getFullYear();
		if (String(dayName).length === 1) dayName = '0' + dayName;
		if (String(monthName).length === 1) monthName = '0' + monthName;
		return `${dayName}/${monthName}/${yearName}`;
	},
	convertToShortDate(date) {
		let timestamp = Date.parse(date);
		const d = new Date(timestamp);
		let dayName = d.getDate();
		const yearName = d.getFullYear();
		let monthName = d.toLocaleString('default', { month: 'short' });
		if (String(dayName).length === 1) dayName = '0' + dayName;
		return `${dayName}.${monthName}.${yearName}`;
	},
	getShortDateFromTimeStamp(timestamp) {
		const d = new Date(timestamp);
		let dayName = d.getDate();
		const yearName = d.getFullYear();
		let monthName = d.toLocaleString('default', { month: 'short' });
		if (String(dayName).length === 1) dayName = '0' + dayName;
		return `${dayName}.${monthName}.${yearName}`;
	},
	formatDateTime(isoDate) {
		const date = new Date(isoDate);

		const day = date.getDate().toString().padStart(2, '0');
		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		const month = monthNames[date.getMonth()];
		const year = date.getFullYear();

		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');

		return `${day}.${month}.${year} ${hours}:${minutes}`;
	},
};
