import { ActivityStatusEnum } from '../enums';

export default {
	getActivityBadgeStatus(status) {
		if (status) {
			if (status === ActivityStatusEnum.NOT_ACTIVE || status === ActivityStatusEnum.DECLINED) return 'badge-danger';
			if (status === ActivityStatusEnum.PENDING || status === ActivityStatusEnum.REFUND) return 'badge-warning';
			else if (status === ActivityStatusEnum.ACTIVE || status === ActivityStatusEnum.PAYMENT) return 'badge-success';
			return 'badge-default';
		} else return 'badge-success';
	},
};
