import axios from 'axios';
import store from '../store/store';

const envUrl = process.env.VUE_APP_URL;
const BASEURL = `${envUrl}user/`;
const APPKEY = 'bxXnw3Wxp5V4GWVei5EgvXdMnKB9llOHIBV4';

export default {
	sendNotification(notification) {
		let config = {
			method: 'post',
			url: `${BASEURL}sendNotification`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: notification,
		};
		return axios(config);
	},
};
