<template>
	<div id="main-content">
		<bread-crumb-table :itemsType="{ type: 'Access Token' }"></bread-crumb-table>
		<modal-create-token v-if="showCreateModal" @closemodal="closeCreateModal"></modal-create-token>
		<modal-delete-token
			v-if="deleteTokenId"
			@deletetoken="deleteToken()"
			@closedeletemodal="onCloseDeleteModal"
		></modal-delete-token>

		<div class="col-lg-12 crop-table-container">
			<div class="filters-section">
				<div class="flex-column">
					<p class="search-crop-title">Search Token</p>
					<div class="crop-input-icons input-icons first-input">
						<input placeholder="Search" />
						<!-- <span class="input-group-text"><i class="icon-magnifier"></i></span> -->
					</div>
				</div>

				<button @click="openCreateModal()" class="btn blue-btn create-token-btn">Create New Token</button>
			</div>

			<div class="card">
				<div class="body table-responsive">
					<table class="table table-hover" id="table-export-excel">
						<thead class="crop-table-head">
							<tr>
								<!-- <th>
									<input
										type="checkbox"
										@click="markShownFarmers()"
										class=""
										id="th-farmer-checkbox"
										:disabled="!isFiltered"
										v-model="isThChecked"
									/>
								</th> -->
								<th class="crop-table-head">Description</th>
								<th class="crop-table-head">Created at</th>
								<th class="crop-table-head">Last access</th>
								<th class="crop-table-head">Expire at</th>
								<th class="crop-table-head">Token</th>
								<th class="crop-table-head token-table-action">Action</th>
							</tr>
						</thead>
						<tbody class="crop-table-body">
							<tr v-for="token in filteredTokens" :key="token._id">
								<!-- <td>
									<input
										type="checkbox"
										@click="markFarmer(token)"
										class="farmer-checkbox"
										name="farmer-checkbox"
										id="checkbox"
										v-model="token.isChecked"
									/>
								</td> -->
								<td class="token-description">
									{{ token.description }}
								</td>

								<td>
									{{ getFormattedDate(token.createdAt) }}
								</td>
								<td>
									{{ token.lastAccessedAt ? getFormattedDate(token.lastAccessedAt) : '' }}
								</td>
								<td>
									{{ token.expireAt ? getFormattedDate(token.expireAt) : 'no expiration' }}
								</td>

								<td>{{ token.token }}</td>

								<td class="action-button-container">
									<router-link
										:to="{
											name: 'access-token-details',
											params: { id: token._id },
										}"
									>
										<a class="btn btn-outline-secondary pencil-margin">
											<i class="icon-eye" style="color: darkslategrey"></i
										></a>
									</router-link>

									<a class="btn btn-outline-secondary" @click="openOpenDeleteModal(token._id)">
										<i class="icon-trash"></i>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumbTable from '@/components/BreadCrumbTable';
import dates from '../../utilities/dates';
import ModalCreateToken from '../access-token/modals/ModalCreateToken.vue';
import accessTokenService from '../../services/accessTokenService';
import ModalDeleteToken from './modals/ModalDeleteToken.vue';
import { mapState } from 'vuex';

export default {
	name: 'AccessTokensTable',
	components: {
		BreadCrumbTable,
		ModalDeleteToken,
		ModalCreateToken,
	},
	data() {
		return {
			filteredTokens: [],
			showCreateModal: false,
			deleteTokenId: null,
		};
	},
	methods: {
		getFormattedDate(date) {
			return dates.convertToShortDate(date);
		},
		closeCreateModal() {
			this.showCreateModal = false;
		},
		openCreateModal() {
			this.showCreateModal = true;
		},
		openOpenDeleteModal(tokenId) {
			this.deleteTokenId = tokenId;
		},
		onCloseDeleteModal() {
			this.deleteTokenId = null;
		},
		async deleteToken() {
			if (!this.deleteTokenId) return;
			try {
				await accessTokenService.deleteAccessToken(this.deleteTokenId);
				this.$store.dispatch('accessToken/fetchTokens', { router: this.$router });
			} catch (error) {
				console.log(error);
			}
		},
	},
	computed: {
		...mapState('accessToken', ['accessTokens']),
	},
	watch: {
		accessTokens(newTokens) {
			this.filteredTokens = newTokens;
		},
	},
	created() {
		this.$store.dispatch('accessToken/fetchTokens');
	},
};
</script>

<style scoped>
.card td {
	font-weight: 300;
}

.filters-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.create-token-btn {
	height: 40px;
}

.token-description {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	font-weight: 500 !important;
}

.token-endpoints-list {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 10px;
	font-weight: bold !important;
}

.action-button-container {
	display: flex;
	gap: 5px;
	justify-content: flex-end;
}

.token-table-action {
	text-align: right;
}

.pencil-margin {
	margin-left: 5px;
}
</style>
