export default {
	KEYS_TO_EXPORT: ['status', 'firstName', 'lastName', 'email', 'country', 'totalBusinessPlansSize'],
	FARMER_EXTENDED_KEYS_TO_EXPORT: [
		'status',
		'firstName',
		'lastName',
		'email',
		'formattedPhone',
		'country',
		'totalBusinessPlansSize',
		'numberOfCrops',
		'cropTypesStr',
		'cropMeasurementUnitsStr',
		'businessPlansNamesStr',
		'businessPlansValidUntilDateFormatted',
		'isSubscribed',
		'userTypeFormatted',
		'lastSampleDateFormatted',
		'marketingAlertsApproved',
		'lastLoginDateFormatted',
		'isPayer',
		'_id',
	],
	FARMER_EXTENDED_HEADERS_TO_EXPORT: [
		[
			'Status',
			'First Name',
			'Last name',
			'Email',
			'Phone',
			'Location',
			'Size',
			'Plots',
			'Crops',
			'Unit',
			'Plans',
			'Subscription',
			'Program Validity',
			'User Type',
			'Last Sample',
			'Marketing Alerts',
			'Last Login',
			'Paying Customer',
			'Farmer ID',
		],
	],

	LONG_TEXT_NAX_LENGTH: 20,
};
