<template>
	<router-view />
</template>

<script>
// Used to fill parent routes that need to have children mounted in them
export default {
	name: 'dummy-view',
};
</script>
