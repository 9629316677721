<template>
	<div class="card">
		<div class="body search">
			<div class="input-group m-b-0">
				<div class="input-group-prepend">
					<span class="input-group-text"><i class="icon-magnifier"></i></span>
				</div>
				<input type="text" class="form-control" placeholder="Search..." />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Search',
};
</script>
<style scoped></style>
