import UserService from '../../services/businessUsersService';
import service from '../../services/userService';
import util from '../../utilities/util';

export const namespaced = true;
export const state = {
	user: null,
	userToken: null,
	isRememberMe: false,
	businessUserFarmers: [],
	users: [],
	filterBy: {
		name: '',
		country: '',
		active: 'ACTIVE',
		type: 'Dealer',
	},
};

export const mutations = {
	SET_BUSINESS_USER_FARMERS(state, businessUserFarmers) {
		state.businessUserFarmers = businessUserFarmers;
	},
	SET_USER_TOKEN(state, token) {
		state.userToken = token;
	},
	SET_USER_DATA(state, userData) {
		state.user = userData;
	},
	CLEAR_USER_DATA(state) {
		state.user = null;
		state.userToken = null;
		location.reload();
	},
	SET_REMEMBER_ME(state, isRememberMe) {
		state.isRememberMe = isRememberMe;
	},
	SET_FILTER(state, filterBy) {
		state.filterBy = filterBy;
	},
	SET_USERS(state, users) {
		state.users = users;
	},
	ADD_USER(state, user) {
		state.users.push(user);
	},
	UPDATE_BUSINESS_USER(state, user) {
		const idx = state.users.findIndex((f) => f._id === user._id);
		state.users.splice(idx, 1, user);
	},
	DELETE_BUSINESS_USER(state, userId) {
		const idx = state.users.findIndex((user) => user._id === userId);
		state.users.splice(idx, 1);
	},
};

export const actions = {
	loginUser({ commit }, { emailAddress, password, isRememberMe }) {
		return new Promise((resolve) => {
			UserService.userLogin(emailAddress, password)
				.then((response) => {
					commit('SET_USER_TOKEN', response.data.token);
					commit('SET_USER_DATA', response.data.data);
					commit('SET_REMEMBER_ME', isRememberMe);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response.data);
					this.$router.push({ path: '/authentication/page-404' });
				});
		});
	},
	logoutUser({ commit }) {
		// Cookies.remove('vuex')
		commit('CLEAR_USER_DATA');
	},
	isRememberUser({ commit }, isRememberMe) {
		commit('SET_REMEMBER_ME', isRememberMe);
	},
	async getUsers({ commit }, payload) {
		let router = payload.router;
		try {
			const response = await UserService.getUsers();
			commit('SET_USERS', response.data.data);
			console.log(response.data.data);
		} catch (error) {
			console.log(`There was an error: ${error}`);
			router.push({ path: '/authentication/page-404' });
		}
	},
	async getActiveUsers() {
		try {
			const response = await UserService.getUsers();
			return response.data.data.filter(user => user.status !== 'NOT_ACTIVE')
		} catch (error) {
			console.log(`There was an error: ${error}`);

		}
	},
	async getUser(_, userId) {
		try {
			const response = await UserService.getUser(userId);
			console.log(response.data.data);
			return response.data
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
	addBusinessUser({ commit }, user) {
		return new Promise((resolve) => {
			UserService.addBusinessUser(user)
				.then((response) => {
					commit('ADD_USER', response.data.data);
					resolve(response.data);
				})
				.catch((error) => {
					if (error.response) {
						console.log('error message:', error.response.data.error);
						commit('ADD_USER', error.response.data.error);
					}

					console.log(`There was an error: ${error}`);
					resolve(error.response.data);
				});
		});
	},
	async updateBusinessUser({ commit }, user) {
		try {
			const response = await UserService.updateBusinessUser(user);
			commit('UPDATE_BUSINESS_USER', response);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
	async deleteBusinessUser({ commit }, userId) {
		try {
			const response = await UserService.deleteBusinessUser(userId);
			console.log(response);
			commit('DELETE_BUSINESS_USER', userId);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
	async getBusinessUserFarmers({ commit }, businessUserId) {
		try {
			const response = await service.getBusinessUserFarmers(businessUserId);
			console.log(response.data.data.farmersArray);
			commit('SET_BUSINESS_USER_FARMERS', response.data.data.farmersArray);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
};

export const getters = {
	getUsers: (state) => {
		return state.user;
	},
	getUser: (state) => {
		return state.userId;
	},
	getUserToken: (state) => {
		return state.userToken;
	},
	getIsRememberUser: (state) => {
		return state.isRememberMe;
	},
	getUserRole: (state) => {
		return state.user?.type;
	},
	getUserFullName: (state) => {
		return state.user.contactName;
	},
	getBusinessUserFarmers: (state) => {
		return state.businessUserFarmers;
	},

	usersForDisplay(state) {
		const checkTxt = state.filterBy.name.toLowerCase();
		const checkCountry = state.filterBy.country.toLowerCase();
		const checkStatus = state.filterBy.active;
		const checkType = state.filterBy.type;

		let filteredUsers = state.users;

		filteredUsers = filteredUsers.filter((user) => {
			return (
				util.checkByNameOrPhone(checkTxt, user.contactName, user.phone) &&
				(checkStatus === '' || user.status === checkStatus) &&
				(checkType === '' || user.type === checkType) &&
				(checkCountry === '' || user.country.toLowerCase().trim() === checkCountry)
			);
		});

		return filteredUsers.map((user) => ({
			...user,
			isChecked: false,
		}));
	},
};
