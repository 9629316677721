<template>
	<div class="row modal-container" v-if="isAddCropModal">
		<div class="add-crop-modal">
			<div class="flex-row">
				<h6>Add New Crop</h6>
				<button class="close-modal-btn btn" @click="closeAddCropModal()">X</button>
			</div>
			<form class="form-add-crop" @submit.prevent="addCrop">
				<div class="d-flex flex-column">
					<label class="add-crop-label">Crop Name </label>
					<input
						class="form-control select-input"
						v-model="newCrop.name"
						type="text"
						placeholder="Type name here"
						@blur="v$.newCrop.name.$touch()"
					/>
					<div v-if="v$.newCrop.name.$error">
						<p v-if="!v$.newCrop.name.required" class="errorMessage">Crop name is required</p>
					</div>
				</div>
				<div class="top-form-section">
					<div class="d-flex flex-column">
						<label class="add-crop-label">Crop Type</label>
						<select
							v-model="newCrop.type"
							type="text"
							class="form-control input-style crop-input-type"
							@blur="v$.newCrop.type.$touch()"
						>
							<option v-for="type in types" :value="type" :key="type.id">
								{{ type }}
							</option>
						</select>
						<div v-if="v$.newCrop.type.$error">
							<p v-if="!v$.newCrop.type.required" class="errorMessage">Crop type is required</p>
						</div>
					</div>
					<div class="d-flex flex-column">
						<label class="add-crop-label">Language Key</label>
						<input
							class="form-control input-style"
							v-model="newCrop.languageKey"
							type="text"
							placeholder="Type here"
							@blur="v$.newCrop.languageKey.$touch()"
						/>
						<div v-if="v$.newCrop.languageKey.$error">
							<p v-if="!v$.newCrop.languageKey.required" class="errorMessage">Crop language key is required</p>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column">
					<label class="add-crop-label">Crop Image </label>
					<div class="position-relative fake-input">
						<input
							class="form-control add-crop-input-image"
							@change="onFileSelected"
							type="file"
							placeholder=""
							ref="fileInput"
							@blur="v$.selectedFile.$touch()"
						/>
						<i class="fa fa-file-picture-o position-absolute btn" @click="$refs.fileInput.click()"></i>
					</div>
					<div v-if="v$.selectedFile.$error">
						<p v-if="!v$.selectedFile.required" class="errorMessage">Crop image is required</p>
					</div>
				</div>
				<div v-if="selectedFile">Image added successfully!</div>
				<div class="next-btn-container">
					<button type="submit" class="next-btn btn">Save</button>
					<p v-if="errMsg" class="errorMessage">All fields are required</p>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';
import WP from '../../../utilities/WP';
export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'ModalAddCrop',
	data() {
		return {
			isAddCropModal: true,
			isCropSaved: false,
			newCrop: {
				name: '',
				type: '',
				languageKey: '',
			},
			selectedFile: null,
			types: [],
			errMsg: false,
		};
	},
	validations: {
		selectedFile: { required },
		newCrop: {
			name: {
				required,
			},
			type: {
				required,
			},
			languageKey: {
				required,
			},
		},
	},
	methods: {
		addCrop() {
			let formData = new FormData();
			formData.append('file', this.selectedFile);
			formData.append('cropName', this.newCrop.name);
			formData.append('cropType', this.newCrop.type);
			formData.append('languageKey', this.newCrop.languageKey);
			formData.append('status', 'ACTIVE');
			if (!this.v$.$invalid) {
				this.$store.dispatch('crop/addCrop', formData);
				this.isAddCropModal = false;
				this.isCropSaved = true;
				this.$emit('saveCrop', this.isCropSaved, this.newCrop.name);
			} else {
				this.errMsg = true;
				setTimeout(() => {
					this.errMsg = false;
				}, 3000);
				console.log('error');
			}
		},
		closeAddCropModal() {
			this.isAddCropModal = false;
			this.$emit('closemodalmask', this.isAddCropModal);
		},
		onFileSelected(event) {
			this.selectedFile = event.target.files[0];
			console.log(this.selectedFile);
		},
	},
	async created() {
		try {
			const response = await WP.getCropCategoriesTable();
			const types = JSON.parse(response.data.data.post_content);
			types.shift(); // this is for replacing the header from WP
			for (const type of types) {
				this.types.push(type.shift());
			}
		} catch (error) {
			console.log(`cannot get the crop types: ${error}`);
			this.$router.push({ path: '/authentication/page-404' });
		}
	},
};
</script>

<style scoped></style>
