<template>
	<div class="crop-details-container border-padding">
		<div>
			<h6 class="crop-details-length">Analysis Log</h6>
		</div>
		<table class="table table-striped">
			<thead>
				<tr>
					<th>Date</th>
					<th>Crop name</th>
					<th>Crop type</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="crop in paginatedAnalyses" :key="crop._id">
					<td>{{ formatDate(crop.createdAt || crop.sampleDate) }}</td>
					<td>{{ crop.cropId?.cropName || crop.cropName }}</td>
					<td>{{ crop.cropId?.cropType || crop.cropType }}</td>
					<td class="actions-row">
						<button class="btn btn-outline-secondary" @click="openModal(crop)" type="button">
							<i class="icon-eye"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>

		<!-- Pagination Controls -->
		<div class="paginate">
			<paginate
				v-if="pageCount > 1"
				:pageCount="pageCount"
				:prev-text="'<'"
				:next-text="'>'"
				:container-class="'pagination'"
				:page-class="'page-item'"
				:prev-class="'page-item'"
				:next-class="'page-item'"
				:link-class="'page-link'"
				:margin-pages="0"
				:per-page="perPage"
				:currentPage="currentPage"
				@set-currentpage="setCurrentPage"
				v-model="currentPage"
				aria-controls="my-table"
			></paginate>
		</div>

		<AnalysisDetailsModal :analysis="selectedCrop" v-if="showModal" @closemodal="toggleModal"></AnalysisDetailsModal>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/*eslint-disable no-unused-vars */
import farmerService from '../../services/farmerService';
import dates from '../../utilities/dates';
import AnalysisDetailsModal from './AnalysisDetailsModal';
import Paginate from 'vuejs-paginate-next';

export default {
	name: 'AnalysisDetails',
	components: {
		AnalysisDetailsModal,
		Paginate,
	},
	props: {
		selectedFarmer: Object,
	},
	data() {
		return {
			selectedCrop: null,
			analyses: [],
			showModal: false,
			currentPage: 1,
			perPage: 10, // Adjust the number of items per page as needed
		};
	},
	computed: {
		pageCount() {
			return Math.ceil(this.analyses.length / this.perPage);
		},
		paginatedAnalyses() {
			const start = (this.currentPage - 1) * this.perPage;
			const end = start + this.perPage;
			return this.analyses.slice(start, end);
		},
	},
	methods: {
		toggleModal() {
			this.showModal = !this.showModal;
		},
		openModal(crop) {
			this.selectedCrop = crop;
			this.toggleModal();
		},
		formatDate(date) {
			return dates.formatDateTime(date);
		},
		async fetchAnalysis() {
			try {
				const res = await farmerService.getCropFarmerAnalysis(this.selectedFarmer._id);
				if (res.data.data?.length) {
					this.analyses = res.data.data;
				}
			} catch (error) {
				console.log(`There was an error: ${error}`);
			}
		},
		setCurrentPage(page) {
			this.currentPage = page;
		},
	},
	async mounted() {
		this.fetchAnalysis();
	},
};
</script>

<style scoped>
.actions-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.page-item {
	margin: 0 5px;
}

.page-link {
	cursor: pointer;
}
</style>
