<template>
	<div class="row modal-container">
		<div class="add-crop-modal lift-modal">
			<div class="flex-row">
				<h6>Send New Notification</h6>
				<button class="close-modal-btn btn" @click="closeSendNotificationModal()">X</button>
			</div>
			<form class="form-add-crop" @submit.prevent="sendNotification">
				<div class="top-form-section">
					<div class="d-flex flex-column">
						<label class="add-crop-label">To</label>
						<input class="form-control input-style" v-model="notification.users" type="text" disabled />
					</div>
					<div class="d-flex flex-column notification-select">
						<label class="add-crop-label">Notification Type</label>
						<select v-model="type" class="form-control input-style select" @blur="v$.type.$touch()">
							<option value="" disabled>Choose type</option>
							<option v-for="type in types" :key="type._id">
								{{ type }}
							</option>
						</select>
						<div v-if="v$.type.$error">
							<p v-if="!v$.type.required" class="errorMessage">Notification type is required</p>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column">
					<label class="add-crop-label">Title</label>
					<input
						class="form-control select-input"
						v-model="notification.title"
						type="text"
						@blur="v$.notification.title.$touch()"
					/>
					<div v-if="v$.notification.title.$error">
						<p v-if="!v$.notification.title.required" class="errorMessage">Title is required</p>
					</div>
				</div>
				<div class="d-flex flex-column">
					<textarea
						class="notification-area"
						type="text"
						v-model="notification.message"
						placeholder="Type your notification here"
						@blur="v$.notification.message.$touch()"
					></textarea>
					<div v-if="v$.notification.message.$error">
						<p v-if="!v$.notification.message.required" class="errorMessage">Message is required</p>
					</div>
				</div>

				<div class="next-btn-container">
					<button class="cancel-btn btn" @click="closeSendNotificationModal()" type="button" value="Cancel">
						Cancel
					</button>
					<button type="submit" class="next-btn btn">Send</button>
					<p v-if="errMsg" class="errorMessage">All fields are required</p>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'ModalSendNotification',
	props: {
		userName: String,
		userId: String,
	},
	data() {
		return {
			types: ['Sms', 'App Notification', 'Email'],
			notification: {
				users: [this.userName],
				notificationsType: [],
				title: '',
				message: '',
			},
			type: '',
			errMsg: false,
		};
	},
	validations: {
		type: {
			required,
		},
		notification: {
			title: {
				required,
			},
			message: {
				required,
			},
		},
	},
	methods: {
		closeSendNotificationModal() {
			this.$emit('closemodal');
		},
		sendNotification() {
			if (!this.v$.$invalid) {
				this.notification.users.pop();
				this.notification.users.push(this.userId);
				this.notification.notificationsType.push(this.type);
				console.log(this.notification);
				this.$store.dispatch('notification/sendNotification', this.notification);
				this.closeSendNotificationModal();
				this.$emit('opensuccessmodal');
			} else {
				this.errMsg = true;
				setTimeout(() => {
					this.errMsg = false;
				}, 3000);
			}
		},
	},
};
</script>

<style scoped>
.notification-area {
	border: 1px solid black;
	border-radius: 5px;
	padding-bottom: 50px;
	margin-top: 20px;
	color: #495057;
}
.notification-select {
	margin-left: 20px;
}
select.form-control:not([size]):not([multiple]) {
	height: 39px;
}
.cancel-btn,
.next-btn {
	width: 35%;
}
.cancel-btn {
	color: rgb(110, 110, 187);
	background-color: white;
	font-weight: 600;
}
</style>
