<template>
	<div class="card right-box">
		<div class="header">
			<h2>Instagram Post</h2>
		</div>
		<div class="body widget">
			<ul class="list-unstyled instagram-plugin m-b-0">
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/05-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/06-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/07-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/08-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/09-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/10-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/11-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/12-img.jpg" alt="image description" /></a>
				</li>
				<li>
					<a href="javascript:void(0);"><img src="./../../assets/blog/13-img.jpg" alt="image description" /></a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'InstagramPost',
};
</script>
<style scoped></style>
