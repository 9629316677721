<template>
	<div class="vertical-align-wrap">
		<div class="vertical-align-middle auth-main">
			<login-auth-box :loginErrorMsg="loginErrorMsg" @submit-login-form="submit"></login-auth-box>
		</div>
	</div>
	<!-- END WRAPPER -->
</template>
<script>
import LoginAuthBox from './LoginAuthBox';
export default {
	name: 'LoginComponent',
	components: {
		LoginAuthBox,
	},
	data() {
		return {
			loginErrorMsg: '',
		};
	},
	methods: {
		submit(dataFromChild) {
			this.$store
				.dispatch('userSession/loginUser', {
					emailAddress: dataFromChild.emailAddress,
					password: dataFromChild.password,
				})
				.then((response) => {
					if (response.success) {
						this.loginErrorMsg = '';
						this.$router.push({ path: '/dashboard/index' });
						if (dataFromChild.isRememberMe) {
							sessionStorage.clear();
						} else {
							localStorage.removeItem('vuex');
						}
					} else {
						this.loginErrorMsg = response.error;
					}
				});
		},
	},
};
</script>
<style scoped></style>
