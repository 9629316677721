<template>
	<div class="card">
		<div class="body">
			<div class="list-group list-widget">
				<a href="app-inbox.html" class="list-group-item">
					<span class="badge badge-success">654</span>
					<i class="fa fa-envelope text-muted"></i>Inbox</a
				>
				<a href="javascript:void(0);" class="list-group-item">
					<span class="badge badge-info">364</span>
					<i class="fa fa-eye text-muted"></i> Profile visits</a
				>
				<a href="javascript:void(0);" class="list-group-item">
					<span class="badge badge-warning">12</span>
					<i class="fa fa-phone text-muted"></i> Call</a
				>
				<a href="javascript:void(0);" class="list-group-item">
					<span class="badge badge-danger">54</span>
					<i class="fa fa-comments-o text-muted"></i> Messages</a
				>
				<a href="javascript:void(0);" class="list-group-item">
					<span class="badge badge-warning">19</span>
					<i class="fa fa-bookmark text-muted"></i> Bookmarks</a
				>
				<a href="javascript:void(0);" class="list-group-item">
					<span class="badge">56</span>
					<i class="fa fa-bell text-muted"></i> Notifications</a
				>
				<a href="javascript:void(0);" class="list-group-item">
					<span class="badge badge-info">25</span>
					<i class="fa fa-clock-o text-muted"></i> Watch</a
				>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'InboxList',
};
</script>
<style scoped></style>
