<template>
	<div class="add-crop-modal add-b-user-modal">
		<div class="flex-row top-section">
			<h5>Add New Business User</h5>
			<button class="close-modal-btn btn" @click="closeModal()">X</button>
		</div>
		<form class="form-add-crop" @submit.prevent="addBusinessUser">
			<div class="flex-row">
				<div class="d-flex flex-column">
					<label class="add-crop-label">Company</label>
					<input
						class="form-control select-input"
						v-model="newBusinessUser.company"
						type="text"
						@blur="v$.newBusinessUser.company.$touch()"
					/>
					<div v-if="v$.newBusinessUser.company?.$error">
						<p v-if="!v$.newBusinessUser.company.required" class="input-error-msg">Company name is required</p>
					</div>
				</div>

				<div class="d-flex flex-column">
					<label class="add-crop-label">Country</label>

					<select
						class="form-control crop-input-type"
						v-model="selectedCountry"
						ref="selectCountry"
						@change="
							setFilter();
							splitCountryCode();
						"
						@blur="v$.newBusinessUser.country.$touch()"
					>
						<option value=""></option>
						<option v-for="item in countries" :key="item.id">
							{{ item.name }}
							<span class="hidden-codearea"> {{ item.dial_code }}</span>
						</option>
					</select>

					<div v-if="v$.newBusinessUser.country.$error">
						<p v-if="!v$.newBusinessUser.country.required" class="input-error-msg">Country is required</p>
					</div>
				</div>
			</div>
			<div class="flex-row">
				<div class="d-flex flex-column middle-section">
					<label class="add-crop-label">Contact Name</label>
					<input
						class="form-control select-input"
						v-model="newBusinessUser.contactName"
						type="text"
						@blur="v$.newBusinessUser.contactName.$touch()"
					/>
					<div v-if="v$.newBusinessUser.contactName.$error">
						<p v-if="!v$.newBusinessUser.contactName.required" class="input-error-msg">Contact name is required</p>
					</div>
				</div>
				<div class="d-flex flex-column middle-section">
					<label class="add-crop-label">Phone</label>
					<input
						class="form-control select-input"
						v-model="newBusinessUser.phone"
						type="tel"
						@keypress="NumbersOnly($event)"
						minlength="9"
						maxlength="11"
						@blur="v$.newBusinessUser.phone.$touch()"
					/>
					<div v-if="v$.newBusinessUser.phone.$error">
						<p v-if="!v$.newBusinessUser.phone.required" class="input-error-msg">Phone is required</p>
					</div>
				</div>
			</div>
			<div class="">
				<div class="d-flex flex-column middle-section">
					<label class="add-crop-label">Email</label>
					<input
						class="form-control select-input"
						v-model="newBusinessUser.email"
						type="text"
						@blur="v$.newBusinessUser.email.$touch()"
					/>
					<div v-if="v$.newBusinessUser.email.$error">
						<p v-if="!v$.newBusinessUser.email.email" class="input-error-msg">Please enter a valid email</p>
						<p v-if="!v$.newBusinessUser.email.required" class="input-error-msg">Email is required</p>
					</div>
				</div>
			</div>
			<div class="">
				<div class="d-flex flex-column middle-section">
					<label class="add-crop-label">Type</label>
					<select
						v-model="newBusinessUser.type"
						type="text"
						class="form-control crop-input-type"
						@blur="v$.newBusinessUser.type.$touch()"
					>
						<option v-for="type in BusinessUserTypes" :value="type" :key="type.id">
							{{ type }}
						</option>
					</select>
					<div v-if="v$.newBusinessUser.type.$error">
						<p v-if="!v$.newBusinessUser.type.required" class="errorMessage">Type is required</p>
					</div>
				</div>
			</div>

			<div class="next-btn-container">
				<button type="submit" class="next-btn btn">Save</button>
				<p v-if="errMsg" class="input-error-msg">Not all fields are proper</p>
				<p v-if="errServerMsgResponse" class="input-error-msg">
					{{ errServerMessage }}
				</p>
			</div>
		</form>
	</div>
</template>

<script>
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import utilities from '../../../utilities/getCountriesWithAreaCode';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'AddUserBusinessForm',

	data() {
		return {
			errMsg: false,
			errServerMessage: 'There was an error adding the user.',
			errServerMsgResponse: false,
			newBusinessUser: {
				company: '',
				country: '',
				contactName: '',
				phone: '',
				email: '',
				type: '',
				areaCode: '',
			},
			countries: [],
			selectedCountry: '',
			BusinessUserTypes: ['Cooperative', 'Dealer'],
			filterBy: {
				country: '',
			},
		};
	},
	validations: {
		newBusinessUser: {
			company: {
				required,
			},
			country: {
				required,
			},
			contactName: {
				required,
			},
			phone: {
				required,
			},
			email: {
				required,
				email,
			},
			type: {
				required,
			},
			areaCode: {
				required,
			},
		},
	},
	methods: {
		splitCountryCode() {
			this.newBusinessUser.country = this.selectedCountry.split(' ')[0];
			this.newBusinessUser.areaCode = this.selectedCountry.split(' ')[1];
		},
		addBusinessUser() {
			if (!this.v$.$invalid) {
				this.$store.dispatch('businessUser/addBusinessUser', this.newBusinessUser).then((response) => {
					if (response.success) {
						this.$parent.$emit('saveuser', this.newBusinessUser.contactName, 'Business User');
						this.closeModal();
					} else {
						this.errServerMsgResponse = true;
						if (response.error === 'The farmer phone number already have been used') {
							response.error = 'You were added now as a business user';
						}
						this.errServerMessage = response.error;
					}
				});
			} else {
				this.errMsg = true;
				setTimeout(() => {
					this.errMsg = false;
				}, 3000);
			}
		},
		NumbersOnly(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		setFilter() {
			this.$store.commit('businessUser/SET_FILTER', this.filterBy);
		},
		closeModal() {
			this.$parent.$emit('closemodal');
		},
	},
	beforeCreate() {
		utilities.getCountries().then((response) => {
			this.countries = response.data.data;
		});

	},
};
</script>

<style scoped>
.flex-row {
	gap: 20px;
}
.flex-row .flex-column {
	width: calc(50% - 10px);
}

select.form-control {
	border: 1px solid black;
	height: calc(2.25rem + 2px);
}
.hidden-codearea {
	display: none;
}
</style>
