<template>
	<div class="card right-box">
		<div class="header">
			<h2>Categories Clouds</h2>
		</div>
		<div class="body widget">
			<ul class="list-unstyled categories-clouds m-b-0">
				<li class="mr-1"><a href="javascript:void(0);">eCommerce</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Microsoft Technologies</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Creative UX</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Wordpress</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Angular JS</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Enterprise Mobility</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Website Design</a></li>
				<li class="mr-1"><a href="javascript:void(0);">HTML5</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Infographics</a></li>
				<li class="mr-1"><a href="javascript:void(0);">Wordpress Development</a></li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'CategoriesClouds',
};
</script>
<style scoped></style>
