<template>
	<div class="card">
		<div class="header">
			<h2>Social Counter</h2>
		</div>
		<div class="body social_counter">
			<ul class="list-unstyled basic-list">
				<li><i class="fa fa-facebook-square m-r-5"></i> FaceBook <span class="badge badge-primary">16,785</span></li>
				<li><i class="fa fa-twitter-square m-r-5"></i> Twitter <span class="badge-purple badge">2,365</span></li>
				<li><i class="fa fa-linkedin-square m-r-5"></i> Linkedin<span class="badge-success badge">9,021</span></li>
				<li><i class="fa fa-google-plus-square m-r-5"></i> Google Plus<span class="badge-info badge">725</span></li>
				<li><i class="fa fa-behance-square m-r-5"></i> Behance<span class="badge-info badge">1,725</span></li>
				<li><i class="fa fa-dribbble m-r-5"></i> Dribbble<span class="badge-info badge">11,725</span></li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'SocialCounter',
};
</script>
<style scoped></style>
