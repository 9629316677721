<template>
	<div class="modal-mask">
		<div v-if="!accessToken" class="create-token-container">
			<form>
				<div class="flex-row top-section">
					<h5>Select Endpoints</h5>
					<button class="close-modal-btn btn" @click="closeModal()">X</button>
				</div>

				<div>
					<input type="text" placeholder="Description *" v-model="description" @change="handleChange" />
				</div>

				<div class="endpoints-list">
					<div class="endpoints-item" v-for="endpoint in endpoints" :key="endpoint.id">
						<input type="checkbox" v-model="selectedEndpoints" :value="endpoint.name" />
						<label style="margin-bottom: 0">{{ endpoint.name }}</label>
					</div>
				</div>

				<div v-if="error" class="error-msg">{{ error }}</div>

				<button class="btn blue-btn" @click.prevent="generateToken">
					{{ loading ? 'Loading...' : 'Generate Access Token' }}
				</button>
			</form>
		</div>

		<div class="create-token-container" v-else>
			<div class="flex-row top-section">
				<h5>Access Token:</h5>
				<button class="close-modal-btn btn" @click="closeModal()">X</button>
			</div>
			<p>Copy and store it in a secure place.</p>
			<p class="access-token">{{ accessToken }}</p>
			<button class="btn blue-btn" @click="copyToken">Copy Access Token</button>
		</div>
	</div>
</template>

<script>
import accessTokenService from '../../../services/accessTokenService';

export default {
	name: 'ModalCreateToken',
	data() {
		return {
			description: '',
			endpoints: [
				{ id: 1, name: 'MarketingLeads' },
				// Add more endpoints as needed
			],
			selectedEndpoints: [],
			accessToken: null,
			loading: false,
			error: '',
		};
	},
	methods: {
		handleChange(event) {
			this.description = event.target.value;
		},
		closeModal() {
			this.$emit('closemodal');
		},
		copyToken() {
			// Copy the token to the clipboard
			navigator.clipboard.writeText(this.accessToken);
			this.closeModal();
		},
		async generateToken() {
			this.error = '';

			if (!this.selectedEndpoints.length || !this.description) {
				this.error = 'Invalid inputs';
				return;
			}

			const data = {
				endpoints: this.selectedEndpoints,
				description: this.description,
			};

			try {
				this.loading = true;
				const res = await accessTokenService.generateAccessToken(data);
				this.accessToken = res.data.token.token;
				this.$store.dispatch('accessToken/fetchTokens', { router: this.$router });
			} catch (error) {
				this.error = error.message || 'An error occurred. Please try again.';
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped>
.error-msg {
	color: red;
}

.center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	top: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease;
}

.modal-container {
	background: #fff;
	display: flex;
	padding: 20px;
	min-width: 400px;
	border-radius: 10px;
}

.create-token-container {
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	min-width: 400px;
}

.create-token-container form {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.endpoints-list {
	flex: 1;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
}

.endpoints-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.access-token {
	font-weight: bold;
}
</style>
