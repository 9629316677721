<template>
	<div id="main-content">
		<div @click.self="closeEditCropModal" :class="showAroundModal" class="modal-mask"></div>
		<modal-update-crop
			v-if="isEditCropModal"
			:isEditCropModal="isEditCropModal"
			@closemodalmask="closeEditCropModal"
			:chosenCrop="selectedCrop"
			:types="types"
		></modal-update-crop>
		<bread-crumb-table :itemsType="{ type: 'Crops' }"></bread-crumb-table>

		<div class="col-lg-12 crop-table-container">
			<p class="search-crop-title">Search By Name</p>
			<div class="flex-row-no-space">
				<div class="crop-input-icons input-icons">
					<input
						class="search-crop-input"
						@input="setFilter"
						v-model="filterBy.name"
						:disabled="!cropsForDisplay.length && !filterBy.name"
					/>
					<span class="input-group-text"><i class="icon-magnifier"></i></span>
				</div>
			</div>
			<div class="card">
				<div class="body table-responsive">
					<table class="table table-hover">
						<thead class="crop-table-head">
							<tr>
								<th class="crop-table-head">#</th>
								<th class="crop-table-head">Status</th>
								<th class="crop-table-head">Crop Name</th>
								<th class="crop-table-head">
									<a @click="sortType" class="btn sort-table-btn"><i class="fa fa-sort"></i></a>
									Crop Type
								</th>
								<th class="crop-table-head">Crop Image</th>
								<th class="crop-table-head"></th>
							</tr>
						</thead>
						<tbody class="crop-table-body">
							<tr v-for="(crop, index) in cropsForDisplay" :key="crop._id">
								<td>{{ index + 1 }}</td>
								<td>
									<div class="crop-status" :class="getActivityBadgeStatus(crop.status)">
										{{ crop.status }}
									</div>
								</td>
								<td>{{ crop.cropName }}</td>
								<td>{{ crop.cropType }}</td>
								<td>
									<img :src="crop.urlCropImage" alt="crop-image" class="crop-small-img" />
								</td>
								<td>
									<a @click="openEditModal(crop)" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumbTable from '@/components/BreadCrumbTable';
import activityStatus from '../../utilities/activityStatus';
import ModalUpdateCrop from '../admin/modals/ModalUpdateCrop';
import WP from '../../utilities/WP';

export default {
	name: 'CropTable',
	components: {
		ModalUpdateCrop,
		BreadCrumbTable,
	},
	data() {
		return {
			filterBy: {
				name: '',
			},
			selectedCrop: null,
			isAddCropModal: null,
			isEditCropModal: null,
			types: [],
		};
	},
	methods: {
		setFilter() {
			this.$store.commit('crop/SET_FILTER', this.filterBy);
		},

		sortType() {
			return this.cropsForDisplay.sort((a, b) => (a.cropType > b.cropType ? 1 : -1));
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		openEditModal(crop) {
			this.isEditCropModal = true;
			this.selectedCrop = crop;
			console.log(this.selectedCrop);
		},
		closeEditCropModal() {
			this.isEditCropModal = false;
			return 'show-around-modal';
		},
	},
	computed: {
		cropsForDisplay() {
			return this.$store.getters['crop/cropsForDisplay'];
		},
		showAroundModal() {
			return { 'show-around-modal': !this.isEditCropModal };
		},
	},
	async created() {
		this.$store.dispatch('crop/getCrops');
		try {
			const response = await WP.getCropCategoriesTable();
			const types = JSON.parse(response.data.data.post_content);
			types.shift(); // this is for replacing the header from WP
			for (const type of types) {
				this.types.push(type.shift());
			}
		} catch (error) {
			console.log(`cannot get the crop types: ${error}`);
		}
	},
};
</script>

<style scoped>
.show-around-modal {
	position: unset !important;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}
</style>
