<template>
	<div class="card">
		<div class="header">
			<h2>Subscribe</h2>
		</div>
		<div class="body">
			<form>
				<div class="form-group">
					<input type="text" value="" placeholder="Enter Name" class="form-control" />
				</div>
				<div class="form-group">
					<input type="text" value="" placeholder="Enter Email" class="form-control" />
				</div>
				<button class="btn btn-primary">Subscribe</button>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Subscribe',
};
</script>
<style scoped></style>
