<template>
  <div v-if="activities">
    <div class="farmer-notifications-container">
      <h6>Activity Log</h6>
      <div class="farmer-payments-table">
        <!-- <business-user-account-plans
          :props="activities"
          @showaction="showActivity"
        ></business-user-account-plans> -->

        <!-- <b-table
          style="white-space: unset"
          class="table-striped"
          id="my-table"
          :fields="fields"
          :items="activityLogs"
          :per-page="perPage"
          :current-page="currentPage"
          small
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        > -->
        
        <table
          style="white-space: unset"
          class="table-striped"
          id="my-table"
          :items="activities"
          small
        >
        <tr>
          <th>Activity Description</th>
          <th>Date</th>
          </tr>

          <tr  v-for="(item, index) in activities" :key="index" class="table-row" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
            <td>{{ item.activityDescription }}</td>
            <td>{{ getFullDate(item.date) }}</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</template>
<script>
import dates from "../../utilities/dates";
// import { BTable } from "bootstrap-vue";
// import BusinessUserAccountPlans from "./BusinessUserAccountPlans";

export default {
  name: "BusinessUserActivityLog",
  props: {
    activities: Array,
  },
  // components: { BusinessUserAccountPlans },
  data() {
    return {
      activityLogs: [],
    };
  },
  methods: {
    getFullDate(timestamp) {
      return dates.getShortDateFromTimeStamp(timestamp);
    },
    showActivity(activity) {
      console.log(activity);
    },
  },
  created() {

    this.activityLogs = this.activities.map(a => {
      const parseDate = new Date(a.date);
      return {
        activityDescription: a.activityDescription,
        date: parseDate.toLocaleDateString('he-IL', {timeZone:'Asia/Jerusalem'}),
      }
    })
  },
};
</script>

<style scoped>
.b-table-container {
	min-height: 500px;
}

.b-table-container th {
	padding-left: 15px;
}

.form-input {
	margin-bottom: 10px;
	margin-left: 15px;
}

.table td {
	border-top: unset;
	font-size: 12px;
}
tr.table-row.even-row{
  background-color: rgba(0,0,0,.05);;
}
.form-control {
	width: 80%;
	padding: 3px;
}

.btn-outline-secondary {
	padding: 5px 0;
}

.pagination {
	padding-left: 15px;
}

</style>
