<template>
	<nav class="navbar navbar-fixed-top">
		<div class="container-fluid">
			<div class="navbar-btn">
				<button type="button" class="btn-toggle-offcanvas">
					<i class="lnr lnr-menu fa fa-bars" v-on:click="toggleSideMenu()"></i>
				</button>
			</div>

			<div class="navbar-brand">
				<h4>AGRIOT</h4>
				<router-link to="/dashboard/index" v-if="toggleSidebarTheme == 'full-dark'"
					><img src="../../../assets/agriot-logo.png" alt="Lucid Logo" class="img-responsive logo"
				/></router-link>
			</div>

			<div class="navbar-right">
				<!--                <form id="navbar-search" class="navbar-form search-form">-->
				<!--                    <input value="" class="form-control" placeholder="Search here..." type="text">-->
				<!--                    <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>-->
				<!--                </form>                -->

				<div id="navbar-menu">
					<ul class="nav navbar-nav">
						<!-- <li>
							<router-link to="/authentication/page-login" class="icon-menu"><i class="icon-login"></i></router-link>
						</li> -->
					</ul>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'HeaderComponent',
	methods: {
		outside: function () {
			this.dropActive = false;
		},
		notificationOutside: function () {
			this.notificationMenu = false;
		},
		toggleSideMenu: function () {
			document.body.classList.toggle('offcanvas-active');
		},
	},
	data() {
		return {
			dropActive: false,
			notificationMenu: false,
			toggleSidebarClass: false,
			toggleSidebarTheme: '',
		};
	},
	directives: {
		'click-outside': {
			bind: function (el, binding) {
				// Define Handler and cache it on the element
				const bubble = binding.modifiers.bubble;
				const handler = (e) => {
					if (bubble || (!el.contains(e.target) && el !== e.target)) {
						binding.value(e);
					}
				};
				el.__vueClickOutside__ = handler;

				// add Event Listeners
				document.addEventListener('click', handler);
			},
		},
	},
	onMounted() {
		Event.$on('theme', (theme) => {
			this.toggleSidebarTheme = theme;
		});
	},
};
</script>
<style scoped></style>
