import axios from 'axios';
import store from '../store/store';

const envUrl = process.env.VUE_APP_URL;
const envUrlV2 = process.env.VUE_APP_URL_V2;
const BASEURL = `${envUrl}user/`;
const BASEURLV2 = `${envUrlV2}dealer/`;
const APPKEY = 'bxXnw3Wxp5V4GWVei5EgvXdMnKB9llOHIBV4';

const apiClient = axios.create({
	baseURL: BASEURL,
	withCredentials: false, // This is the default
	headers: {
		appkey: APPKEY,
		'Content-Type': 'application/json',
	},
});

export default {
	userLogin(emailAddress, password) {
		return apiClient.post('boUserLogin', {
			email: emailAddress,
			password: password,
		});
	},
	userForgetPassword(emailAddress) {
		let config = {
			method: 'post',
			url: `${BASEURL}forgotPassword`,
			headers: {
				appkey: APPKEY,
				'Content-Type': 'application/json',
			},
			data: emailAddress,
		};

		return axios(config);
	},

	getUsers() {
		console.log('get business users');
		let config = {
			method: 'get',
			url: `${BASEURLV2}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	getUser(userId) {
		console.log(userId);

		let config = {
			method: 'post',
			url: `${BASEURLV2}find`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: {
				id: userId
			}
		};
		return axios(config);
	},
	getBusinessUserDetailsById(userId) {
		let config = {
			method: 'get',
			url: `${BASEURL}getSingleBusinessUserDetails/${userId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	updateBusinessUser(user) {
		let config = {
			method: 'put',
			url: `${BASEURL}updateBusinessUserDetails`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: user,
		};
		return axios(config);
	},
	addBusinessUser(user) {
		let config = {
			method: 'post',
			url: `${BASEURL}createBusinessUser`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: user,
		};
		return axios(config);
	},
	deleteBusinessUser(userId) {
		let config = {
			method: 'delete',
			url: `${BASEURL}deleteBusinessUser/${userId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: '',
		};
		return axios(config);
	},

	// the function is here because it has the same base-url
	deleteCrop(cropId) {
		let config = {
			method: 'post',
			url: `${BASEURL}deleteCrop`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
				'Content-Type': 'application/json',
			},
			data: { cropId: cropId },
		};
		return axios(config);
	},
	updateBusinessUserPlanSize(user) {
		let config = {
			method: 'post',
			url: `${BASEURL}setBusinessPlanSizeForBusinessUser`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: user,
		};
		return axios(config);
	},

	getFarmerCropsById(farmerId) {
		let config = {
			method: 'get',
			url: `${BASEURL}getSingleFarmerCrops/${farmerId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
};
