import dates from './dates';
import { uniq } from 'lodash';

export default {
	getFormattedFarmers(farmers) {
		return farmers.map((farmer) => {
			const formattedPhone = '+' + farmer.areaCode + farmer.phoneNumber;
			const cropTypesStr = uniq(farmer.cropTypes).join(',');
			const cropMeasurementUnitsWithoutNulls = farmer.cropMeasurementUnits.filter((unitStr) => unitStr != null);
			const cropMeasurementUnitsStr =
				farmer.numberOfCrops == 0
					? ''
					: cropMeasurementUnitsWithoutNulls.length == 0
					? 'hectare'
					: uniq(cropMeasurementUnitsWithoutNulls).join(',');
			const businessPlansNamesStr = farmer.businessPlansNames.join(',');
			const isSubscribed = farmer.businessPlansValidUntilDate
				? new Date(farmer.businessPlansValidUntilDate) > new Date()
					? 'Yes'
					: 'No'
				: '';
			const userTypeFormatted = farmer.userType.replace('_', ' ').replace(/\b\w/g, function (char) {
				return char.toUpperCase();
			});
			const lastSampleDateFormatted = farmer.lastSampleDate ? dates.convertToShortDate(farmer.lastSampleDate) : '';
			const marketingAlertsApproved = farmer.marketingAcceptance ? 'Yes' : 'No';
			const businessPlansValidUntilDateFormatted = farmer.businessPlansValidUntilDate
				? dates.convertToShortDate(farmer.businessPlansValidUntilDate)
				: '';
			const lastLoginDateFormatted = farmer.lastLoginDate ? dates.convertToShortDate(farmer.lastLoginDate) : '';
			const isPayer = farmer.numberOfPayingBusinessPlans > 0 ? 'Yes' : 'No';

			return {
				...farmer,
				formattedPhone,
				cropTypesStr,
				cropMeasurementUnitsStr,
				businessPlansNamesStr,
				isSubscribed,
				userTypeFormatted,
				lastSampleDateFormatted,
				marketingAlertsApproved,
				businessPlansValidUntilDateFormatted,
				lastLoginDateFormatted,
				isPayer,
			};
		});
	},
};
