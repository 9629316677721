<template>
	<div class="modal-mask">
		<div class="modal-container-delete position-relative">
			<button @click="closeDeleteModal" class="btn position-absolute close-delete-modal">X</button>
			<h5>Are you sure you want to delete {{ userName }}?</h5>

			<button @click="deleteToken" class="btn blue-btn right-margin">Yes</button>
			<button @click="closeDeleteModal" class="btn blue-btn">No</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModalDeleteToken',
	methods: {
		closeDeleteModal() {
			this.$emit('closedeletemodal');
		},
		deleteToken() {
			this.$emit('deletetoken');
			this.closeDeleteModal();
		},
	},
};
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	top: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease;
}

.close-delete-modal {
	top: 0px;
	right: 0;
	border: none;
	background-color: white;
	font-size: large;
}
h5 {
	margin-top: 15px;
	margin-bottom: 15px;
}
.modal-container-delete {
	background-color: white;
	padding: 30px;
	min-width: 400px;
	text-align: center;
	border-radius: 8px;
	position: fixed !important;
}
.right-margin {
	margin-right: 20px;
}
</style>
