<template>
	<div class="card">
		<div class="header">
			<h2>About Me</h2>
		</div>
		<div class="body text-center">
			<div class="profile-image m-b-15"><img src="./../../assets/user.png" class="rounded-circle" alt="" /></div>
			<div>
				<h4 class="m-b-0"><strong>Alizee</strong> Thomas</h4>
				<span>Washington, d.c.</span>
			</div>
			<div class="m-t-15">
				<button class="btn btn-primary mr-1">Follow</button>
				<button class="btn btn-outline-secondary">Message</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Profile',
};
</script>
<style scoped></style>
