<template>
	<div>
		<div class="error-page-container">
			<h1>404 Error</h1>
			<h4>The resource could not be found.</h4>
			<router-link to="/" exact> Back to login page </router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ErrorPageComponent',
};
</script>
<style>
.error-page-container {
	margin-top: 200px;
	text-align: center;
}
</style>
