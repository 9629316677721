<template>
	<div class="modal-container position-relative">
		<button @click="closeDeleteModal" class="btn position-absolute close-delete-modal">X</button>
		<h5>Are you sure you want to delete {{ userName }}?</h5>

		<button @click="deleteUser" class="btn blue-btn right-margin">Yes</button>
		<button @click="closeDeleteModal" class="btn blue-btn">No</button>
	</div>
</template>

<script>
export default {
	props: {
		userName: String,
	},
	name: 'ModalDeleteUser',
	methods: {
		closeDeleteModal() {
			this.$emit('closedeletemodal');
		},
		deleteUser() {
			this.$emit('deleteuser');
			this.closeDeleteModal();
		},
	},
};
</script>

<style scoped>
.close-delete-modal {
	top: 0px;
	right: 0;
	border: none;
	background-color: white;
	font-size: large;
}
h5 {
	margin-top: 15px;
	margin-bottom: 15px;
}
.modal-container {
	background-color: white;
	padding: 30px;
	min-width: 400px;
	text-align: center;
	border-radius: 8px;
	position: fixed !important;
}
.right-margin {
	margin-right: 20px;
}
</style>
