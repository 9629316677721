<template>
	<div id="main-content">
		<div
			:class="{
				'modal-mask': isUpdateUserModal || isDeleteModalOpen || isModalSuccessOpen,
			}"
			@click.self="closeModal()"
		></div>
		<bread-crumb-table :itemsType="{ type: 'Users' }"></bread-crumb-table>
		<modal-add-user
			@closemodal="closeModal()"
			@updateuser="updateUser"
			v-if="isUpdateUserModal"
			:userToUpdate="user"
		></modal-add-user>
		<modal-success
			v-if="isModalSuccessOpen"
			:userName="userName"
			:userType="userType"
			@closesuccessmodal="closeSuccessModal()"
		></modal-success>
		<modal-delete-user
			v-if="isDeleteModalOpen"
			@deleteuser="deleteUser()"
			:userName="userName"
			@closedeletemodal="closeDeleteModal()"
		></modal-delete-user>
		<div class="col-lg-12 crop-table-container">
			<p class="search-crop-title">Search By name</p>
			<div class="flex-row-no-space">
				<div class="crop-input-icons input-icons first-input">
					<input class="search-farmer-input" @input="setFilter" v-model="filterBy.name" />
					<span class="input-group-text"><i class="icon-magnifier"></i></span>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.active">
						<option disabled value="">Status</option>
						<option value="ACTIVE">Active</option>
						<option value="NOT_ACTIVE">Not Active</option>
					</select>
				</div>
				<button @click="resetFilter()" class="btn reset-filter">Reset Filter</button>
			</div>
			<div class="farmer-search-message" v-if="!isFiltered">
				<h4>Please search / refine by characteristics to see results.</h4>
			</div>
			<div v-if="isFiltered" class="paging-exporting-container">
				<paging
					:filteredFarmers="usersForDisplay"
					@updatevisiblefarmers="updateVisibleUsers"
					:key="componentKey"
				></paging>
			</div>
			<div class="body table-responsive">
				<table class="table table-hover">
					<thead class="crop-table-head">
						<tr>
							<th class="crop-table-head">Status</th>
							<th class="crop-table-head">Name</th>
							<th class="crop-table-head">Email</th>
							<th class="crop-table-head">Role</th>
							<th class="crop-table-head"></th>
						</tr>
					</thead>
					<tbody class="crop-table-body" v-if="isFiltered">
						<tr v-for="user in visibleUsers" :key="user._id">
							<td>
								<div class="crop-status" :class="getActivityBadgeStatus(user.status)">
									{{ user.status }}
								</div>
							</td>
							<td>
								<div class="farmer-name-btn">{{ user.firstName }} {{ user.lastName }}</div>
								<!-- <div class="farmer-date">
                  Created {{ getFormattedDate(user.createdAt) }}
                </div> -->
							</td>
							<td>{{ user.email }}</td>
							<td>{{ user.role }}</td>
							<td class="farmer-cell-actions">
								<a class="btn btn-outline-secondary" @click="openDeleteModal(user)">
									<i class="icon-trash"></i>
								</a>
								<a class="btn btn-outline-secondary pencil-margin" @click="openUpdateUserModal(user)">
									<i class="icon-pencil"></i
								></a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import activityStatus from '../../utilities/activityStatus';
import BreadCrumbTable from '@/components/BreadCrumbTable';
import paging from '@/components/admin/app/paging';
import ModalAddUser from '../admin/modals/ModalAddUser.vue';
import ModalSuccess from '../admin/modals/ModalSuccess.vue';
import ModalDeleteUser from '../admin/modals/ModalDeleteUser.vue';

export default {
	name: 'UsersTable',
	components: {
		BreadCrumbTable,
		paging,
		ModalAddUser,
		ModalSuccess,
		ModalDeleteUser,
	},
	data() {
		return {
			isDeleteModalOpen: false,
			isModalSuccessOpen: false,
			isUpdateUserModal: false,
			componentKey: 0,
			isFiltered: false,
			filterBy: {
				name: '',
				active: 'ACTIVE',
			},
			visibleUsers: null,
			userName: '',
			userType: '',
			user: null,
		};
	},

	methods: {
		loadUsers() {
			this.$store.dispatch('user/getUsers', { router: this.$router });
		},
		setFilter() {
			this.componentKey++;
			this.isFiltered = true;
			this.$store.commit('user/SET_FILTER', this.filterBy);
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		resetFilter() {
			this.isFiltered = false;
			this.filterBy.name = '';
			this.filterBy.active = 'ACTIVE';
		},
		updateVisibleUsers(visibleUsers) {
			this.visibleUsers = visibleUsers;
		},
		openUpdateUserModal(user) {
			this.isUpdateUserModal = true;
			this.user = user;
			this.user.role = user.role;
		},
		closeModal() {
			this.isUpdateUserModal = false;
		},
		closeSuccessModal() {
			this.isModalSuccessOpen = false;
		},
		openDeleteModal(user) {
			this.user = user;
			this.userName = `${user.firstName} ${user.lastName}`;
			this.isDeleteModalOpen = true;
		},
		closeDeleteModal() {
			this.isDeleteModalOpen = false;
		},
		deleteUser() {
			const userId = this.user._id;
			this.$store.dispatch('user/deleteUser', userId);
			this.resetFilter();
		},
		updateUser(userName, userType) {
			this.userName = userName;
			this.userType = userType;
			this.isModalSuccessOpen = true;
			this.resetFilter();
			this.loadUsers();
		},
	},
	computed: {
		usersForDisplay() {
			return this.$store.getters['user/usersForDisplay'];
		},
	},
	watch: {
		usersForDisplay() {
			this.setFilter();
		},
	},
	created() {
		this.loadUsers();
	},
};
</script>

<style>
.farmer-search-message {
	text-align: center;
	margin-top: 50px;
}
.farmer-table-action {
	text-align: right;
}
.farmer-cell-actions {
	text-align: right;
}
.pencil-margin {
	margin-left: 5px;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.paging-exporting-container {
	margin-top: 20px;
}
.card td {
	font-weight: 300;
}
.farmer-date {
	font-size: 12px;
}
.show-around-modal {
	position: unset !important;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}
</style>
