<template>
	<div class="card">
		<div class="header">
			<h2>Twitter Feed</h2>
		</div>
		<div class="body">
			<form>
				<div class="form-group">
					<textarea rows="3" class="form-control no-resize" placeholder="Enter here for tweet..."></textarea>
				</div>
				<button class="btn btn-primary">Tweet</button>
				<a href="javascript:void(0);">13K users active</a>
			</form>
			<hr />
			<ul class="right_chat list-unstyled mb-0">
				<li class="offline">
					<a href="javascript:void(0);">
						<div class="media">
							<img class="media-object" src="../../assets/xs/avatar2.jpg" alt="" />
							<div class="media-body">
								<span class="name">@Isabella <small class="float-right">1 hours ago</small></span>
								<span class="message">Contrary to popular belief not simply text</span>
								<span class="badge badge-outline status"></span>
							</div>
						</div>
					</a>
				</li>
				<li class="online">
					<a href="javascript:void(0);">
						<div class="media">
							<img class="media-object" src="../../assets/xs/avatar3.jpg" alt="" />
							<div class="media-body">
								<span class="name">@Alexander <small class="float-right">2 hours ago</small></span>
								<span class="message">Contrary to popular belief not simply text</span>
								<span class="badge badge-outline status"></span>
							</div>
						</div>
					</a>
				</li>
				<li class="online">
					<a href="javascript:void(0);">
						<div class="media">
							<img class="media-object" src="../../assets/xs/avatar4.jpg" alt="" />
							<div class="media-body">
								<span class="name">@Alexander <small class="float-right">1 day ago</small></span>
								<span class="message">Contrary to popular belief not simply text</span>
								<span class="badge badge-outline status"></span>
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: 'TwitterFeed',
};
</script>
<style scoped></style>
