<template>
	<div class="user-sessions">
		<!-- <div class="tabs">
			<button
				v-for="env in environments"
				:key="env"
				:class="{ active: currentEnv === env }"
				@click="fetchSessions(env)"
			>
				{{ env.toUpperCase() }}
			</button>
		</div> -->
		<div v-if="loading" class="spinner">Loading...</div>
		<table v-else class="full-width">
			<thead>
				<tr>
					<th>Session Date</th>
					<th>Session Title</th>
					<th>Username</th>
					<th>IP Address</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="session in sessions" :key="session.id">
					<td>{{ session.dateCreated }}</td>
					<td>
						<a target="_blank" :href="constructLink(session)">
							{{ session.title }}
						</a>
					</td>
					<td>{{ session.user.username }}</td>
					<td>{{ session.user.ip_address }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import userService from '../../services/userService';

const props = defineProps({
	userId: String,
});

const sessions = ref([]);
// const currentEnv = ref('prod');
// const environments = ['prod', 'staging', 'local'];
const loading = ref(false);

const fetchSessions = async () => {
	loading.value = true;
	try {
		const response = await userService.getSessions(props.userId, process.env.VUE_APP_SENTRY_ENV);
		sessions.value = response.data.data;
	} catch (error) {
		sessions.value = [];
	} finally {
		loading.value = false;
	}
};

// eslint-disable-next-line no-unused-vars
const constructLink = (session) => {
	const startDate = new Date(session.dateCreated);
	startDate.setSeconds(startDate.getSeconds() - 2);
	const start = encodeURIComponent(startDate.toISOString());

	let query = `environment=${process.env.VUE_APP_SENTRY_ENV}&start=${start}&name=All+Events&query=+user.id%3A${props.userId}&sort=-timestamp&utc=true`;

	// get next session date
	const sessionIndex = sessions.value?.findIndex((s) => s.id === session.id);
	const nextSession = sessions.value[sessionIndex - 1];

	const endDate = nextSession ? new Date(nextSession.dateCreated) : new Date();
	endDate.setSeconds(endDate.getSeconds() + 2);
	query = query + `&end=${encodeURIComponent(endDate.toISOString())}`;

	return `https://croptune.sentry.io/discover/homepage/?${query}`;
};

onMounted(() => {
	console.log('env', process.env.VUE_APP_SENTRY_ENV);
	fetchSessions();
});
</script>

<style scoped>
.user-sessions {
	border-radius: 8px;
	border: 1px solid black;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

.tabs button {
	margin-right: 10px;
	padding: 8px 16px;
	background-color: transparent;
	border: none;
	border-bottom: 3px solid transparent;
	cursor: pointer;
}

.tabs button.active {
	border-color: blue;
}

.full-width {
	width: 100%;
	border-collapse: collapse;
}

.full-width th,
.full-width td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #ddd;
}
.spinner {
	text-align: center;
	padding: 20px;
}
</style>
