import accessTokenService from '../../services/accessTokenService';

export const namespaced = true;
export const state = {
	accessTokens: [],
};

export const mutations = {
	SET_TOKEN(state, accessTokens) {
		state.accessTokens = accessTokens;
	},
	ADD_TOKEN(state, token) {
		state.accessTokens.push(token);
	},
	SET_FILTER(state, filterBy) {
		state.filterBy = filterBy;
		console.log(state.filterBy);
	},
	DELETE_TOKEN(state, tokenId) {
		const idx = state.accessTokens.findIndex((c) => c._id === tokenId);
		state.accessTokens.splice(idx, 1);
	},
};

export const getters = {
	getTokens(state) {
		return state.accessTokens;
	},
};

export const actions = {
	fetchTokens({ commit }) {
		return new Promise((resolve) => {
			accessTokenService
				.getAccessTokens()
				.then((response) => {
					commit('SET_TOKEN', response.data.data);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
};
