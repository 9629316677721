import notificationService from '../../services/notificationService';
import farmerService from '../../services/farmerService';
import dates from '../../utilities/dates';

export const namespaced = true;
export const state = {
	notifications: [],
};

export const getters = {
	notificationsForDisplay(state) {
		return state.notifications;
	},
};

export const mutations = {
	SEND_NOTIFICATION(state, notification) {
		notification.notificationType = notification.notificationsType[0];
		delete notification.notificationsType;
		notification.sentDate = dates.getShortDateFromTimeStamp(Date.now());
		state.notifications.push(notification);
	},
	SET_NOTIFICATIONS(state, notifications) {
		state.notifications = notifications;
	},
};

export const actions = {
	sendNotification({ commit }, notification) {
		return new Promise((resolve) => {
			notificationService
				.sendNotification(notification)
				.then((response) => {
					commit('SEND_NOTIFICATION', notification);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},

	getNotifications({ commit }, userId) {
		return new Promise((resolve) => {
			farmerService
				.getNotifications(userId)
				.then((response) => {
					commit('SET_NOTIFICATIONS', response.data.farmerNotifications);
					resolve(response.data.farmerNotifications);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
};
