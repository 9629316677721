<template>
	<div id="main-content">
		<div
			@click.self="closeDeleteModal"
			:class="{
				'modal-mask': isDeleteModalOpen,
			}"
		></div>
		<bread-crumb-table :itemsType="{ type: 'Users' }"></bread-crumb-table>
		<modal-delete-user
			v-if="isDeleteModalOpen"
			:userName="userName"
			@closedeletemodal="closeDeleteModal()"
			@deleteuser="deleteUser()"
		></modal-delete-user>
		<div class="col-lg-12 crop-table-container">
			<p class="search-crop-title">Search By Name Or Phone</p>
			<div class="flex-row-no-space">
				<div class="crop-input-icons input-icons first-input">
					<input
						placeholder="search phone without +"
						class="search-farmer-input"
						@input="setFilter"
						v-model="filterBy.name"
					/>
					<span class="input-group-text"><i class="icon-magnifier"></i></span>
				</div>

				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.country">
						<option value="">Country</option>
						<option v-for="item in countries" :key="item.id">
							{{ item.country }}
						</option>
					</select>
				</div>

				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.type">
						<option disabled value="">Type</option>
						<option selected value="Dealer">Dealer</option>
						<option value="Cooperative">Cooperative</option>
					</select>
				</div>

				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.active">
						<option disabled value="">Status</option>
						<option selected value="ACTIVE">Active</option>
						<option value="NOT_ACTIVE">Not Active</option>
					</select>
				</div>

				<button @click="resetFilter()" class="btn reset-filter">Reset Filter</button>
			</div>

			<div class="farmer-search-message" v-if="!isFiltered">
				<h4>Please search / refine by characteristics to see results.</h4>
			</div>
			<div v-if="isFiltered" class="paging-exporting-container">
				<paging
					:filteredFarmers="usersForDisplay"
					@updatevisiblefarmers="updateVisibleUsers"
					:key="componentKey"
				></paging>
			</div>
			<div class="body table-responsive">
				<table class="table table-hover">
					<thead class="crop-table-head">
						<tr>
							<th class="crop-table-head">Status</th>
							<th class="crop-table-head">Company</th>
							<th class="crop-table-head">Email</th>
							<th class="crop-table-head">Phone</th>
							<th class="crop-table-head">Country</th>
							<th class="crop-table-head">Contact Name</th>
							<th class="crop-table-head">Type</th>
							<th class="crop-table-head">Numbers of Users</th>
							<th class="crop-table-head"></th>
						</tr>
					</thead>
					<tbody class="crop-table-body" v-if="isFiltered">
						<tr v-for="user in visibleUsers" :key="user._id">
							<td>
								<div class="crop-status" :class="getActivityBadgeStatus(user.status)">
									{{ user.status }}
								</div>
							</td>
							<td>{{ user.company }}</td>
							<td>{{ user.email }}</td>
							<td>{{ user.phone }}</td>
							<td>{{ user.country }}</td>
							<td>
								<div class="farmer-name-btn">
									<router-link
										:to="{
											name: 'business-user-details',
											params: { id: user._id },
										}"
									>
										{{ user.contactName }}
									</router-link>
								</div>
								<div class="farmer-date">Created at {{ getFormattedDate(user.creationDate) }}</div>
							</td>
							<td>{{ user.type }}</td>
							<td>---</td>
							<td class="farmer-cell-actions">
								<!-- <a
                  class="btn btn-outline-secondary"
                  @click="openDeleteModal(user)"
                >
                  <i class="icon-trash"></i>
                </a> -->
								<router-link
									v-if="isAdmin"
									class="btn btn-outline-secondary pencil-margin"
									:to="{
										name: 'business-user-details',
										params: { id: user._id },
									}"
								>
									<i class="icon-pencil"></i>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import activityStatus from '../../utilities/activityStatus';
import BreadCrumbTable from '@/components/BreadCrumbTable';
import paging from '@/components/admin/app/paging';
import utilities from '../../utilities/getCountries';
import ModalDeleteUser from '../admin/modals/ModalDeleteUser.vue';
export default {
	name: 'businessUsersTable',
	components: {
		BreadCrumbTable,
		paging,
		ModalDeleteUser,
	},
	data() {
		return {
			isDeleteModalOpen: false,
			componentKey: 0,
			isFiltered: false,
			countries: [],
			filterBy: {
				name: '',
				country: '',
				active: 'ACTIVE',
				type: '',
			},
			visibleUsers: null,
			user: null,
			userName: null,
		};
	},
	methods: {
		loadUsers() {
			this.$store.dispatch('businessUser/getUsers', { router: this.$router });
		},
		setFilter() {
			this.componentKey++;
			this.isFiltered = true;
			this.$store.commit('businessUser/SET_FILTER', this.filterBy);
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		resetFilter() {
			this.isFiltered = false;
			this.filterBy.name = '';
			this.filterBy.country = '';
			this.filterBy.active = '';
			this.filterBy.type = '';
		},
		updateVisibleUsers(visibleUsers) {
			this.visibleUsers = visibleUsers;
		},
		getFormattedDate(date) {
			const dateStr = new Date(date);
			return dateStr.toLocaleDateString('he-IL');
		},
		// openDeleteModal(user) {
		//   this.isDeleteModalOpen = true;
		//   this.user = user;
		//   this.userName = `${user.contactName}`;
		// },
		closeDeleteModal() {
			this.isDeleteModalOpen = false;
		},
		deleteUser() {
			const userId = this.user._id;
			console.log(userId);
			this.$store.dispatch('businessUser/deleteBusinessUser', userId);
		},
	},
	computed: {
		usersForDisplay() {
			return this.$store.getters['businessUser/usersForDisplay'];
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	watch: {
		usersForDisplay() {
			this.setFilter();
		},
	},
	created() {
		this.loadUsers();

		utilities.getCountries().then((response) => {
			this.countries = response.data.data;
		});
	},
};
</script>

<style>
.farmer-search-message {
	text-align: center;
	margin-top: 50px;
}
.farmer-table-action {
	text-align: right;
}
.farmer-cell-actions {
	text-align: right;
}
.pencil-margin {
	margin-left: 5px;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.paging-exporting-container {
	margin-top: 20px;
}
.card td {
	font-weight: 300;
}
.farmer-date {
	font-size: 12px;
}
.show-around-modal {
	position: unset !important;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}
</style>
