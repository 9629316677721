import axios from 'axios';

const BASEURL = process.env.VUE_APP_WP_URL;

export default {
	getCropCategoriesTable() {
		let config = {
			method: 'get',
			url: `${BASEURL}wp-json/app/GetTable?table_id=11`,
		};
		return axios(config);
	},
	getSizeRangeTable() {
		let config = {
			method: 'get',
			url: `${BASEURL}wp-json/app/GetTable?table_id=6`,
		};
		return axios(config);
	},
	getWordPressPlansAndPricesTable() {
		var config = {
			method: 'get',
			url: `${BASEURL}wp-json/app/GetTable?table_id=10`,
		};
		return axios(config);
	},
	getCropTypesTable() {
		let config = {
			method: 'get',
			url: `${BASEURL}wp-json/app/GetTable?table_id=8`,
		};
		return axios(config);
	},
};
