const ActivityStatusEnum = Object.freeze({
	ACTIVE: 'ACTIVE',
	PENDING: 'PENDING',
	NOT_ACTIVE: 'NOT ACTIVE',
	PAYMENT: 'Payment',
	REFUND: 'Refund',
	DECLINED: 'Declined',
});

export default ActivityStatusEnum;
