import { orderBy } from 'lodash';
import farmerService from '../../services/farmerService';
import userService from '../../services/userService';
import util from '../../utilities/util';
import farmerUtils from '../../utilities/getFormattedFarmers';

export const namespaced = true;
export const state = {
	farmers: [],
	payments: [],
	filterBy: {
		sortBy: '',
		name: '',
		country: '',
		size: '',
		active: '',
		cropType: '',
		marketingAlerts: '',
	},
};

export const getters = {
	farmersForDisplay(state) {
		const sortByFieldName = state.filterBy.sortBy;
		const sortAscending = state.filterBy?.sortAscending;
		const checkStatus = state.filterBy.active;
		const checkTxt = state.filterBy.name.toLowerCase();
		const checkMinSize = Number(state.filterBy.size.substring(0, state.filterBy.size.indexOf('-')));
		const checkCountry = state.filterBy.country.toLowerCase();
		const checkMaxSize = Number(state.filterBy.size.substring(state.filterBy.size.indexOf('-') + 1));
		const checkCropType = state.filterBy.cropType == undefined ? '' : state.filterBy.cropType.toLowerCase();
		const checkMarketingAlerts = state.filterBy.marketingAlerts;

		let result = [...state.farmers];

		if (checkStatus !== '') {
			result = result.filter((farmer) => farmer.status === checkStatus);
		}

		if (checkTxt !== '') {
			result = result.filter((farmer) => {
				const phoneNumber = farmer.areaCode + farmer.phoneNumber;
				const fullName = `${farmer.firstName} ${farmer.lastName}`;
				return util.checkByNameOrPhone(checkTxt, fullName, phoneNumber);
			});
		}

		if (checkCountry !== '') {
			result = result.filter((farmer) => {
				return farmer.country.toLowerCase().trim() === checkCountry;
			});
		}

		if (state.filterBy.size !== '') {
			result = result.filter((farmer) => {
				const size = (farmer.totalBusinessPlansSize * 100) / 120;
				return size >= checkMinSize && size <= checkMaxSize;
			});
		}

		if (state.filterBy.cropType !== '') {
			result = result.filter((farmer) => {
				return farmer.cropTypesStr.includes(checkCropType);
			});
		}

		if (state.filterBy.marketingAlerts !== '') {
			result = result.filter((farmer) => {
				return farmer.marketingAlertsApproved == checkMarketingAlerts;
			});
		}

		if (sortByFieldName !== '') {
			result = orderBy(result, [sortByFieldName], sortAscending ? 'asc' : 'desc');
		}

		return result;
	},

	paymentsForDisplay() {
		return state.payments;
	},
};

export const mutations = {
	SET_FARMERS(state, farmers) {
		state.farmers = farmers;
		// console.log('SET_FARMERS at farmer.js')
		// console.log(state.farmers)
	},
	SET_PAYMENTS(state, payments) {
		state.payments = payments;
	},
	SET_FILTER(state, filterBy) {
		state.filterBy = filterBy;
	},
	UPDATE_FARMER(state, farmer) {
		const idx = state.farmers.findIndex((f) => f._id === farmer._id);
		state.farmers.splice(idx, 1, farmer);
	},
	DELETE_FARMER(state, farmerId) {
		const idx = state.farmers.findIndex((farmer) => farmer._id === farmerId);
		state.farmers.splice(idx, 1);
	},
};

export const actions = {
	async getFarmers({ commit }, payload) {
		let router = payload.router;
		try {
			const response = await farmerService.getFarmers();
			// console.log(response.data.data)
			const formattedFarmers = farmerUtils.getFormattedFarmers(response.data.data);
			// console.log(formattedFarmers)
			commit('SET_FARMERS', formattedFarmers);
		} catch (error) {
			console.log(`There was an error: ${error}`);
			router.push({ path: '/authentication/page-404' });
		}
	},
	async updateFarmer({ commit }, farmer) {
		try {
			const response = await farmerService.updateFarmer(farmer);
			commit('UPDATE_FARMER', response);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
	async deleteFarmer({ commit }, farmerId) {
		try {
			const response = await farmerService.deleteFarmer(farmerId);
			console.log(response);
			commit('DELETE_FARMER', farmerId);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
	async getFarmerPayments({ commit }, farmerId) {
		try {
			const response = await userService.getFarmerPayments(farmerId);
			commit('SET_PAYMENTS', response.data.data.payments);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
};
