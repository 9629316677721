<template>
	<div class="modal-success-container">
		<div class="modal-container position-relative">
			<button @click="closeSuccessModal" class="btn position-absolute close-success-modal">X</button>
			<h5 v-if="!isMessageShown">
				{{ upperTextStartLine }} <b> {{ userName }} </b> {{ upperTextEndLine }}
			</h5>
			<p v-if="!isMessageShown">{{ bottomText }}</p>
			<div v-if="isMessageShown">
				<p>The notification is:</p>

				<h5>{{ title }}</h5>
				<p>{{ message }}</p>
			</div>
			<button @click="closeSuccessModal" class="btn blue-btn">Continue</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		userName: String,
		upperTextStartLine: String,
		upperTextEndLine: String,
		bottomText: String,
		message: String,
		title: String,
		isMessageShown: Boolean,
	},
	name: 'ModalSuccessDynamic',
	methods: {
		closeSuccessModal() {
			this.$emit('closesuccessmodal');
		},
	},
};
</script>

<style scoped>
.close-success-modal {
	top: 0px;
	right: 0;
	border: none;
	background-color: white;
	font-size: large;
}
h5 {
	margin-top: 5px;
}
.modal-container {
	background-color: white;
	padding: 30px;
	min-width: 400px;
	text-align: center;
	border-radius: 8px;
	position: fixed !important;
}
.right-margin {
	margin-right: 20px;
}
</style>
