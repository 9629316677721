<template>
	<div class="row modal-container" v-if="isModalOpen">
		<div class="add-crop-modal">
			<div class="flex-row">
				<h6>Edit Crop</h6>
				<button class="close-modal-btn btn" @click="closeEditCropModal()">X</button>
			</div>
			<form class="form-add-crop" @submit.prevent="updateCrop">
				<div class="d-flex flex-column">
					<label class="add-crop-label">Crop Name </label>
					<input
						class="form-control select-input"
						v-model="selectedCrop.cropName"
						type="text"
						placeholder="Type name here"
						@blur="v$.selectedCrop.cropName.$touch()"
					/>
					<div v-if="v$.selectedCrop.cropName.$error">
						<p v-if="!v$.selectedCrop.cropName.required" class="errorMessage">Crop name is required</p>
					</div>
				</div>
				<div class="top-form-section">
					<div class="d-flex flex-column">
						<label class="add-crop-label">Crop Type</label>
						<select
							v-model="selectedCrop.cropType"
							type="text"
							class="form-control input-style crop-input-type"
							@blur="v$.selectedCrop.cropType.$touch()"
						>
							<option v-for="type in types" :value="type" :key="type.id">{{ type }}</option>
						</select>
						<div v-if="v$.selectedCrop.cropType.$error">
							<p v-if="!v$.selectedCrop.cropType.required" class="errorMessage">Crop type is required</p>
						</div>
					</div>
					<div class="d-flex flex-column">
						<label class="add-crop-label">Language Key</label>
						<input
							class="form-control input-style"
							v-model="selectedCrop.languageKey"
							type="text"
							placeholder="Type here"
							@blur="v$.selectedCrop.languageKey.$touch()"
						/>
						<div v-if="v$.selectedCrop.languageKey.$error">
							<p v-if="!v$.selectedCrop.languageKey.required" class="errorMessage">Crop language key is required</p>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column">
					<label class="add-crop-label">Crop Image </label>
					<div class="position-relative fake-input">
						<input
							class="form-control add-crop-input-image"
							@change="onFileSelected"
							type="file"
							placeholder=""
							ref="fileInput"
							@blur="v$.selectedCrop.urlCropImage.$touch()"
						/>
						<i class="fa fa-file-picture-o position-absolute btn" @click="$refs.fileInput.click()"></i>
					</div>
					<div v-if="v$.selectedCrop.urlCropImage.$error">
						<p v-if="!v$.selectedCrop.urlCropImage.required" class="errorMessage">Crop image is required</p>
					</div>
				</div>
				<div v-if="selectedCrop.urlCropImage">Image added successfully!</div>

				<div class="d-flex flex-column">
					<label class="add-crop-label">Status</label>
					<select
						v-model="selectedCrop.status"
						type="text"
						class="form-control crop-input-status"
						@blur="v$.selectedCrop.status.$touch()"
						:class="[selectedCrop.status === 'ACTIVE' ? 'badge-success' : 'badge-danger']"
					>
						<option value="ACTIVE">ACTIVE</option>
						<option value="NOT ACTIVE">NOT ACTIVE</option>
					</select>
					<div v-if="v$.selectedCrop.status.$error">
						<p v-if="!v$.selectedCrop.status.required" class="errorMessage">Crop Status is required</p>
					</div>
				</div>
				<div class="next-btn-container">
					<button type="submit" class="next-btn btn">Update</button>
					<p v-if="errMsg" class="errorMessage">All fields are required</p>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'ModalUpdateCrop',
	props: {
		isEditCropModal: Boolean,
		chosenCrop: Object,
		types: Array,
	},
	data() {
		return {
			isModalOpen: true,
			isCropSaved: false,
			selectedCrop: {
				cropId: this.chosenCrop._id,
				cropName: this.chosenCrop.cropName,
				cropType: this.chosenCrop.cropType,
				languageKey: this.chosenCrop.languageKey,
				urlCropImage: this.chosenCrop.urlCropImage,
				status: this.chosenCrop.status,
			},
			errMsg: false,
		};
	},
	validations: {
		selectedCrop: {
			cropName: {
				required,
			},
			cropType: {
				required,
			},
			languageKey: {
				required,
			},
			urlCropImage: {
				required,
			},
			status: {
				required,
			},
		},
	},
	methods: {
		updateCrop() {
			console.log(this.selectedCrop);
			let formData = new FormData();
			formData.append('cropId', this.selectedCrop.cropId);
			formData.append('file', this.selectedCrop.urlCropImage);
			formData.append('cropName', this.selectedCrop.cropName);
			formData.append('cropType', this.selectedCrop.cropType);
			formData.append('languageKey', this.selectedCrop.languageKey);
			formData.append('status', this.selectedCrop.status);
			if (!this.v$.$invalid) {
				this.$store.dispatch('crop/updateCrop', formData);
				this.closeEditCropModal();
			} else {
				this.errMsg = true;
				setTimeout(() => {
					this.errMsg = false;
				}, 3000);
				console.log('error');
			}
		},
		closeEditCropModal() {
			this.isModalOpen = false;
			this.$emit('closemodalmask');
		},
		onFileSelected(event) {
			this.selectedCrop.urlCropImage = event.target.files[0];
			console.log(this.selectedCrop.urlCropImage);
		},
		created() {
			console.log(this.types);
		},
	},
};
</script>

<style scoped>
.crop-input-status {
	width: 130px;
	padding: 0px;
	border: 1.5px solid;
}
</style>
