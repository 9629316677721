<template>
	<div class="row modal-container">
		<div class="add-crop-modal lift-modal">
			<div class="flex-row">
				<h6>Send <span v-if="markedFarmers.length > 1">Group</span> Notification</h6>
				<button class="close-modal-btn btn" @click="closeGroupNotificationModal()">X</button>
			</div>
			<div class="headline">
				All <b>{{ markedFarmers.length }}</b> selected members will recieve this notification.
			</div>
			<div>Choose channel</div>
			<div class="flex-no-space">
				<div class="notification-panel" v-for="(type, index) in NotificationTypes" :key="type.id">
					<div
						class="btn notification-type"
						@click="chooseNotificationType(type, index)"
						:class="{ 'blue-btn': chosenTypes(type) }"
					>
						{{ type }}
					</div>
				</div>
			</div>
			<form class="form-add-crop" @submit.prevent="sendNotification">
				<div class="top-form-section"></div>
				<div class="d-flex flex-column">
					<label class="add-crop-label">Title</label>
					<input
						class="form-control select-input"
						v-model="notification.title"
						type="text"
						@blur="v$.notification.title.$touch()"
					/>
					<div v-if="v$.notification.title.$error">
						<p v-if="!v$.notification.title.required" class="errorMessage">Title is required</p>
					</div>
				</div>
				<div class="d-flex flex-column">
					<textarea
						class="notification-area"
						type="text"
						v-model="notification.message"
						placeholder="Type your notification here"
						@blur="v$.notification.message.$touch()"
					></textarea>
					<div v-if="v$.notification.message.$error">
						<p v-if="!v$.notification.message.required" class="errorMessage">Message is required</p>
					</div>
				</div>

				<div class="next-btn-container">
					<button class="cancel-btn btn" @click="closeGroupNotificationModal()" type="button" value="Cancel">
						Cancel
					</button>
					<button type="submit" class="next-btn btn">Send</button>
					<p v-if="errMsg" class="errorMessage">All fields are required</p>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
	name: 'ModalSendNotification',
	props: {
		userName: String,
		markedFarmers: Array,
	},
	data() {
		return {
			notification: {
				users: [],
				notificationsType: [],
				title: '',
				message: '',
			},
			NotificationTypes: ['App Notification', 'Sms', 'Email'],
			chosenIndex: null,
			chosenType: null,
			errMsg: false,
		};
	},
	validations: {
		notification: {
			title: {
				required,
			},
			message: {
				required,
			},
		},
	},
	methods: {
		closeGroupNotificationModal() {
			this.$emit('closegroupnotificationmodal');
		},
		sendNotification() {
			if (!this.v$.$invalid && this.notification.notificationsType.length) {
				const farmersId = this.markedFarmers.map((farmer) => {
					return farmer._id;
				});
				this.notification.users = farmersId;
				console.log(this.notification);
				this.$store.dispatch('notification/sendNotification', this.notification);
				this.closeGroupNotificationModal();
				this.$emit('opensuccessmodal');
			} else {
				this.errMsg = true;
				setTimeout(() => {
					this.errMsg = false;
				}, 3000);
			}
		},
		chooseNotificationType(type, index) {
			this.chosenIndex = index;
			if (this.notification.notificationsType.length) {
				if (!this.notification.notificationsType.includes(type)) {
					this.notification.notificationsType.push(type);
				} else {
					this.notification.notificationsType.splice(this.notification.notificationsType.indexOf(type), 1);
				}
			} else {
				this.notification.notificationsType.push(type);
			}
		},
		chosenTypes(type) {
			return this.notification.notificationsType.includes(type);
		},
	},
};
</script>

<style scoped>
.notification-area {
	min-width: 400px;
	border: 1px solid black;
	border-radius: 5px;
	padding-bottom: 50px;
	margin-top: 20px;
	color: #495057;
}
.cancel-btn,
.next-btn {
	width: 35%;
}
.cancel-btn {
	color: rgb(110, 110, 187);
	background-color: white;
	font-weight: 600;
}
.notification-type {
	border-radius: 5px;
	border: 1px solid black;
	margin-right: 10px;
}
.headline {
	font-size: 18px;
	margin-bottom: 25px;
}
</style>
