<template>
	<div class="modal-mask">
		<div class="details-container">
			<button class="close-button" @click="closeModal">×</button>
			<h5>{{ analysis.cropId.cropName }} Analysis</h5>
			<p><strong>Crop Type:</strong> {{ analysis.cropId.cropType }}</p>
			<p><strong>Analysis Date:</strong> {{ formatDate(analysis.createdAt || analysis.sampleDate) }}</p>

			<div class="images-section" v-if="!!analysis.labImageUrls?.length">
				<h6>Uploaded Images</h6>
				<div class="images-container">
					<a target="_blank" :href="image" v-for="(image, index) in analysis.labImageUrls" :key="index">
						<img :src="image" alt="Crop Image" />
					</a>
				</div>
			</div>

			<h6>Analysis Results</h6>
			<div class="analysis-section">
				<ul>
					<li v-if="analysis.N_Average"><strong>N Average:</strong> {{ analysis.N_Average.toFixed(2) }}%</li>
					<li v-if="analysis.N">
						<strong>N %:</strong>
						<span>{{ analysis.N.Minimum }}% - {{ analysis.N.Maximum }}%</span>
					</li>
					<li v-if="analysis.N_Uptake">
						<strong>N Uptake:</strong>
						<span>{{ analysis.N_Uptake.Minimum }} - {{ analysis.N_Uptake.Maximum }}</span>
					</li>
					<li v-if="analysis.N_Uptake_Average">
						<strong>N Uptake Average:</strong> {{ analysis.N_Uptake_Average.toFixed(2) }}%
					</li>
					<li v-if="analysis.Delta_N">
						<strong>Delta N</strong>
						{{ analysis.Delta_N }}
					</li>
					<li v-if="analysis.Percent_Above_N">
						<strong>Percent Above N</strong>
						{{ analysis.Percent_Above_N }}
					</li>
				</ul>
				<ul>
					<li v-if="analysis.Add_N_Units">
						<strong>Add N Units</strong>
						<span>{{ analysis.Add_N_Units.Minimum }} - {{ analysis.Add_N_Units.Maximum }}</span>
					</li>
					<li v-if="analysis.Add_P_Units">
						<strong>Add P Units</strong>
						<span>{{ analysis.Add_P_Units.Minimum }} - {{ analysis.Add_P_Units.Maximum }}</span>
					</li>
					<li v-if="analysis.Add_K_Units">
						<strong>Add K Units</strong>
						<span>{{ analysis.Add_K_Units.Minimum }} - {{ analysis.Add_K_Units.Maximum }}</span>
					</li>
					<li v-if="analysis.Fertilizer">
						<strong>Fertilizer</strong>
						<span>{{ analysis.Fertilizer.Minimum }} - {{ analysis.Fertilizer.Maximum }}</span>
					</li>
					<li v-if="analysis.Dap">
						<strong>Dap</strong>
						<span>{{ analysis.Dap.Minimum }} - {{ analysis.Dap.Maximum }}</span>
					</li>
					<li v-if="analysis.Mop">
						<strong>Mop</strong>
						<span>{{ analysis.Mop.Minimum }} - {{ analysis.Mop.Maximum }}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FarmerAnalysisTable',
	props: {
		analysis: {
			type: Object,
			required: true,
		},
	},
	methods: {
		closeModal() {
			this.$emit('closemodal');
		},
		formatDate(date) {
			if (!date) return '';
			const options = { year: 'numeric', month: 'long', day: 'numeric' };
			return new Date(date).toLocaleDateString(undefined, options);
		},
	},
};
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease;
}

.details-container {
	background: #fff;
	padding: 20px;
	max-width: 600px;
	width: 100%;
	border-radius: 10px;
	position: relative;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
}

h5 {
	margin-bottom: 16px;
	font-size: 1.25rem;
}

.images-section {
	margin: 20px 0;
}

.images-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.images-container img {
	max-width: 100px;
	border-radius: 5px;
}

.analysis-section {
	display: flex;
	justify-content: space-between;
}

.analysis-section ul {
	flex: 1;
	padding-right: 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.analysis-section ul li {
	display: flex;
	gap: 10px;
}
</style>
