<template>
	<div class="modal-delete-container">
		<div class="delete-crop-modal-container position-relative">
			<button @click="closeDeleteCropModal" class="btn position-absolute close-delete-modal">X</button>
			<h5>Are you sure you want to delete this crop?</h5>
			<button @click="deleteCrop(cropId)" class="btn blue-btn">Yes</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModalDeleteCrop',
	props: {
		cropId: String,
	},
	methods: {
		closeDeleteCropModal() {
			this.$emit('closedeletecropmodal');
		},
		deleteCrop(cropId) {
			this.$emit('deletecrop', cropId);
		},
	},
};
</script>

<style scoped>
.close-delete-modal {
	top: 0px;
	right: 0;
	border: none;

	background-color: white;
	font-size: large;
}

.delete-crop-modal-container {
	z-index: 9999;
	border: 1px solid black;
	background-color: white;
	padding: 30px;
	min-width: 500px;
	text-align: center;
	border-radius: 8px;
}
.delete-crop-modal-container h5 {
	margin-bottom: 25px;
}
</style>
