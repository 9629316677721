<template>
	<div id="main-content" v-if="selectedBU">
		<bread-crumb-table :itemsType="{ type: 'Users' }" :farmerName="selectedBU.contactName"></bread-crumb-table>

		<div class="col-lg-12 crop-table-container">
			<div class="col-lg-4 col-md-6">
				<div class="farmer-details-profile border-padding">
					<div class="flex-row-no-space farmer-details-profile-tabs">
						<div :class="{ 'active-tab': activeIndex === 0 }" class="farmer-details-profile-tab btn">
							<a @click="farmerProfileTab()">Profile</a>
						</div>
						<div v-if="isAdmin" :class="{ 'active-tab': activeIndex === 1 }" class="farmer-details-profile-update btn">
							<a @click="farmerUpdateTab()">Edit</a>
						</div>
					</div>
					<business-user-profile-details
						v-if="activeIndex === 0"
						:selectedBU="selectedBU"
					></business-user-profile-details>

					<business-user-update-profile
						v-if="activeIndex === 1"
						:selectedBU="selectedBU"
						@cancelupdate="farmerProfileTab()"
						@backtoprofile="farmerProfileTab"
						@buserupdatedetails="BUserUpdateDetails"
					></business-user-update-profile>
				</div>
				<!-- <business-user-coupons></business-user-coupons> -->
			</div>

			<div class="col-lg-5 col-md-12">
				<!-- <crop-details :selectedBU="selectedBU"></crop-details> -->
				<!-- <farmer-payments></farmer-payments> -->
				<business-user-account-plans :id="id" :plans="BusinessUserAccountPlans"></business-user-account-plans>
			</div>

			<div class="col-lg-3 col-md-12">
				<!-- <farmer-notifications></farmer-notifications> -->

				<business-user-activity-log v-if="activityLogs" :activities="activityLogs"></business-user-activity-log>
			</div>
		</div>
		<div class="col-lg-12">
			<business-user-farmers :id="id"></business-user-farmers>
		</div>
	</div>
</template>

<script>
import BreadCrumbTable from '@/components/BreadCrumbTable';
import businessUsersService from '../../services/businessUsersService';
import BusinessUserProfileDetails from './BusinessUserProfileDetails.vue';
// import BusinessUserCoupons from "./BusinessUserCoupons";
// import CropDetails from "../crops/CropDetails";
// import FarmerPayments from "./FarmerPayments";
// import FarmerNotifications from "./FarmerNotifications";
import BusinessUserActivityLog from './BusinessUserActivityLog';
import BusinessUserUpdateProfile from './BusinessUserUpdateProfile';
import BusinessUserAccountPlans from './BusinessUserAccountPlans.vue';
import BusinessUserFarmers from './BusinessUserFarmers.vue';

export default {
	name: 'BusinessUserProfile',
	props: {
		id: String,
	},
	components: {
		BusinessUserAccountPlans,
		BusinessUserActivityLog,
		// FarmerNotifications,
		// FarmerPayments,
		// CropDetails,
		// BusinessUserCoupons,
		BreadCrumbTable,
		BusinessUserProfileDetails,
		BusinessUserUpdateProfile,
		BusinessUserFarmers,
	},
	data() {
		return {
			selectedBU: {},
			BusinessUserAccountPlans: {
				fieldCrops: '',
				orchardCrops: '',
				greenhouseCrops: '',
			},
			activityLogs: null,
			activeIndex: 0,
		};
	},
	computed: {
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	methods: {
		farmerProfileTab() {
			this.activeIndex = 0;
		},
		farmerUpdateTab() {
			this.activeIndex = 1;
		},
		BUserUpdateDetails(user) {
			console.log(user);
			this.selectedBU = user;
		},
	},

	created() {
		businessUsersService.getBusinessUserDetailsById(this.id).then((response) => {
			const fetchedData = response.data.data;

			this.selectedBU = fetchedData.businessUser;
			this.BusinessUserAccountPlans.fieldCrops = fetchedData.businessUser.fieldCrops;
			this.BusinessUserAccountPlans.orchardCrops = fetchedData.businessUser.orchardCrops;
			this.BusinessUserAccountPlans.greenhouseCrops = fetchedData.businessUser.greenhouseCrops;
			this.activityLogs = fetchedData.activityLog;

			// this.selectedBU = {... this.selectedBU, ...response.data.farmerBusinessPlan}
		});

		console.log('the BU', this.selectedBU);
	},
};
</script>

<style scoped>
.crop-table-container {
	display: flex;
}
.farmer-details-profile-tabs {
	margin-bottom: 20px;
}
.farmer-details-profile {
	margin-bottom: 20px;
	min-height: 400px;
}
.active-tab {
	font-weight: bold;
	border-bottom: 3px solid black;
	border-radius: unset;
}
</style>
