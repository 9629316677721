<template>
	<div class="farmer-payments-container">
		<h6>Payments Transactions</h6>
		<div class="farmer-payments-table">
			<table class="table-striped">
				<thead>
					<tr>
						<th>Date</th>
						<th>Amount</th>
						<th>Confirmation ID</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="payment in displayPayments" :key="payment._id">
						<td>{{ getFullDate(payment.transactionDate) }}</td>
						<td>{{ payment.totalPrice }}$</td>
						<td>{{ payment.transactionID }}</td>
						<td>
							<div class="crop-status" :class="getActivityBadgeStatus(payment.status)">
								{{ payment.status }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import dates from '../../utilities/dates';
import activityStatus from '../../utilities/activityStatus';
import { mapState } from 'vuex';

export default {
	name: 'FarmerPayments',
	props: {
		farmerId: String,
	},
	data() {
		return {
			displayPayments: [],
		};
	},
	methods: {
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		getFullDate(timestamp) {
			return dates.getShortDateFromTimeStamp(timestamp);
		},
		loadPayments() {
			this.$store.dispatch('farmer/getFarmerPayments', this.farmerId);
		},
	},
	computed: {
		...mapState('farmer', ['payments']),
	},
	watch: {
		payments: {
			immediate: true,
			handler(newPayments) {
				this.displayPayments = newPayments;
			},
		},
	},
	created() {
		this.loadPayments();
	},
};
</script>

<style scoped>
.farmer-payments-container {
	border-radius: 8px;
	border: 1px solid black;
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.farmer-payments-container h6 {
	padding: 15px;
}
.table-striped {
	table-layout: fixed;
	width: 100%;
	font-size: 13px;
}
.farmer-payments-table th {
	color: #9a9a9a;
	padding: 10px;
}
.farmer-payments-table td {
	padding: 10px;
	word-wrap: break-word;
	font-size: 12px;
}
</style>
