<template>
	<div class="overflow-auto table-container" v-if="items">
		<div class="farmer-details-profile with-border">
			<div class="flex-row-no-space farmer-details-profile-tabs">
				<div :class="{ 'active-tab': activeIndex === 0 }" class="farmer-details-profile-tab btn">
					<a @click="currentPlansTab()">Package Plans</a>
				</div>
				<div v-if="isAdmin" :class="{ 'active-tab': activeIndex === 1 }" class="farmer-details-profile-update btn">
					<a @click="plansUpdateTab()">Edit</a>
				</div>
			</div>

			<table v-if="activeIndex === 0" style="white-space: unset" class="table-striped" :items="items" small>
				<th>Crop Type</th>
				<th>Total Number Of Plans</th>
				<tr
					v-for="(item, index) in items"
					:key="index"
					class="table-row"
					:class="{ 'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0 }"
				>
					<td style="white-space: normal">{{ item.cropType }}</td>
					<td style="white-space: normal">{{ item.amount }}</td>
				</tr>
			</table>

			<div class="edit-table-wrap" v-if="activeIndex === 1">
				<table class="table table-striped table-sm" id="adding-plans-table" style="white-space: unset">
					<thead role="rowgroup">
						<tr role="row">
							<th role="columnheader" scope="col" aria-colindex="1">
								<div>Crop Type</div>
							</th>
							<th role="columnheader" scope="col" aria-colindex="2">
								<div>Hacta</div>
							</th>
							<th role="columnheader" scope="col" aria-colindex="3">
								<div>Quantity</div>
							</th>
							<th role="columnheader" scope="col" aria-colindex="3">
								<div></div>
							</th>
						</tr>
					</thead>

					<tbody role="rowgroup">
						<tr role="row" v-for="(item, i) in editPanelItemsAdded" :key="i">
							<td>{{ item.cropType }}</td>
							<td>{{ item.hacta }}</td>
							<td>{{ item.quantity }}</td>
							<td></td>
						</tr>

						<tr role="row" v-if="addNewRow">
							<td>
								<select v-model="selectedPlan" @change="pickAmount">
									<option value="" selected>Choose Plan</option>
									<option v-for="plan in editPanelItems" :key="plan.cropType" :value="plan.cropType">
										{{ plan.cropType }}
									</option>
								</select>
							</td>
							<td>
								<input type="number" disabled v-model.number="newPackege.amount" />
							</td>
							<td>
								<input type="number" v-model.number="newPackege.quantity" />
							</td>
							<td>
								<button class="btn btn-primary" @click="sendNewPack">Update</button>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="add-row">
					<a @click="addNewPack" style="color: #49c5b6; cursor: pointer">+ Add Package</a>
				</div>
			</div>
		</div>

		<!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination> -->
	</div>
</template>

<script>
// import { BPagination } from "bootstrap-vue";
// import { BTable } from "bootstrap-vue";
// import dates from "../../utilities/dates";
import businessUsersService from '../../services/businessUsersService';
import WP from '../../utilities/WP';
export default {
	name: 'BusinessUserAccountPlans',
	props: {
		id: String,
		plans: Object,
	},
	components: {
		// BPagination,
		// BTable,
	},
	data() {
		return {
			activeIndex: 0,
			items: [],
			editPlansFields: ['cropType', 'hacta', 'quantity', ''],
			editPanelItems: [],
			editPanelItemsAdded: [],
			addNewRow: false,
			newPackege: {
				cropType: [],
				amount: '',
				quantity: '',
			},
			newPackegeToSend: {
				businessUserId: this.id,
				fieldCrops: 0,
				orchardCrops: 0,
				group3: 0,
			},
			selectedPlan: null,
		};
	},
	computed: {
		rows() {
			return this.items.length;
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	methods: {
		addNewPack(e) {
			e.preventDefault();
			this.addNewRow = true;
		},
		sendNewPack() {
			if (this.selectedPlan === 'field crops') {
				this.newPackegeToSend.greenhouseCrops = 0;
				this.newPackegeToSend.orchardCrops = 0;

				this.newPackegeToSend.fieldCrops = this.newPackege.amount * this.newPackege.quantity;
			} else if (this.selectedPlan === 'orchard crops') {
				this.newPackegeToSend.fieldCrops = 0;
				this.newPackegeToSend.greenhouseCrops = 0;

				this.newPackegeToSend.orchardCrops = this.newPackege.amount * this.newPackege.quantity;
			} else if (this.selectedPlan === 'greenhouse crops') {
				this.newPackegeToSend.fieldCrops = 0;
				this.newPackegeToSend.orchardCrops = 0;

				this.newPackegeToSend.greenhouseCrops = this.newPackege.amount * this.newPackege.quantity;
			} else {
				console.log('nothing to send');
			}

			businessUsersService
				.updateBusinessUserPlanSize(this.newPackegeToSend)
				.then((response) => {
					this.editPanelItemsAdded = [
						...this.editPanelItemsAdded,
						{
							cropType: this.selectedPlan,
							hacta: this.newPackege.amount,
							quantity: this.newPackege.quantity,
						},
					];
					console.log('what to send: ', this.newPackegeToSend);
					console.log(response);
				})
				.finally(() => {
					this.selectedPlan = null;
					this.newPackege.amount = '';
					this.newPackege.quantity = '';
					this.addNewRow = false;

					// update table with the new plans
					this.fetchUserPlans();
				});
		},
		pickAmount() {
			const selected = this.editPanelItems.filter((item) => {
				return item.cropType == this.selectedPlan;
			});
			this.newPackege.amount = Number(selected[0].hacta);
		},
		onFiltered(filteredItems) {
			console.log(filteredItems, this.items);
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		fetchUserPlans() {
			businessUsersService.getBusinessUserDetailsById(this.id).then((response) => {
				const fetchedData = response.data.data;
				this.items = fetchedData.packagePlans;
				// this.items.map((item) => ((item['Total number of Plans'] = item['amount']), delete item['amount']));
			});
		},

		currentPlansTab() {
			this.activeIndex = 0;
		},
		plansUpdateTab() {
			this.activeIndex = 1;
		},
	},

	created() {
		this.fetchUserPlans();
		WP.getWordPressPlansAndPricesTable().then((response) => {
			console.log('wp', response);
			const data = response.data.data;
			const parsedData = JSON.parse(data.post_content);

			const formatedData = parsedData.map((item) => {
				return {
					cropType: item[3],
					hacta: item[2],
				};
			});

			const filteredData = formatedData.filter((plan, i) => {
				return (
					plan !== formatedData[0] &&
					formatedData[i].cropType !== 'agronomist private' &&
					formatedData[i].cropType !== 'agronomist business'
				);
			});
			this.editPanelItems = filteredData;
		});
	},
};
</script>

<style scoped>
.with-border {
	border: 1px solid black;
	border-radius: 8px;
}

.farmer-details-profile-tabs {
	padding: 20px;
}

.table-container {
	min-height: 500px;
}

.b-table-container th {
	padding-left: 15px;
}

.b-form-input {
	margin-bottom: 10px;
	margin-left: 15px;
}
.table {
	margin-bottom: 0;
}
.table td {
	border-top: unset;
	font-size: 12px;
	padding: 5px;
}

.table-striped th {
	padding: 5px 10px;
}

.form-control {
	width: 80%;
	padding: 3px;
}

.btn-outline-secondary {
	padding: 5px 0;
}

.b-pagination {
	padding-left: 15px;
}

.active-tab {
	font-weight: bold;
	border-bottom: 3px solid black;
	border-radius: unset;
}

.add-row {
	padding: 20px;
}

select,
input {
	max-width: 100%;
}
tr.table-row.even-row {
	background-color: rgba(0, 0, 0, 0.05);
}
</style>
