<template>
	<div class="farmer-update-profile-container" v-if="selectedFarmer">
		<div class="farmer-update-profile-form">
			<form @submit.prevent="onSubmit">
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">First Name</label>
					<input
						type="text"
						v-model="farmerToUpdate.firstName"
						class="farmer-update-profile-input"
						@blur="v$.farmerToUpdate.firstName.$touch()"
					/>
					<div v-if="v$.farmerToUpdate.firstName.$error">
						<p v-if="v$.farmerToUpdate.firstName.required" class="errorMessage">Farmer's first name is required</p>
					</div>
				</div>
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Last Name</label>
					<input
						type="text"
						v-model="farmerToUpdate.lastName"
						class="farmer-update-profile-input"
						@blur="v$.farmerToUpdate.lastName.$touch()"
					/>
					<div v-if="v$.farmerToUpdate.lastName.$error">
						<p v-if="v$.farmerToUpdate.lastName.required" class="errorMessage">Farmer's last name is required</p>
					</div>
				</div>
				<div class="flex-row farmer-update-profile-input-container">
					<div class="d-flex flex-column medium-input">
						<label class="farmer-update-profile-label">Country</label>
						<select class="farmer-update-profile-input" disabled>
							<option>{{ farmerToUpdate.country }}</option>
						</select>
					</div>
					<div
						class="d-flex flex-column medium-input margin-left-input"
						v-if="selectedFarmer.userType !== 'private_agro'"
					>
						<label class="farmer-update-profile-label">Total Size</label>
						<input type="number" class="farmer-update-profile-input" :placeholder="totalFieldSize" disabled />
					</div>
				</div>

				<div class="farmer-update-profile-input-container">
					<div class="d-flex flex-column medium-input">
						<label class="farmer-update-profile-label">Dealers</label>
						<select :disabled="!isAdmin" v-model="farmerToUpdate.businessUserId" name="" class="farmer-update-profile-input">
							<option :value="individualUserPlaceholder">Individual user</option>
							<option v-for="dealer in dealers" :key="dealer._id" :value="dealer._id" :selected="farmersAssignedDealer?.data._id === dealer._id">
								{{ `${dealer.contactName}, ${dealer.areaCode}${dealer.phone}` }}
							</option>
						</select>
					</div>
				</div>

				<label class="farmer-update-profile-label">Business Plans</label>
				<div class="farmer-update-profile-input-container">
					<button
						class="farmer-update-business-plan"
						v-for="businessPlan in farmerToUpdate.businessPlans"
						:key="businessPlan.id"
						disabled
					>
						{{ removeUnderScore(businessPlan.businessPlanName) }}
					</button>
				</div>
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Status</label>
					<select
						v-model="farmerToUpdate.status"
						class="select-status"
						:class="getActivityBadgeStatus(farmerToUpdate.status)"
					>
						{{
							farmerToUpdate.status
						}}
						<option value="ACTIVE">ACTIVE</option>
						<option value="NOT_ACTIVE">NOT ACTIVE</option>
					</select>
				</div>
				<div class="d-flex flex-column farmer-update-profile-input-container" v-if="selectedFarmer.activeUntilDate">
					<label class="farmer-update-profile-label">Valid Until</label>
					<input
						type="text"
						:placeholder="getFormattedDate(farmerToUpdate.activeUntilDate)"
						class="farmer-update-profile-input"
						disabled
					/>
				</div>
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Email Address</label>
					<input
						type="text"
						v-model="farmerToUpdate.email"
						class="farmer-update-profile-input"
						@blur="v$.farmerToUpdate.email.$touch()"
					/>
					<div v-if="v$.farmerToUpdate.email.$error">
						<p v-if="v$.farmerToUpdate.email.required" class="errorMessage">Farmer's email is required</p>
						<p v-if="v$.farmerToUpdate.email.email" class="errorMessage">Invalid email format</p>
					</div>
				</div>
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Receive Marketing updates</label>
					<select v-model="formattedMarketingAcceptance" class="select-status">
						{{
							formattedMarketingAcceptance
						}}
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div class="d-flex flex-column farmer-update-profile-input-container">
					<label class="farmer-update-profile-label">Log Level</label>
					<select v-model="farmerToUpdate.logLevel" class="farmer-update-profile-input">
						<option value="INFO">INFO</option>
						<option value="DEBUG">DEBUG</option>
					</select>
				</div>
				<div class="flex-row-no-space farmer-update-profile-btns">
					<button type="submit" class="btn blue-btn form-btn" name="update" id="1">Update</button>
					<button type="button" @click="cancelUpdate" class="btn form-btn-cancel" name="cancel" id="2">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import activityStatus from '../../utilities/activityStatus';
import dates from '../../utilities/dates';
import RemoveUnderscore from '../../utilities/removeUnderscore';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	name: 'FarmerUpdateProfile',
	props: {
		selectedFarmer: Object,
	},
	data() {
		return {
			totalFieldSize: 0,
			farmerToUpdate: { ...this.selectedFarmer },
			formattedMarketingAcceptance: this.selectedFarmer.marketingAcceptance == true ? 'Yes' : 'No',
			originalFormattedMarketingAcceptance: this.selectedFarmer.marketingAcceptance == true ? 'Yes' : 'No',
			dealers: null,
			farmersAssignedDealer: null,
			individualUserPlaceholder: undefined,
			isAdmin: null
		};
	},
	validations: {
		farmerToUpdate: {
			firstName: {
				required,
			},
			lastName: {
				required,
			},
			email: {
				required,
				email,
			},
		},
	},
	methods: {
		onSubmit() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				this.farmerToUpdate.farmerId = this.farmerToUpdate._id;
				if (this.formattedMarketingAcceptance !== this.originalFormattedMarketingAcceptance) {
					this.farmerToUpdate.marketingAcceptance = this.formattedMarketingAcceptance == 'Yes' ? true : false;
				}
				this.$store.dispatch('farmer/updateFarmer', this.farmerToUpdate);
				this.$emit('farmerupdatedetails', this.farmerToUpdate);
				setTimeout(() => {
					this.$emit('backtoprofile');
				}, 500);
			}
		},
		cancelUpdate() {
			this.$emit('cancelupdate');
		},
		removeUnderScore(str) {
			return RemoveUnderscore.removeUnderscrore(str);
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		getFormattedDate(date) {
			return dates.convertToShortDate(date);
		},
	},
	async created() {
		const totalFieldSize = this.selectedFarmer.businessPlans.map((plan) => (plan.totalFieldSize * 100) / 120);
		for (let size of totalFieldSize) {
			this.totalFieldSize += size;
		}

		if(this.selectedFarmer.businessUserId) {
			this.farmersAssignedDealer = await this.$store.dispatch('businessUser/getUser', this.selectedFarmer.businessUserId);
		}

		this.isAdmin = this.$store.getters['userSession/getUserRole'] === 'Super Admin';
		this.dealers = await this.$store.dispatch('businessUser/getActiveUsers');
	},
};
</script>

<style scoped></style>
