<template>
	<div class="crop-details-container border-padding">
		<modal-delete-crop
			v-if="isDeleteCropModal"
			@closedeletecropmodal="closeDeleteCropModal"
			@deletecrop="deleteCrop(cropId)"
			:cropId="cropId"
		></modal-delete-crop>
		<div @click.self="closeDeleteCropModal" :class="showAroundModal" class="modal-mask"></div>
		<div class="flex-row">
			<h6 class="crop-details-length">Crops ({{ cropsForDisplay.length }})</h6>

			<button @click="toggleFilterStatus">{{ getFilterStatus }}</button>
		</div>
		<div v-for="crop in cropsForDisplay" :key="crop._id" class="crop-details-card">
			<div>
				<h5>{{ crop.cropName }}'s Crop</h5>
			</div>

			<div class="flex-row crop-details-headers">
				<div>
					<p>Type</p>
					<h6>{{ removeUnderScore(crop.businessPlanName) }}</h6>
				</div>
				<div>
					<p>Crop size</p>
					<h6>
						{{ crop.polygonSize }}
					</h6>
				</div>
				<div>
					<p>Unit</p>
					<h6>{{ crop.measurementUnit || 'hectare' }}</h6>
				</div>
				<div>
					<p>Weight unit</p>
					<!-- <h6>{{ weightUnit || 'kg'}}</h6> -->
				</div>
				<div>
					<p>Location</p>
					<!-- <h6>{{ crop.location[0][0] }}</h6> -->
				</div>
				<div v-if="isAdmin">
					<p class="trash-icon">trash</p>
					<button
						v-if="crop.status === 'ACTIVE'"
						class="btn btn-outline-secondary"
						type="button"
						@click="OpenDeleteCropModal(crop._id)"
					>
						<i class="icon-trash"></i>
					</button>
					<button
						v-if="crop.status === 'NOT_ACTIVE'"
						class="btn btn-outline-secondary"
						type="button"
						@click="reactivateCrop(crop._id)"
					>
						<i class="icon-action-undo"></i>
					</button>
				</div>
			</div>
			<div class="crop-details-img-container" v-if="crop.location.length">
				<!-- <crop-map-field :pos="pos(crop.location)" /> -->
			</div>
		</div>
		<div></div>
	</div>
</template>

<script>
import userService from '../../services/userService';
// import CropMapField from '../admin/maps/CropMapField.vue';
import ModalDeleteCrop from '../admin/modals/ModalDeleteCrop';
import RemoveUnderscore from '../../utilities/removeUnderscore';
export default {
	name: 'CropDetails',
	components: {
		ModalDeleteCrop,
		// CropMapField,
	},
	props: {
		selectedFarmer: Object,
	},
	data() {
		return {
			isDeleteCropModal: false,
			cropId: null,
			crops: [],
			lat: 0,
			lng: 0,
		};
	},
	methods: {
		deleteCrop(cropId) {
			this.$store.dispatch('crop/deleteCrop', cropId);
			this.closeDeleteCropModal();
		},
		reactivateCrop(cropId) {
			this.$store.dispatch('crop/reactivateCrop', cropId);
		},
		toggleFilterStatus() {
			this.$store.dispatch('crop/toggleFilterStatus');
		},
		OpenDeleteCropModal(cropId) {
			this.cropId = cropId;
			this.isDeleteCropModal = true;
		},
		closeDeleteCropModal() {
			this.isDeleteCropModal = false;
			return 'show-around-modal';
		},
		removeUnderScore(str) {
			return RemoveUnderscore.removeUnderscrore(str);
		},
		pos(location) {
			const keys = new Map([
				['lat', location[0][0]],
				['lng', location[0][1]],
			]);
			return Object.fromEntries(keys);
		},
	},
	computed: {
		showAroundModal() {
			return { 'show-around-modal': !this.isDeleteCropModal };
		},
		cropsForDisplay() {
			const data = this.$store.getters['crop/cropsForDisplay'];
			return data;
		},
		getFilterStatus() {
			const status = this.$store.getters['crop/getFilterStatus'];
			return status === 'ACTIVE' ? 'Show inactive' : 'Show active';
		},
		weightUnit() {
			if (this.crop?.measurementUnit === 'acre') {
				return this.selectedFarmer.weightUnit;
			} else return 'kg';
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	async mounted() {
		try {
			const res = await userService.getFarmerCropsById(this.selectedFarmer._id);
			this.crops = res.data.data.farmerCrops;
			this.$store.dispatch('crop/getFarmerCropsById', res.data.data.farmerCrops);
		} catch (error) {
			console.log(`There was an error: ${error}`);
		}
	},
};
</script>

<style scoped>
.show-around-modal {
	position: unset !important;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}
</style>
