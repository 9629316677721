<template>
	<div id="main-content">
		<bread-crumb :itemsType="{ type: 'Access Token' }"></bread-crumb>

		<div v-if="!!accessToken" class="box">
			<div class="column">
				<h5>{{ accessToken.description }}</h5>
				<div><strong>Created at:</strong> {{ formatDate(accessToken.createdAt) }}</div>
				<div>
					<strong>Last Access at:</strong>
					{{ accessToken.lastAccessedAt ? formatDate(accessToken.lastAccessedAt) : '' }}
				</div>
				<div>
					<strong>Expire at:</strong> {{ accessToken.expireAt ? formatDate(accessToken.expireAt) : 'no expiration' }}
				</div>
				<div><strong>Endpoints allowed:</strong> {{ accessToken.endpoints?.join(', ') }}</div>
				<div><strong>Token:</strong> {{ accessToken.token }}</div>
			</div>
			<div class="column">
				<h5>Activity Log</h5>

				<table class="activity-log-table">
					<thead>
						<tr>
							<th>Action</th>
							<th>Timestamp</th>
							<th>IP</th>
							<th>Path</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="log in logs" :key="log._id">
							<td>{{ log.description }}</td>
							<td>{{ formatDate(log.createdAt) }}</td>
							<td>{{ log.ip }}</td>
							<th>{{ log.route }}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb';
import accessTokenService from '../../services/accessTokenService';

export default {
	name: 'AccessTokenDetails',
	props: {
		id: String,
	},
	components: {
		BreadCrumb,
	},
	data() {
		return {
			accessToken: null,
			logs: [],
		};
	},
	methods: {
		formatDate(date) {
			const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
			return new Date(date).toLocaleDateString('en-US', options);
		},
	},
	created() {
		accessTokenService
			.getById(this.id)
			.then((response) => {
				this.accessToken = response.data.token;
			})
			.catch((error) => {
				console.log(error);
			});
		accessTokenService
			.getLogs(this.id)
			.then((response) => {
				this.logs = response.data.data;
			})
			.catch((error) => {
				console.log(error);
			});
	},
};
</script>

<style scoped>
.box {
	/* Grid 2 col */
	padding: 0 20px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	/* Mobile 1 col */
	@media (max-width: 1200px) {
		grid-template-columns: 1fr;
	}
}
.column {
	display: flex;
	flex-direction: column;
	gap: 20px;
	border-radius: 10px;
	border: 1px solid #e0e0e0;
	padding: 10px;
}

.activity-log-table {
	width: 100%;
	border-collapse: collapse;
}
.activity-log-table th,
.activity-log-table td {
	padding: 10px;
	border: 1px solid #e0e0e0;
}
@media (max-width: 600px) {
	.activity-log-table th,
	.activity-log-table td {
		font-size: 12px;
	}
}

.box {
	/* Grid 2 col */
	padding: 0 20px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	/* Mobile 1 col */
	@media (max-width: 1200px) {
		grid-template-columns: 1fr;
	}
}

.column {
	display: flex;
	flex-direction: column;
	gap: 20px;
	border-radius: 10px;
	border: 1px solid #e0e0e0;
	padding: 10px;
}
</style>
