<template>
	<div id="main-content">
		<div
			@click.self="closeUSuccessModal"
			:class="{
				'modal-mask': isSuccessModalOpen || isDeleteModalOpen,
			}"
		></div>
		<bread-crumb-table
			:itemsType="{ type: 'Farmers' }"
			:isGroupNotificationModal="isGroupNotificationModal"
			@closegroupnotificationmodal="closeGroupNotificationModal()"
		></bread-crumb-table>
		<modal-group-notification
			v-if="isGroupNotificationModal"
			:markedFarmers="markedFarmers"
			@closegroupnotificationmodal="closeGroupNotificationModal()"
			@opensuccessmodal="openSuccessModal()"
		></modal-group-notification>
		<modal-success-dynamic
			v-if="isSuccessModalOpen"
			:upperTextStartLine="upperText"
			:bottomText="bottomText"
			@closesuccessmodal="closeUSuccessModal()"
		></modal-success-dynamic>
		<modal-delete-user
			v-if="isDeleteModalOpen"
			@deleteuser="deleteUser()"
			:userName="farmerName"
			@closedeletemodal="closeDeleteModal()"
		></modal-delete-user>
		<div class="col-lg-12 crop-table-container">
			<div class="flex-row-no-space flex-end" style="align-items: flex-end">
				<div class="flex-column">
					<p class="search-crop-title">Search By Name Or Phone</p>
					<div class="crop-input-icons input-icons first-input">
						<input
							placeholder="search phone without +"
							class="search-farmer-input"
							@input="setFilter"
							v-model="filterBy.name"
						/>
						<span class="input-group-text"><i class="icon-magnifier"></i></span>
					</div>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.country">
						<option value="">Country</option>
						<option v-for="item in countries" :key="item.id">
							{{ item.country }}
						</option>
					</select>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.size">
						<option value="" hidden>Size Range</option>
						<option v-for="size in sizeRange" :key="size.id">{{ size.min }}-{{ size.max }}</option>
					</select>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.active">
						<option value="ACTIVE" selected="selected">Active</option>
						<option value="PENDING">Pending</option>
						<option value="NOT_ACTIVE">Not Active</option>
					</select>
				</div>
				<button @click="resetFilter()" class="btn reset-filter">Reset Filter</button>
			</div>
			<div class="farmer-search-message" v-if="!isFiltered">
				<h4>Please search / refine by characteristics to see results.</h4>
			</div>
			<div v-if="isFiltered" class="flex-space-between paging-exporting-container">
				<paging
					:filteredFarmers="farmersForDisplay"
					@updatevisiblefarmers="updateVisibleFarmers"
					:key="componentKey"
				></paging>
				<div v-if="isThChecked && !isAllMarked && visibleFarmers.length > 0">
					All {{ this.visibleFarmers.length }} members on this page are selected.
					<button class="btn reset-filter" @click="markAllMembers" v-if="farmersForDisplay > visibleFarmers">
						Select all {{ this.farmersForDisplay.length }} members from this list
					</button>
				</div>
				<div v-if="isAllMarked">
					All {{ this.farmersForDisplay.length }} members are selected.
					<button class="btn reset-filter" @click="unmarkAllMembers">Clear selection</button>
				</div>
				<div class="paging-right-buttons">
					<button class="btn export-btn" @click="exportFarmersToExcel()">Export</button>
					<button
						v-if="isAdmin"
						class="btn btn-outline-secondary blue-btn"
						@click="openGroupNotificationModal()"
						:disabled="!markedFarmers.length"
					>
						<i class="icon-bell"></i>
					</button>
				</div>
			</div>
			<div class="card">
				<div class="body table-responsive">
					<table class="table table-hover" id="table-export-excel">
						<thead class="crop-table-head">
							<tr>
								<th v-if="isAdmin">
									<input
										type="checkbox"
										@click="markShownFarmers()"
										class=""
										id="th-farmer-checkbox"
										:disabled="!isFiltered"
										v-model="isThChecked"
									/>
								</th>
								<th class="crop-table-head">Status</th>
								<th class="crop-table-head">Name</th>
								<th class="crop-table-head">Email</th>
								<th class="crop-table-head">Phone</th>
								<th class="crop-table-head">Location</th>
								<th class="crop-table-head">Size</th>
								<th class="crop-table-head farmer-table-action" v-if="isAdmin">Action</th>
							</tr>
						</thead>
						<tbody class="crop-table-body" v-if="isFiltered">
							<tr v-for="farmer in visibleFarmers" :key="farmer._id">
								<td v-if="isAdmin">
									<input
										type="checkbox"
										@click="markFarmer(farmer)"
										class="farmer-checkbox"
										name="farmer-checkbox"
										id="checkbox"
										v-model="farmer.isChecked"
									/>
								</td>
								<td>
									<div :class="getActivityBadgeStatus(farmer.status)" class="crop-status">
										{{ farmer.status }}
									</div>
								</td>
								<td>
									<div class="farmer-name-btn">
										<router-link
											:to="{
												name: 'farmer-details',
												params: { id: farmer._id },
											}"
										>
											{{ farmer.firstName }} {{ farmer.lastName }}
										</router-link>
									</div>
									<div class="farmer-date">Created {{ getFormattedDate(farmer.createdAt) }}</div>
								</td>
								<td>{{ farmer.email }}</td>
								<td>+{{ farmer.areaCode }}{{ farmer.phoneNumber }}</td>
								<td>{{ farmer.country }}</td>
								<td>
									{{ gettotalBusinessPlansSize(farmer.totalBusinessPlansSize) }}
								</td>
								<td class="farmer-cell-actions" v-if="isAdmin">
									<a class="btn btn-outline-secondary" @click="openDeleteModal(farmer)">
										<i class="icon-trash"></i>
									</a>
									<a class="btn btn-outline-secondary pencil-margin" @click="openNotificationModal(farmer)">
										<i class="icon-bell"></i
									></a>
									<router-link
										:to="{
											name: 'farmer-details',
											params: { id: farmer._id },
										}"
									>
										<a class="btn btn-outline-secondary pencil-margin">
											<i class="icon-pencil" style="color: darkslategrey"></i
										></a>
									</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pick from 'object.pick';
import BreadCrumbTable from '@/components/BreadCrumbTable';
import activityStatus from '../../utilities/activityStatus';
import utilities from '../../utilities/getCountries';
import paging from '@/components/admin/app/paging';
import consts from '../../utilities/consts';
import util from '../../utilities/util';
import dates from '../../utilities/dates';
import utilitiesWP from '../../utilities/WP';
import ModalGroupNotification from '../admin/modals/ModalGroupNotification.vue';
import ModalSuccessDynamic from '../admin/modals/ModalSuccessDynamic.vue';
import ModalDeleteUser from '../admin/modals/ModalDeleteUser.vue';

export default {
	name: 'FarmerTable',
	components: {
		BreadCrumbTable,
		paging,
		ModalGroupNotification,
		ModalSuccessDynamic,
		ModalDeleteUser,
	},
	data() {
		return {
			isDeleteModalOpen: false,
			isThChecked: false,
			componentKey: 0,
			isFiltered: false,
			wpData: [],
			sizeRange: [],
			countries: [],
			filterBy: {
				sortBy: '',
				sortAscending: true,
				name: '',
				size: '',
				active: 'ACTIVE',
				country: '',
				cropType: '',
				marketingAlerts: '',
			},
			markedFarmers: [],
			visibleFarmers: [],
			isAllMarked: false,
			isGroupNotificationModal: false,
			isSuccessModalOpen: false,
			upperText: 'Notifications were sent to the chosen members',
			bottomText: 'You can go back to members page',
			farmer: null,
			farmerName: '',
		};
	},
	methods: {
		loadFarmers() {
			this.$store.dispatch('farmer/getFarmers', { router: this.$router }).then(() => {
				this.updateVisibleFarmers(this.farmersForDisplay);
			});
		},
		setFilter() {
			this.componentKey++;
			this.isFiltered = true;
			this.$store.commit('farmer/SET_FILTER', this.filterBy);
		},
		getActivityBadgeStatus(status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		resetFilter() {
			this.isFiltered = false;
			this.filterBy.country = '';
			this.filterBy.name = '';
			this.filterBy.size = '';
			this.filterBy.active = '';
		},
		markFarmer(farmer) {
			farmer.isChecked = !farmer.isChecked;
			const markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			this.markedFarmers = markedFarmers;
			// the th checkbox if infected by the other checkboxes
			if (this.visibleFarmers.length === markedFarmers.length) this.isThChecked = true;
			else {
				this.isThChecked = false;
				this.isAllMarked = false;
			}
		},
		markShownFarmers() {
			const markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			for (let visibleFarmer of this.visibleFarmers) {
				visibleFarmer.isChecked = markedFarmers.length < this.visibleFarmers.length;
			}
			this.markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			console.log(this.markedFarmers);
		},
		markAllMembers() {
			this.isAllMarked = true;
			for (let farmerForDisplay of this.farmersForDisplay) {
				farmerForDisplay.isChecked = true;
			}
		},
		unmarkAllMembers() {
			this.isAllMarked = false;
			for (let farmerForDisplay of this.farmersForDisplay) {
				farmerForDisplay.isChecked = false;
			}
			this.isThChecked = false;
		},
		updateVisibleFarmers(visibleFarmers) {
			this.visibleFarmers = visibleFarmers;
			const markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			this.isThChecked = markedFarmers.length >= this.visibleFarmers.length;
		},
		exportFarmersToExcel() {
			const data = this.farmersForDisplay.map((farmer) => pick(farmer, consts.KEYS_TO_EXPORT));
			util.exportToExcel(data);
		},
		openGroupNotificationModal() {
			this.isGroupNotificationModal = true;
		},
		openNotificationModal(farmer) {
			this.markedFarmers.map((farmer) => {
				return (farmer.isChecked = false);
			});
			this.markedFarmers = [];
			this.markedFarmers.push(farmer);
			farmer.isChecked = true;
			this.isGroupNotificationModal = true;
		},
		closeGroupNotificationModal() {
			this.isGroupNotificationModal = false;
		},
		openSuccessModal() {
			this.isSuccessModalOpen = true;
		},
		closeUSuccessModal() {
			this.isSuccessModalOpen = false;
		},
		openDeleteModal(farmer) {
			this.farmer = farmer;
			this.farmerName = `${farmer.firstName} ${farmer.lastName}`;
			this.isDeleteModalOpen = true;
		},
		closeDeleteModal() {
			this.isDeleteModalOpen = false;
		},
		deleteUser() {
			const farmerId = this.farmer._id;
			this.$store.dispatch('farmer/deleteFarmer', farmerId);
			this.resetFilter();
		},
		gettotalBusinessPlansSize(size) {
			if (size) {
				return (size * 100) / 120;
			} else {
				return 0;
			}
		},
		getFormattedDate(date) {
			return dates.convertToShortDate(date);
		},
	},
	computed: {
		farmersForDisplay() {
			return this.$store.getters['farmer/farmersForDisplay'];
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	watch: {
		farmersForDisplay() {
			this.setFilter();
		},
	},
	created() {
		this.loadFarmers();
		utilities.getCountries().then((response) => {
			this.countries = response.data.data;
		});
		utilitiesWP
			.getSizeRangeTable()
			.then((response) => {
				this.wpData = JSON.parse(response.data.data.post_content);
				let keys = this.wpData.shift();
				let obj = null;
				const sizeRange = [];
				for (let i = 0; i < this.wpData.length; i++) {
					obj = {};
					for (let k = 0; k < keys.length; k++) {
						obj[keys[k]] = this.wpData[i][k];
					}
					sizeRange.push(obj);
				}
				this.sizeRange = sizeRange;
			})
			.catch((error) => {
				this.$router.push({ path: '/authentication/page-404' });
				console.log(`There was an error: ${error}`);
			});
	},
};
</script>

<style scoped>
.search-farmer-input {
	max-height: 40px;
}
/* Your CSS styles */
</style>
