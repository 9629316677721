import axios from 'axios';
import store from '../store/store';

const envUrl = process.env.VUE_APP_URL;
const BASEURL = `${envUrl}access-token`;
const APPKEY = 'bxXnw3Wxp5V4GWVei5EgvXdMnKB9llOHIBV4';

export default {
	getAccessTokens() {
		let config = {
			method: 'GET',
			url: BASEURL,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	generateAccessToken(data) {
		let config = {
			method: 'POST',
			url: BASEURL,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data,
		};
		return axios(config);
	},
	deleteAccessToken(tokenId) {
		let config = {
			method: 'DELETE',
			url: `${BASEURL}/${tokenId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	getById(tokenId) {
		let config = {
			method: 'GET',
			url: `${BASEURL}/${tokenId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	getLogs(tokenId) {
		let config = {
			method: 'GET',
			url: `${BASEURL}/${tokenId}/logs`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
};
