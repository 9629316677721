<template>
	<div id="main-content">
		<div
			@click.self="closeUSuccessModal"
			:class="{
				'modal-mask': isSuccessModalOpen || isDeleteModalOpen,
			}"
		></div>
		<bread-crumb-table
			:itemsType="{ type: 'Farmers' }"
			:isGroupNotificationModal="isGroupNotificationModal"
			@closegroupnotificationmodal="closeGroupNotificationModal()"
		></bread-crumb-table>
		<modal-group-notification
			v-if="isGroupNotificationModal"
			:markedFarmers="markedFarmers"
			@closegroupnotificationmodal="closeGroupNotificationModal()"
			@opensuccessmodal="openSuccessModal()"
		></modal-group-notification>
		<modal-success-dynamic
			v-if="isSuccessModalOpen"
			:upperTextStartLine="upperText"
			:bottomText="bottomText"
			@closesuccessmodal="closeUSuccessModal()"
		></modal-success-dynamic>
		<modal-delete-user
			v-if="isDeleteModalOpen"
			@deleteuser="deleteUser()"
			:userName="farmerName"
			@closedeletemodal="closeDeleteModal()"
		></modal-delete-user>
		<div class="col-lg-12 crop-table-container">
			<div class="flex-row-no-space flex-end">
				<div class="flex-column">
					<div class="crop-input-icons input-icons first-input">
						<input placeholder="Name or phone" class="search-farmer-input" @input="setFilter" v-model="filterBy.name" />
						<span class="input-group-text"></span>
					</div>
				</div>

				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.country">
						<option value="">Country</option>
						<option v-for="item in countries" :key="item.id">
							{{ item.country }}
						</option>
					</select>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.size">
						<option value="">Size Range</option>
						<option v-for="size in sizeRange" :key="size.id">{{ size.min }}-{{ size.max }}</option>
					</select>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.active">
						<option value="" selected="selected">All Statuses</option>
						<option value="ACTIVE">Active</option>
						<option value="PENDING">Pending</option>
						<option value="NOT_ACTIVE">Not Active</option>
					</select>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.cropType">
						<option value="">Crop Type</option>
						<option v-for="cropType in this.cropTypes" :value="cropType" :key="cropType.id">
							{{ cropType }}
						</option>
					</select>
				</div>
				<div class="crop-input-icons input-icons">
					<select class="search-farmer-input" @change="setFilter" v-model="filterBy.marketingAlerts">
						<option value="">Marketing Alerts</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<button @click="resetFilter()" class="btn reset-filter">Reset Filter</button>
			</div>
			<p>Search phone without +</p>

			<div class="farmer-search-message" v-if="!isFiltered">
				<h4>Please search / refine by characteristics to see results.</h4>
			</div>
			<div v-if="isFiltered" class="flex-space-between paging-exporting-container">
				<paging
					:filteredFarmers="farmersForDisplay"
					@updatevisiblefarmers="updateVisibleFarmers"
					:key="componentKey"
				></paging>
				<div v-if="isThChecked && !isAllMarked && visibleFarmers.length > 0">
					All {{ this.visibleFarmers.length }} members on this page are selected.
					<button class="btn reset-filter" @click="markAllMembers" v-if="farmersForDisplay > visibleFarmers">
						Select all {{ this.farmersForDisplay.length }} members from this list
					</button>
				</div>
				<div v-if="isAllMarked">
					All {{ this.farmersForDisplay.length }} members are selected.
					<button class="btn reset-filter" @click="unmarkAllMembers">Clear selection</button>
				</div>
				<div class="paging-right-buttons">
					<button class="btn export-btn" @click="exportFarmersToExcel()">Export</button>
					<button
						v-if="isAdmin"
						class="btn btn-outline-secondary blue-btn"
						@click="openGroupNotificationModal()"
						:disabled="!markedFarmers.length"
					>
						<i class="icon-bell"></i>
					</button>
				</div>
			</div>
			<div class="card">
				<div class="body table-responsive">
					<table class="table table-hover" id="table-export-excel">
						<thead class="crop-table-head">
							<tr>
								<th v-if="isAdmin" class="checkbox-column">
									<input
										type="checkbox"
										@click="markShownFarmers()"
										class=""
										id="th-farmer-checkbox"
										:disabled="!isFiltered"
										v-model="isThChecked"
									/>
								</th>
								<th class="crop-table-head">Status</th>
								<th class="crop-table-head" @click="sortBy('firstName')">
									Name <span v-html="getSortIcon('firstName')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('email')">
									Email <span v-html="getSortIcon('email')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('formattedPhone')">
									Phone <span v-html="getSortIcon('formattedPhone')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('country')">
									Location <span v-html="getSortIcon('country')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('totalBusinessPlansSize')">
									Size <span v-html="getSortIcon('totalBusinessPlansSize')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('numberOfCrops')">
									Plots <span v-html="getSortIcon('numberOfCrops')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('cropTypesStr')">
									Crops <span v-html="getSortIcon('cropTypesStr')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('cropMeasurementUnitsStr')">
									Unit <span v-html="getSortIcon('cropMeasurementUnitsStr')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('businessPlansNamesStr')">
									Plans <span v-html="getSortIcon('businessPlansNamesStr')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('isSubscribed')">
									Subscription <span v-html="getSortIcon('isSubscribed')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('businessPlansValidUntilDate')">
									Program Validity <span v-html="getSortIcon('businessPlansValidUntilDate')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('userType')">
									User Type <span v-html="getSortIcon('userType')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('lastSampleDate')">
									Last Sample <span v-html="getSortIcon('lastSampleDate')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('marketingAlertsApproved')">
									Marketing Alerts <span v-html="getSortIcon('marketingAlertsApproved')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('lastLoginDateFormatted')">
									Last Login <span v-html="getSortIcon('lastLoginDateFormatted')"></span>
								</th>
								<th class="crop-table-head" @click="sortBy('isPayer')">
									Paying Customer <span v-html="getSortIcon('isPayer')"></span>
								</th>
								<th class="crop-table-head">Farmer ID</th>
								<th class="crop-table-head farmer-table-action">Action</th>
							</tr>
						</thead>
						<tbody class="crop-table-body" v-if="isFiltered">
							<tr v-for="farmer in visibleFarmers" :key="farmer._id">
								<td class="checkbox-column" v-if="isAdmin">
									<input
										type="checkbox"
										@click="markFarmer(farmer)"
										class="farmer-checkbox"
										name="farmer-checkbox"
										id="checkbox"
										v-model="farmer.isChecked"
									/>
								</td>
								<td>
									<div :class="getActivityBadgeStatus(farmer.status)" class="crop-status">
										{{ farmer.status }}
									</div>
								</td>
								<td>
									<div class="farmer-name-btn">
										<router-link
											:to="{
												name: 'farmer-details',
												params: { id: farmer._id },
											}"
										>
											<span v-tooltip="{ content: [farmer.firstName, farmer.lastName].join(' ') }">{{
												getLongTextFormat([farmer.firstName, farmer.lastName].join(' '))
											}}</span>
										</router-link>
									</div>
									<div class="farmer-date">Created {{ getFormattedDate(farmer.createdAt) }}</div>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.email }">{{ getLongTextFormat(farmer.email) }}</span>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.formattedPhone }">{{
										getLongTextFormat(farmer.formattedPhone)
									}}</span>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.country }">{{ getLongTextFormat(farmer.country) }}</span>
								</td>
								<td>
									<span v-tooltip="{ content: gettotalBusinessPlansSize(farmer.totalBusinessPlansSize).toString() }">{{
										getLongTextFormat(gettotalBusinessPlansSize(farmer.totalBusinessPlansSize).toString())
									}}</span>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.numberOfCrops.toString() }">{{ farmer.numberOfCrops }}</span>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.cropTypesStr }">{{ getLongTextFormat(farmer.cropTypesStr) }}</span>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.cropMeasurementUnitsStr }">{{
										getLongTextFormat(farmer.cropMeasurementUnitsStr)
									}}</span>
								</td>
								<td>
									<span v-tooltip="{ content: farmer.businessPlansNamesStr }">{{
										getLongTextFormat(farmer.businessPlansNamesStr)
									}}</span>
								</td>
								<td>{{ farmer.businessPlansValidUntilDateFormatted }}</td>
								<td>{{ farmer.isSubscribed }}</td>
								<td>
									<span v-tooltip="{ content: farmer.userTypeFormatted }">{{ farmer.userTypeFormatted }}</span>
								</td>
								<td>{{ farmer.lastSampleDateFormatted }}</td>
								<td>{{ farmer.marketingAlertsApproved }}</td>
								<td>{{ farmer.lastLoginDateFormatted }}</td>
								<td>{{ farmer.isPayer }}</td>
								<td>{{ farmer._id }}</td>

								<td class="farmer-cell-actions" v-if="isAdmin">
									<a class="btn btn-outline-secondary" @click="openDeleteModal(farmer)">
										<i class="icon-trash"></i>
									</a>
									<a class="btn btn-outline-secondary pencil-margin" @click="openNotificationModal(farmer)">
										<i class="icon-bell"></i
									></a>
									<router-link
										:to="{
											name: 'farmer-details',
											params: { id: farmer._id },
										}"
									>
										<a class="btn btn-outline-secondary pencil-margin">
											<i class="icon-pencil" style="color: darkslategrey"></i
										></a>
									</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pick from 'object.pick';
import BreadCrumbTable from '@/components/BreadCrumbTable';
import activityStatus from '../../utilities/activityStatus';
import utilities from '../../utilities/getCountries';
import paging from '@/components/admin/app/paging';
import consts from '../../utilities/consts';
import util from '../../utilities/util';
import dates from '../../utilities/dates';
import utilitiesWP from '../../utilities/WP';
import ModalGroupNotification from '../admin/modals/ModalGroupNotification.vue';
import ModalSuccessDynamic from '../admin/modals/ModalSuccessDynamic.vue';
import ModalDeleteUser from '../admin/modals/ModalDeleteUser.vue';

export default {
	name: 'FarmerReportTable',
	components: {
		BreadCrumbTable,
		paging,
		ModalGroupNotification,
		ModalSuccessDynamic,
		ModalDeleteUser,
	},
	data() {
		return {
			isDeleteModalOpen: false,
			isThChecked: false,
			componentKey: 0,
			isFiltered: false,
			wpData: [],
			sizeRange: [],
			countries: [],
			filterBy: {
				sortBy: 'firstName',
				sortAscending: true,
				name: '',
				size: '',
				active: '',
				country: '',
				cropType: '',
				marketingAlerts: '',
			},
			markedFarmers: [],
			visibleFarmers: null,
			isAllMarked: false,
			isGroupNotificationModal: false,
			isSuccessModalOpen: false,
			upperText: 'Notifications were sent to the chosen members',
			bottomText: 'You can go back to members page',
			farmer: null,
			farmerName: '',
			cropTypes: [],
		};
	},
	methods: {
		loadFarmers() {
			this.$store.dispatch('farmer/getFarmers', { router: this.$router });
		},
		setFilter() {
			this.componentKey++;
			this.isFiltered = true;
			this.$store.commit('farmer/SET_FILTER', this.filterBy);
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		resetFilter() {
			this.isFiltered = false;
			this.filterBy.sortBy = 'firstName';
			this.filterBy.sortAscending = true;
			this.filterBy.country = '';
			this.filterBy.name = '';
			this.filterBy.size = '';
			this.filterBy.active = '';
			this.filterBy.cropType = '';
			this.filterBy.marketingAlerts = '';
			this.setFilter();
		},
		markFarmer(farmer) {
			farmer.isChecked = !farmer.isChecked;
			const markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			this.markedFarmers = markedFarmers;
			// the th checkbox if infected by the other checkboxes
			if (this.visibleFarmers.length === markedFarmers.length) this.isThChecked = true;
			else {
				this.isThChecked = false;
				this.isAllMarked = false;
			}
		},
		markShownFarmers() {
			const markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			for (let visibleFarmer of this.visibleFarmers) {
				visibleFarmer.isChecked = markedFarmers.length < this.visibleFarmers.length;
			}
			this.markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			console.log(this.markedFarmers);
		},
		markAllMembers() {
			this.isAllMarked = true;
			for (let farmerForDisplay of this.farmersForDisplay) {
				farmerForDisplay.isChecked = true;
			}
		},
		unmarkAllMembers() {
			this.isAllMarked = false;
			for (let farmerForDisplay of this.farmersForDisplay) {
				farmerForDisplay.isChecked = false;
			}
			this.isThChecked = false;
		},
		updateVisibleFarmers(visibleFarmers) {
			this.visibleFarmers = visibleFarmers;
			const markedFarmers = this.visibleFarmers.filter((farmer) => farmer.isChecked);
			this.isThChecked = markedFarmers.length >= this.visibleFarmers.length;
		},
		exportFarmersToExcel() {
			const data = this.farmersForDisplay.map((farmer) => pick(farmer, consts.FARMER_EXTENDED_KEYS_TO_EXPORT));
			util.exportToExcelWithCustomHeaders(data, consts.FARMER_EXTENDED_HEADERS_TO_EXPORT);
		},
		openGroupNotificationModal() {
			this.isGroupNotificationModal = true;
		},
		openNotificationModal(farmer) {
			this.markedFarmers.map((farmer) => {
				return (farmer.isChecked = false);
			});
			this.markedFarmers = [];
			this.markedFarmers.push(farmer);
			farmer.isChecked = true;
			this.isGroupNotificationModal = true;
		},
		closeGroupNotificationModal() {
			this.isGroupNotificationModal = false;
		},
		openSuccessModal() {
			this.isSuccessModalOpen = true;
		},
		closeUSuccessModal() {
			this.isSuccessModalOpen = false;
		},
		openDeleteModal(farmer) {
			this.farmer = farmer;
			this.farmerName = `${farmer.firstName} ${farmer.lastName}`;
			this.isDeleteModalOpen = true;
		},
		closeDeleteModal() {
			this.isDeleteModalOpen = false;
		},
		deleteUser() {
			const farmerId = this.farmer._id;
			this.$store.dispatch('farmer/deleteFarmer', farmerId);
			this.resetFilter();
		},
		gettotalBusinessPlansSize(size) {
			if (size) {
				return (size * 100) / 120;
			} else {
				return 0;
			}
		},
		getFormattedDate(date) {
			return dates.convertToShortDate(date);
		},
		getLongTextFormat(text) {
			if (!text) {
				return null;
			}
			if (text.length <= consts.LONG_TEXT_NAX_LENGTH) {
				return text;
			}
			return `${text.substring(0, consts.LONG_TEXT_NAX_LENGTH)}...`;
		},
		sortBy(fieldName) {
			console.log(fieldName);
			if (this.filterBy.sortBy === fieldName) {
				this.filterBy.sortAscending = !this.filterBy.sortAscending;
			} else {
				this.filterBy.sortAscending = true;
				this.filterBy.sortBy = fieldName;
			}
			this.setFilter();
		},

		getSortIcon(fieldName) {
			if (this.filterBy.sortBy === fieldName) {
				return this.filterBy.sortAscending ? '<span>&#8593;</span>' : '<span>&#8595;</span>';
			} else {
				return '';
			}
		},
	},
	computed: {
		farmersForDisplay() {
			return this.$store.getters['farmer/farmersForDisplay'];
		},
		isAdmin() {
			const userRole = this.$store.getters['userSession/getUserRole'];
			return userRole === 'Super Admin';
		},
	},
	watch: {
		farmersForDisplay() {
			this.setFilter();
		},
	},
	created() {
		this.loadFarmers();
		utilities.getCountries().then((response) => {
			this.countries = response.data.data;
		});
		utilitiesWP
			.getSizeRangeTable()
			.then((response) => {
				this.wpData = JSON.parse(response.data.data.post_content);
				let keys = this.wpData.shift();
				let obj = null;
				const sizeRange = [];
				for (let i = 0; i < this.wpData.length; i++) {
					obj = {};
					for (let k = 0; k < keys.length; k++) {
						obj[keys[k]] = this.wpData[i][k];
					}
					sizeRange.push(obj);
				}
				this.sizeRange = sizeRange;
			})
			.catch((error) => {
				this.$router.push({ path: '/authentication/page-404' });
				console.log(`There was an error: ${error}`);
			});

		utilitiesWP
			.getCropTypesTable()
			.then((response) => {
				const data = JSON.parse(response.data.data.post_content);
				data.shift(); // this is for replacing the header from WP
				const cropTypes = data.map((item) => item[0].split('_')[2]);
				this.cropTypes = cropTypes.sort();
			})
			.catch((error) => {
				this.$router.push({ path: '/authentication/page-404' });
				console.log(`There was an error: ${error}`);
			});
	},
};
</script>

<style scoped>
.farmer-search-message {
	text-align: center;
	margin-top: 50px;
}
.search-farmer-input {
	height: 34px;
	width: 150px;
}
.flex-end {
	align-items: flex-end;
}
.farmer-table-action {
	text-align: right;
}
.farmer-cell-actions {
	text-align: right;
}
.pencil-margin {
	margin-left: 5px;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.paging-exporting-container {
	margin-top: 20px;
}
.card td {
	font-weight: 300;
}
.farmer-date {
	font-size: 12px;
}

td {
	max-width: 250px;
	overflow: hidden;
}

th {
	min-width: 150px;
}

.checkbox-column {
	min-width: 30px;
	max-width: 30px;
}

.crop-input-icons {
	width: 150px;
}
</style>
