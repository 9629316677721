<template>
	<div class="farmer-profile-details">
		<div class="farmer-profile-details-row">
			<p>Company</p>
			<h6>{{ selectedBU.company }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Country</p>
			<h6>{{ selectedBU.country }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Contact Name</p>
			<h6>{{ selectedBU.contactName }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Contact Email</p>
			<h6 class="farmer-profile-email">{{ selectedBU.email }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Contact Phone</p>
			<h6>{{ selectedBU.phone }}</h6>
		</div>

		<div class="farmer-profile-details-row">
			<p>Contact Type</p>
			<h6>{{ selectedBU.type }}</h6>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BusinessUserProfileDetails',
	props: {
		selectedBU: Object,
	},
};
</script>
