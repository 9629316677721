<template>
	<div class="add-crop-modal">
		<div class="flex-row top-section">
			<h5 v-if="userToUpdate">Update Admin User</h5>
			<template v-else>
				<h5>Add New User</h5>
			</template>
			<button class="close-modal-btn btn" @click="closeModal()">X</button>
		</div>
		<form class="form-add-crop" @submit.prevent="addUser">
			<div class="flex-row">
				<div class="d-flex flex-column">
					<label class="add-crop-label">First Name </label>
					<input
						class="form-control select-input"
						v-model="newUser.firstName"
						type="text"
						@keypress="LettersOnly($event)"
						@blur="v$.newUser.firstName.$touch()"
					/>
					<div v-if="v$.newUser.firstName.$error">
						<p v-if="!v$.newUser.firstName.required" class="input-error-msg">First name is required</p>
					</div>
				</div>
				<div>
					<div class="d-flex flex-column right-input">
						<label class="add-crop-label">Last Name </label>
						<input
							class="form-control select-input"
							v-model="newUser.lastName"
							type="text"
							@keypress="LettersOnly($event)"
							@blur="v$.newUser.lastName.$touch()"
						/>
						<div v-if="v$.newUser.lastName.$error">
							<p v-if="!v$.newUser.lastName.required" class="input-error-msg">Last name is required</p>
						</div>
					</div>
				</div>
			</div>

			<div class="flex-row">
				<div class="d-flex flex-column middle-section">
					<label class="add-crop-label">Phone</label>
					<input
						class="form-control select-input"
						v-model="newUser.phone"
						type="tel"
						@keypress="NumbersOnly($event)"
						minlength="9"
						maxlength="11"
						@blur="v$.newUser.phone.$touch()"
					/>
					<div v-if="v$.newUser.phone.$error">
						<p v-if="!v$.newUser.phone.required" class="input-error-msg">Phone is required</p>
					</div>
				</div>
				<div class="d-flex flex-column middle-section">
					<label class="add-crop-label">Email</label>
					<input
						class="form-control select-input"
						v-model="newUser.email"
						type="text"
						@blur="v$.newUser.email.$touch()"
					/>
					<div v-if="v$.newUser.email.$error">
						<p v-if="!v$.newUser.email.email" class="input-error-msg">Please enter a valid email</p>
						<p v-if="!v$.newUser.email.required" class="input-error-msg">Email is required</p>
					</div>
				</div>
			</div>
			<div class="flex-row">
				<div class="d-flex flex-column">
					<div class="flex-row">
						<label class="add-crop-label">Password</label>
						<a @click="generatePassword()" class="generate-password" style="color: blue; text-decoration: underline"
							>Generate</a
						>
					</div>
					<div class="crop-input-icons input-icons password-input">
						<input
							ref="passInput"
							class="search-farmer-input"
							v-model="newUser.password"
							type="password"
							maxlength="9"
							@blur="v$.newUser.password.$touch()"
						/>
						<i ref="passIcon" class="concil-password btn fa fa-eye-slash" @click="toggleInputType()"></i>
					</div>
					<div v-if="v$.newUser.password.$error">
						<p v-if="!v$.newUser.password.required" class="input-error-msg">Password is required</p>
					</div>
				</div>
				<div class="d-flex flex-column right-input">
					<label class="add-crop-label">Verify Password</label>

					<div class="crop-input-icons input-icons password-input">
						<input
							class="search-farmer-input"
							maxlength="9"
							v-model="newUser.verifyPassword"
							type="password"
							placeholder="9 chars - letters and num"
							ref="verifyPassInput"
							@blur="v$.newUser.verifyPassword.$touch()"
						/>
						<span>
							<i ref="verifyPassIcon" class="concil-password btn fa fa-eye-slash" @click="toggleVerifyInputType()"></i
						></span>
					</div>
					<div v-if="v$.newUser.verifyPassword.$error">
						<p v-if="!v$.newUser.verifyPassword.required" class="input-error-msg">Must verify password</p>
					</div>
				</div>
			</div>
			<div class="flex-row">
				<div v-if="userToUpdate" class="d-flex flex-column">
					<label class="add-crop-label">Status </label>
					<select
						class="form-control select-input password-input"
						v-model="newUser.status"
						:class="getActivityBadgeStatus(newUser.status)"
					>
						<option value="ACTIVE">Active</option>
						<option value="NOT_ACTIVE">Not Active</option>
					</select>
				</div>
				<div class="d-flex flex-column right-input w-100">
					<label class="add-crop-label">Role </label>
					<select class="form-control select-input" v-model="newUser.role">
						<option value="Super Admin">Super Admin</option>
						<option value="Support">Support</option>
					</select>
				</div>
			</div>
			<div class="next-btn-container">
				<button type="submit" v-if="userToUpdate" class="next-btn btn">Update</button>
				<template v-else>
					<button type="submit" class="next-btn btn">Save</button>
				</template>
				<p v-if="errMsg" class="input-error-msg">Not all fields are proper</p>
				<p v-if="errPasswordMsg" class="input-error-msg">The passwords must be the same</p>

				<p v-if="errServerMsg" class="input-error-msg">The password pattern doesn't match.</p>
			</div>
		</form>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength, requiredUnless } from '@vuelidate/validators';
import activityStatus from '../../../utilities/activityStatus';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	name: 'AddUserForm',
	props: {
		userToUpdate: Object,
	},
	data() {
		return {
			newUser: {
				firstName: '',
				lastName: '',
				password: '',
				verifyPassword: '',
				phone: '',
				email: '',
				role: '',
				status: '',
			},
			type: 'password',
			icon: 'fa fa-eye-slash',
			errMsg: false,
			errServerMsg: false,
			errPasswordMsg: false,
		};
	},
	computed: {
		isUpdate() {
			return !!this.userToUpdate;
		},
	},
	validations: {
		newUser: {
			firstName: {
				required,
			},
			lastName: {
				required,
			},
			password: {
				required: requiredUnless('isUpdate'),
				minLength: minLength(9),
			},
			verifyPassword: {
				required: requiredUnless('isUpdate'),
				length,
			},
			email: {
				required,
				email,
			},
			phone: {
				required,
			},
			role: {
				required,
			},
		},
	},
	methods: {
		addUser() {
			if (this.userToUpdate) {
				console.log('update', this.userToUpdate);
				this.updateUser();
			} else {
				if (this.newUser.password !== this.newUser.verifyPassword) {
					this.errPasswordMsg = true;
					setTimeout(() => {
						this.errPasswordMsg = false;
					}, 3000);
					console.log('error add user');
				} else if (this.v$.$invalid) {
					this.errMsg = true;
					setTimeout(() => {
						this.errMsg = false;
					}, 3000);
					console.log('error add user');
				} else {
					this.$store.dispatch('user/addUser', this.newUser).then((response) => {
						if (response.success) {
							const userName = `${this.newUser.firstName} ${this.newUser.lastName}`;
							this.$parent.$emit('saveuser', userName, this.newUser.role);
							this.closeModal();
						} else {
							this.errServerMsg = true;
							setTimeout(() => {
								this.errServerMsg = false;
							}, 3000);
						}
					});
				}
			}
		},
		updateUser() {
			if (!this.v$.$invalid && this.newUser.password === this.newUser.verifyPassword) {
				this.newUser.boUserId = this.userToUpdate._id;
				this.$store.dispatch('user/updateUser', this.newUser).then((response) => {
					if (response.success) {
						const userName = `${this.newUser.firstName} ${this.newUser.lastName}`;
						this.$emit('updateuser', userName, this.newUser.role);
						this.closeModal();
					} else {
						this.errServerMsg = true;
					}
				});
			} else {
				this.errMsg = true;
				setTimeout(() => {
					this.errMsg = false;
				}, 3000);
				console.log('error update user');
			}
		},
		generatePassword() {
			var text = '';
			var possible = 'abcdefghijklmnopqrstuvwxyz01234567890123456789';
			for (var i = 0; i < 9; i++) {
				text += possible.charAt(Math.floor(Math.random() * possible.length));
			}
			this.newUser.password = text;
		},
		getActivityBadgeStatus: function (status) {
			return activityStatus.getActivityBadgeStatus(status);
		},
		NumbersOnly(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		// @desc check if the input contains only letters
		LettersOnly(event) {
			let char = event.key;
			if (!/^[a-zA-Z]+$/.test(char)) {
				event.preventDefault();
			}
			return true;
		},
		closeModal() {
			this.$parent.$emit('closemodal');
		},
		toggleVerifyInputType() {
			this.$refs.verifyPassInput.type = this.$refs.verifyPassInput.type === 'password' ? 'text' : 'password';
			this.$refs.verifyPassIcon.className = this.$refs.verifyPassIcon.className.includes('fa')
				? this.$refs.verifyPassIcon.className.replace('fa fa-eye-slash', 'icon-eye')
				: this.$refs.verifyPassIcon.className.replace('icon-eye', 'fa fa-eye-slash');
		},
		toggleInputType() {
			this.$refs.passInput.type = this.$refs.passInput.type === 'password' ? 'text' : 'password';
			this.$refs.passIcon.className = this.$refs.passIcon.className.includes('fa')
				? this.$refs.passIcon.className.replace('fa fa-eye-slash', 'icon-eye')
				: this.$refs.passIcon.className.replace('icon-eye', 'fa fa-eye-slash');
		},
	},
	created() {
		console.log(this.newUser);
		if (this.userToUpdate) {
			this.newUser.firstName = this.userToUpdate.firstName;
			this.newUser.lastName = this.userToUpdate.lastName;
			this.newUser.phone = this.userToUpdate.phone;
			this.newUser.email = this.userToUpdate.email;
			this.newUser.status = this.userToUpdate.status;
			this.newUser.role = this.userToUpdate.role;
		}
	},
};
</script>
