import axios from 'axios';
import store from '../store/store';

const envUrl = process.env.VUE_APP_URL;
const BASEURL = `${envUrl}user/`;
const APPKEY = 'bxXnw3Wxp5V4GWVei5EgvXdMnKB9llOHIBV4';

const apiClient = axios.create({
	baseURL: BASEURL,
	withCredentials: false, // This is the default
	headers: {
		appkey: APPKEY,
		'Content-Type': 'application/json',
	},
});

export default {
	userLogin(emailAddress, password) {
		return apiClient.post('boUserLogin', {
			email: emailAddress,
			password: password,
		});
	},
	userForgetPassword(emailAddress) {
		let config = {
			method: 'post',
			url: `${BASEURL}forgotPassword`,
			headers: {
				appkey: APPKEY,
				'Content-Type': 'application/json',
			},
			data: emailAddress,
		};

		return axios(config);
	},

	getUsers() {
		let config = {
			method: 'get',
			url: `${BASEURL}getBoUsersList`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	addUser(user) {
		let config = {
			method: 'post',
			url: `${BASEURL}createBoUser`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: user,
		};
		console.log(config);
		return axios(config);
	},

	updateUser(user) {
		let config = {
			method: 'put',
			url: `${BASEURL}updateAdminDetails`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
				'Content-Type': 'application/json',
			},
			data: user,
		};
		return axios(config);
	},
	deleteUser(userId) {
		let config = {
			method: 'delete',
			url: `${BASEURL}deleteBoUser/${userId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: '',
		};
		return axios(config);
	},

	getFarmerPayments(farmerId) {
		let config = {
			method: 'get',
			url: `${BASEURL}getPaymentDetailsByFarmerId/${farmerId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	getFarmerCropsById(farmerId) {
		let config = {
			method: 'get',
			url: `${BASEURL}getSingleFarmerCrops/${farmerId}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
	getBusinessUserFarmers(businessUserId) {
		let config = {
			method: 'post',
			url: `${BASEURL}getBusinessUsersFarmers`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
			data: { businessUserId: businessUserId },
		};
		return axios(config);
	},
	getSessions(userId, env) {
		let config = {
			method: 'get',
			url: `${BASEURL}/${userId}/sessions?env=${env}`,
			headers: {
				appkey: APPKEY,
				Authorization: `Bearer ${store.getters['userSession/getUserToken']}`,
			},
		};
		return axios(config);
	},
};
