<template>
	<div class="card single_post2">
		<img class="img-fluid" src="./../../assets/blog/blog-page-3.jpg" alt="img" />
		<div class="body">
			<div class="content">
				<div class="actions_sidebar">
					<a href="javascript:void(0)"><i class="icon-share"></i></a>
					<a href="javascript:void(0)"><i class="icon-heart"></i><span>5</span></a>
					<a href="javascript:void(0)"><i class="icon-bubble"></i><span>8</span></a>
				</div>
				<h4 class="title">All photographs are accurate</h4>
				<p class="date">
					<small>Jun 15, 2018</small>
				</p>
				<p class="text">
					It is a long established fact that a reader will be distracted by the readable content of a page when looking
					at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
				</p>
				<a class="btn btn-primary" href="javascript:void(0)">READ MORE</a>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'SinglePost',
};
</script>
<style scoped></style>
