import { createStore } from 'vuex';
import * as userSession from '../store/modules/userSession';
import * as user from '../store/modules/user';
import * as businessUser from '../store/modules/businessUser';
import * as farmer from '../store/modules/farmer';
import * as crop from '../store/modules/crop';
import * as notification from '../store/modules/notification';
import * as accessToken from '../store/modules/accessToken';

import createPersistedState from 'vuex-persistedstate';

export default createStore({
	modules: {
		accessToken,
		userSession,
		user,
		businessUser,
		crop,
		farmer,
		notification,
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			reducer(val) {
				return {
					userSession: val.userSession,
				};
			},
		}),
	],
});
