import cropService from '../../services/cropService';
// import farmerService from '../../services/farmerService'
import businessUsersService from '../../services/businessUsersService';
export const namespaced = true;
export const state = {
	crops: [],
	filterBy: {
		name: '',
		status: 'ACTIVE',
	},
};

export const mutations = {
	SET_CROPS(state, crops) {
		state.crops = crops;
	},
	ADD_CROP(state, crop) {
		state.crops.push(crop);
	},
	SET_FILTER(state, filterBy) {
		state.filterBy = filterBy;
	},
	TOGGLE_STATUS(state) {
		state.filterBy.status = state.filterBy.status === 'NOT_ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE';
	},
	UPDATE_CROP(state, crop) {
		const idx = state.crops.findIndex((c) => c._id === crop._id);
		state.crops.splice(idx, 1, crop);
	},
	DELETE_CROP(state, cropId) {
		const idx = state.crops.findIndex((c) => c._id === cropId);
		const crop = state.crops[idx];
		if (crop) {
			crop.status = 'NOT_ACTIVE';
			state.crops.splice(idx, 1, crop);
		}
	},
};

export const getters = {
	cropsForDisplay(state) {
		return state.crops.filter((crop) => crop.status === state.filterBy.status);
	},
	getFilterStatus(state) {
		return state.filterBy.status;
	},
};

export const actions = {
	toggleFilterStatus({ commit }) {
		commit('TOGGLE_STATUS');
	},
	addCrop({ commit }, crop) {
		return new Promise((resolve) => {
			cropService
				.addCrop(crop)
				.then((response) => {
					commit('ADD_CROP', response.data.data);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
	getCrops({ commit }) {
		return new Promise((resolve) => {
			cropService
				.getCrops()
				.then((response) => {
					commit('SET_CROPS', response.data.data);
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
	getFarmerCropsById({ commit }, crops) {
		commit('SET_CROPS', crops);
	},
	getFarmerCrops() {
		return new Promise((resolve) => {
			cropService
				.getFarmerCrops()
				.then((response) => {
					resolve(response.data.success);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error.response);
				});
		});
	},
	updateCrop({ commit }, crop) {
		return new Promise((resolve) => {
			cropService
				.updateCrop(crop)
				.then((response) => {
					commit('UPDATE_CROP', response.data.data);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error);
				});
		});
	},
	deleteCrop({ commit }, cropId) {
		return new Promise((resolve) => {
			businessUsersService
				.deleteCrop(cropId)
				.then((response) => {
					commit('DELETE_CROP', cropId);
					resolve(response.data);
				})
				.catch((error) => {
					console.log(`There was an error: ${error}`);
					resolve(error);
				});
		});
	},
	reactivateCrop({ commit }, cropId) {
		return new Promise((resolve) => {
			cropService
				.reactivateCrop(cropId)
				.then((response) => {
					commit('UPDATE_CROP', response.data.crop);
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});
	},
};
